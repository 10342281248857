/**
 * extract informations about each formula member
 * @param formula string
 * @returns {{formula: *, setId: number|number, label: string|*, exer: string|*}[]}
 */
const getFormulaInfo = formula => {
  const regex = /(@\[.+?\]\((\d+|N([+-]\d)?)\)|[^@]+)/g;
  const parts = formula.match(regex) || [];

  return parts.map(part => {
    const setMemberRegex = /@\[(.+?)\]\((\d+|N([+-]\d)?)\)/g;
    const subparts = setMemberRegex.exec(part) || [];
    const isSetMember = subparts.length >= 2;

    const partLabel = isSetMember ? subparts[1] : part;
    const partExer = isSetMember ? subparts[3] : null;
    const partSetId = (isSetMember && subparts[3] !== undefined) || !isSetMember ? -1 : parseInt(subparts[2], 10);
    return { label: partLabel, formula: part, setId: partSetId, exer: partExer };
  });
};

/**
 * Extracts start, end for each member + corresponding positions in the formula
 * @param formula
 * @returns {{endFormula, start: number, setId: number, end, isMember: boolean, startFormula: number}[]}
 */
const getFormulaPositions = formula => {
  const formulaInfo = getFormulaInfo(formula);
  let position = 0;
  let formulaPosition = 0;

  return formulaInfo.map(item => {
    const labelLength = item.label.length;
    const isMember = item.setId > -1 || (item.setId < 0 && item.exer !== null);
    const result = {
      isMember,
      setId: item.setId,
      start: position,
      end: position + labelLength,
      startFormula: formulaPosition,
      endFormula: formulaPosition + (isMember ? labelLength + 5 + `${item.setId}`.length : labelLength),
    };
    position = result.end;
    formulaPosition = result.endFormula;
    return result;
  });
};

/**
 * Get info about the member touched by the cursor
 * @param formula string
 * @param cursorPosition where the cursor is in the formula
 * @returns {{formula: *, setId: number, label: (string|*), exer: (string|*)}}
 */
const getMemberInfoOfCursor = (formula, cursorPosition) => {
  // Walk through the formula, extract info and find which member contains the cursor
  let position = 0;
  return getFormulaInfo(formula).find(item => {
    // Guess if the cursor is in this part
    const labelLength = item.label.length;
    // Start of member is 0 at the beginning of the formula, +1 otherwise
    // TODO getSelectedSetId: Position 0 belongs to first member ?
    const start = position;
    // const start = position === 0 ? -1 : position;
    const cursorIsInPart = start < cursorPosition && cursorPosition <= position + labelLength;
    position += labelLength;
    return cursorIsInPart;
  });
};

/**
 * Get the ID of the set touched by the cursor
 * @param formula
 * @param cursorPosition
 * @returns {number}
 */
const getSelectedSetId = (formula, cursorPosition) => {
  const selectionInfo = getMemberInfoOfCursor(formula, cursorPosition);
  return selectionInfo ? selectionInfo.setId : -1;
};

/**
 * Get the ids of the sets referenced in the formula
 * @param formula
 * @returns {number[]}
 */
const getSetsIds = formula =>
  getFormulaInfo(formula)
    .map(info => info.setId)
    .filter(id => id > -1);

/**
 * Gets the label of the member whose id is setId
 * @param formula
 * @param setId
 * @returns {string|*}
 */
const getSetLabel = (formula, setId) => {
  if (setId === -1) {
    console.warn('FormulaHelper.getSetLabel is not meant to work with a non-set member (when setId is -1)');
  }
  const setInfo = getFormulaInfo(formula).find(info => info.setId === setId);
  return setInfo?.label;
};

/**
 * Get position informations about the member containing the cursor
 * @param formula
 * @param cursorPosition
 * @returns {{endFormula: number, start: number, end: number, startFormula: number}}
 */
const getCurrentMemberPositions = (formula, cursorPosition) => {
  const allPositions = getFormulaPositions(formula);
  const defaultValue = { start: 0, end: 0, startFormula: 0, endFormula: 0 };
  return allPositions.find(item => item.start <= cursorPosition && cursorPosition <= item.end) || defaultValue;
};

/**
 * Get position informations about the member with given setId (real set ID, not -1)
 * @param formula
 * @param setId
 * @returns {{endFormula, start: number, setId: number, end, isMember: boolean, startFormula: number}|{endFormula: number, start: number, end: number, startFormula: number}}
 */
const getSelectedMemberPositions = (formula, setId) => {
  const allPositions = getFormulaPositions(formula);
  const defaultValue = { start: 0, end: 0, startFormula: 0, endFormula: 0 };
  return allPositions.find(item => item.setId === setId) || defaultValue;
};

/**
 * The cursor position applies to the formula label. We need to have the corresponding position in the formula
 * @param formula
 * @param cursorPosition
 * @returns {number}
 */
const translatePositionInFormula = (formula, cursorPosition) => {
  const currentMemberPositions = getCurrentMemberPositions(formula, cursorPosition);

  if (!currentMemberPositions.isMember) {
    // Inside a text member
    return currentMemberPositions.startFormula + cursorPosition - currentMemberPositions.start;
  }
  if (cursorPosition === currentMemberPositions.start) {
    return currentMemberPositions.start;
  }
  if (cursorPosition === currentMemberPositions.end) {
    return currentMemberPositions.endFormula;
  }
  if (currentMemberPositions.start < cursorPosition && cursorPosition < currentMemberPositions.end) {
    // In the middle of a set member
    return currentMemberPositions.startFormula + 2 + cursorPosition - currentMemberPositions.start;
  }
  // should not happen
  return -1;
};

/**
 * Get the label of the formula, as displayed in the FormulaInput
 * @param formula
 * @returns {string}
 */
const getFormulaLabel = formula =>
  getFormulaInfo(formula)
    .map(item => item.label)
    .join('');

/**
 * Tells if the cursor is positioned on a N+1/N-1 member
 * @param formula
 * @param cursorPosition
 * @returns {boolean}
 */
const hasCurrentMemberExer = (formula, cursorPosition) => {
  const info = getMemberInfoOfCursor(formula, cursorPosition) || {};
  // Do not return !!info.exer because exer can be equal to 0
  return info.exer !== null && info.exer !== undefined;
};

export default {
  getFormulaInfo,
  getSelectedSetId,
  getSetsIds,
  getSetLabel,
  translatePositionInFormula,
  getSelectedMemberPositions,
  getFormulaLabel,
  hasCurrentMemberExer,
};
