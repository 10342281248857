import React from 'react';
import * as PropTypes from 'prop-types';

import { AdjustableInput } from './components';

import './FormulaInput2.scss';

// A text member has setId = -1 and is not a N+-1 member
const isTextMember = member => {
  const regex = /@\[(.+?)\]\((N([+-]\d)?)\)/;
  const parts = member.formula.match(regex);
  return member.setId < 0 && !parts;
};

const isExerMember = member => {
  const regex = /@\[(.+?)\]\((N([+-]\d)?)\)/;
  const parts = member.formula.match(regex);
  return member.setId < 0 && parts.length > 0;
};

// TODO Implement EXER dropdowns
export default function FormulaInput2({
  members = [],
  onChange = () => {},
  selectedMemberId,
  onMemberSelected = () => {},
}) {
  const getMemberClassName = member => {
    if (isTextMember(member)) {
      return 'formula-member';
    }
    if (isExerMember(member)) {
      return `exer-member ${member.id === selectedMemberId ? 'selected' : ''}`;
    }
    return member.id === selectedMemberId ? 'formula-set-member selected' : 'formula-set-member';
  };

  const onInputTextChanged = (newValue, member) => {
    const updatedMembers = members.map(item =>
      item.id === member.id ? { ...member, label: newValue, formula: newValue } : { ...item }
    );
    onChange(updatedMembers);
  };

  const onInputSelectionChanged = (position, member) => {
    onMemberSelected(member.id, position);
  };

  const onSetMemberSelected = member => {
    onMemberSelected(member.id, -1);
  };

  const handleKeyUp = event => {
    // 8: backspace, 37: left arrow, 39: right arrow
    const selectedMember = members.find(m => m.id === selectedMemberId);
    if (
      event.keyCode === 8 &&
      (selectedMember.setId > -1 || Object.prototype.hasOwnProperty.call(selectedMember, 'exer'))
    ) {
      // if (event.keyCode === 8 && members.find(m => m.id === selectedMemberId).setId > -1) {
      const updatedMembers = members.filter(member => member.id !== selectedMemberId);
      onChange(updatedMembers);
    }
  };

  return (
    <div className="formula-input">
      {/* Very hacky, but we need to have the focus inside the modal */}
      {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
      <input className="false-input" autoFocus />
      {members.map(member => {
        if (isTextMember(member)) {
          return (
            <AdjustableInput
              key={member.id}
              value={member.label}
              selected={member.id === selectedMemberId}
              onChange={newValue => onInputTextChanged(newValue, member)}
              onCursorChange={position => onInputSelectionChanged(position, member)}
            />
          );
        }
        return (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className={getMemberClassName(member)}
            key={member.id}
            onClick={() => onSetMemberSelected(member)}
            onKeyUp={handleKeyUp}
            /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
            tabIndex={0}
          >
            <span title={member.label}>{member.label}</span>
          </div>
        );
      })}
    </div>
  );
}

FormulaInput2.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({})),
  selectedMemberId: PropTypes.number,
  onChange: PropTypes.func,
  onMemberSelected: PropTypes.func,
};
