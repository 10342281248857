import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

import './MasterQuery.scss';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { Constants } from 'utils';

// Need to use a class component. Otherwise, the onQueryChange callback ignores the component state changes
export default class MasterQuery extends Component {
  onQueryChange = (prev, next) => {
    const { onMasterQueryChange } = this.props;

    // Remove Match None from nextQuery
    const lastMust = next.query.bool.must[0].bool.must;
    if (lastMust && 'match_none' in lastMust[lastMust.length - 1]) {
      next.query.bool.must[0].bool.must.pop();
    }
    onMasterQueryChange(next);
  };

  render = () => (
    <ReactiveComponent
      componentId="master_query"
      react={{ and: Constants.SEARCH_FIELDS_LABELS }}
      onQueryChange={this.onQueryChange}
      defaultQuery={() => ({ query: { match_none: {} } })}
    />
  );
}

// Function version of the component.

// export default function MasterQuery2({ onMasterQueryChange = () => {} }) {
//   const onQueryChange = (prev, next) => {
//     onMasterQueryChange(prev, next);
//   };
//
//   return (
//     <ReactiveComponent
//       componentId="master_query"
//       react={{ and: Constants.SEARCH_FIELDS_LABELS }}
//       onQueryChange={onQueryChange.bind(this)}
//       // onQueryChange={onMasterQueryChange}
//       defaultQuery={() => ({ query: { match_none: {} } })}
//     />
//   );
// }

MasterQuery.propTypes = {
  onMasterQueryChange: PropTypes.func,
};
