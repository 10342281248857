import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import CSVReader from 'react-csv-reader';
import { Constants } from 'utils';
import { formulaService } from 'services';
import labels from 'build/texts/labels.json';
import { LoadDots, TableDetails } from '..';

import './ImportCsvModal.scss';

const removeAccents = s => `${s}`.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const isSimilarTo = (a, b) => removeAccents(a).match(new RegExp(`^${removeAccents(b)}$`, 'i'));

export default function ImportCsvModal({ visible = false, onHide = () => {}, growl }) {
  const [loading, setLoading] = useState(false);
  const [sample, setSample] = useState([]);
  const [data, setData] = useState([]);

  const handleLoad = rawData => {
    window.data = rawData.map(v => v);
    const headers = rawData.shift().map(h => {
      Object.entries(Constants.CSV.aliases).forEach(([k, aliases]) => {
        aliases.forEach(a => {
          if (isSimilarTo(h, a)) {
            h = k;
          }
        });
      });
      return Object.keys(Constants.CSV.aliases).includes(h) ? h : null;
    });

    console.log(headers);

    const processedData = rawData
      .map(row =>
        row
          .map((v, k) => [headers[k], v])
          .filter(([k, v]) => k && v)
          .map(([k, v]) => {
            Object.entries(Constants.CSV.valueAliases[k] || {}).forEach(([newV, aliases]) => {
              aliases.forEach(a => {
                if (isSimilarTo(v, a)) {
                  v = newV;
                }
              });
            });
            return [k, v];
          })
      )
      .map(row => Object.fromEntries(row))
      .filter(row => Object.keys(row).length)
      .map(row => ({ ...Constants.CSV.defaultValue, ...row }))
      // eslint-disable-next-line no-sequences
      .map(row => ((row.montant = +(row.montant || '0').replace(/\s+/g, '').replace(',', '.')), row))
      .map(row => {
        const ndept = row.NDEPT ? String(row.NDEPT).trim() : null;
        // const ndept = String(row.NDEPT).trim() || undefined;
        const cregi = row.CREGI ? String(row.CREGI).trim() : null;
        // const cregi = String(row.CREGI).trim() || undefined;
        if (ndept) {
          const corres = labels[row.EXER < 2016 ? 'DPT_REGION_pre2016' : 'DPT_REGION'];
          const regexp = new RegExp(ndept.replace(/\W+/, '.*').replace(/^0+/, ''), 'i');
          const ndeptFromName =
            Object.entries(labels.NDEPT)
              .filter(([k, v]) => +k === +ndept || v.replace(/\W+/, '-').match(regexp))
              .sort(
                ([k1, a], [k2, b]) =>
                  // prendre le plus court en premier (cas Moselle / Meurthe-et-Moselle)
                  a.length - b.length
              )
              .map(([k]) => k)
              .shift() || row.NDEPT;
          const defaultValues = {
            CATEG: 'DEPT',
            CTYPE: 'Département',
            CREGI: row.CREGI || corres[row.NDEPT] || corres[ndeptFromName],
          };
          row = Object.assign(defaultValues, row, {
            NDEPT: ndeptFromName,
          });
        } else if (cregi) {
          const regexp = new RegExp(cregi.replace(/\W+/, '.*').replace(/^0+/, ''), 'i');
          const cregiFromName =
            Object.entries(labels.CREGI)
              .filter(([k, v]) => +k === +cregi || v.replace(/\W+/, '-').match(regexp))
              .sort(
                ([k1, a], [k2, b]) =>
                  // prendre le plus court en premier (cas Moselle / Meurthe-et-Moselle)
                  a.length - b.length
              )
              .map(([k]) => k)
              .shift() || row.CREGI;
          const defaultValues = {
            CATEG: 'REG',
            CTYPE: 'Région',
          };
          row = Object.assign(defaultValues, row, {
            CREGI: cregiFromName,
          });
        } else if (row.NOMEN === 'M14') {
          // No ndept, no cregi -> see if 'Commune'
          row.CATEG = 'Commune';
          row.CTYPE = 'Commune';
          row.NDEPT = undefined;
          row.CREGI = undefined;
        }

        if (row.COMPTE) {
          row.COMPTE_ABBR = row.COMPTE.substr(0, 2);
          row.COMPTE_BASE = row.COMPTE.substr(0, 3);
        }

        row.SOURCE = 'Données importées';

        if (row.origine) {
          row.description = row.origine + (row.description ? ` - ${row.description}` : '');
          delete row.origine;
        }
        return row;
      })
      // eslint-disable-next-line no-sequences
      .map(
        row => (
          (row._id = `import-${
            row.id ||
            [
              row.LBUDG,
              row.NDEPT,
              row.description || row.formulaLabel,
              row.CREGI,
              row.CTYPE,
              row.CBUDG,
              row.EXER,
              row.RD,
              row.COMPTE,
              row.NOMEN,
            ].join('-')
          }`),
          row
        )
      )
      .map(row =>
        Object.fromEntries(
          Object.entries(row).map(([header, value]) => {
            let k = header;
            k = k === 'CREGI' && row.EXER < 2016 && labels.CREGI_pre2016[value] ? 'CREGI_pre2016' : k;
            k = k.match(/^COMPTE_/) ? 'COMPTE' : k;

            if (labels[k] && value) {
              const label = labels[k][value];
              value = String(
                Constants.CSV.headersThatKeepCode.includes(header)
                  ? `${value || Constants.CSV.NA} ${label || ''}`
                  : label || value
              ).trim();
            }

            return [header, value];
          })
        )
      );

    const validData = processedData.filter(
      row =>
        Object.keys(row).filter(x => Constants.CSV.mandatoryKeys.includes(x)).length ===
        Constants.CSV.mandatoryKeys.length
    );

    setData(validData);
    setSample(processedData);
  };

  const importToElastic = async () => {
    setLoading(true);
    try {
      await formulaService.bulkIndexRows(data, undefined);
      growl.show({ severity: 'success', summary: 'Traitement terminé !', detail: `${data.length} lignes importées` });
      setData([]);
      setSample([]);
      setLoading(false);
      onHide();
    } catch (error) {
      growl.show({ severity: 'error', summary: "Erreur d'import", detail: "Les données n'ont pas pu être importées" });
      console.error('CSV import error :', error);
      setLoading(false);
    }
  };

  return (
    <Dialog header="Importer" visible={visible} style={{ width: '80vw' }} modal onHide={onHide}>
      <CSVReader
        cssClass="csv-input"
        parserOptions={{ encoding: 'cp1252' }}
        label={
          <span>
            Sélectionnez un fichier à importer. (<a href="/import-example.csv">télécharger le gabarit</a>)
          </span>
        }
        onFileLoaded={handleLoad}
      />

      {sample.length > 0 && (
        <div>
          <TableDetails value={sample} filter />

          <p>
            {sample.length} lignes traitées dont {data.length} ligne(s) valide(s).
          </p>

          <div className="btn-with-loader">
            <Button
              label={`Importer ${data.length} ligne(s)`}
              icon="pi pi-upload"
              className="p-button-success "
              onClick={importToElastic}
              disabled={!data.length}
            />
            {loading && <LoadDots />}
          </div>
        </div>
      )}
    </Dialog>
  );
}

ImportCsvModal.propTypes = {
  visible: PropTypes.bool,
  onHide: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  growl: PropTypes.any,
};
