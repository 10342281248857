import React, { Component } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { TabPanel, TabView } from 'primereact/tabview';
import { Fieldset } from 'primereact/fieldset';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { FilterBuilder, SorterBuilder } from 'pages/ChartBuilder/components';
import { aggregationService } from 'services';
import { FormulaParser } from 'utils';
import { ProfilChart } from '../Chart';

import { ResultDataTable, ResultMap } from '../DashboardResult/components';
import Aggregator from './Aggregator';
import FormulaBuilder from './FormulaBuilder';

const defaultColors = [
  '#ff7f00',
  '#ffff33',
  '#000DD6',
  '#984ea3',
  '#a65628',
  '#4daf4a',
  '#f781bf',
  '#e41a1c',
  '#999999',
  '#000000',
  '#00FFF0',
  '#DD9AFC',
  '#51FF00',
  '#377eb8',
].sort(() => Math.random() - 0.5);
// ajout de nouvelles couleurs / séleciton aléatoire de la couleur du jeu de données (uniquement pour le mosaic plot)
class ChartBuilder extends Component {
  chartTypes = [
    { label: 'Histogrammes', value: 'bar' },
    { label: 'Courbes', value: 'line' },
    { label: 'Histo. empilés', value: 'stack' },
    { label: 'Secteurs', value: 'pie' },
    { label: 'Anneau', value: 'doughnut' },
    { label: 'Radar', value: 'radar' },
    { label: 'Aires polaires', value: 'polarArea' },
    { label: 'Mosaïque', value: 'mosaic' }, /// ajout du choix d'un nouveau graphe
  ];

  colorCount = 0;

  constructor() {
    super();
    this.state = {
      value: '',
      dataSets: [{ color: this.nextColor(), sets: [], formula: '' }],
      chartType: 'bar',
      aggregs: ['EXER'],
      filterSet: { sets: [], formula: '' },
      sorterSet: { sets: [], formula: '' },
    };
    this.formulae = {};
  }

  nextColor() {
    return defaultColors[this.colorCount++ % defaultColors.length];
  }

  onNewSet() {
    console.log('NEW SET');

    const { dataSets } = this.state;
    dataSets.push({ color: this.nextColor(), sets: [], formula: '' });
    this.setState({ dataSets, dataTitle: null });
  }

  onDuplicateSet() {
    console.log('NEW SET');

    const { dataSets } = this.state;
    dataSets.push({ ...dataSets[dataSets.length - 1], color: this.nextColor() });
    this.setState({ dataSets, dataTitle: null });
  }

  updateSets = (dataSets, sorterSet) => {
    const parser = new FormulaParser();
    dataSets.map(set => parser.parse(set));
    this.setState({ dataSets, sorterSet, dataTitle: '' });
    this.dataTable && this.dataTable.reset();
  };

  updateAggregatorData = async () => {
    const { dataSets, sorterSet } = await aggregationService.updateData(
      this.state.dataSets,
      this.state.filterSet,
      this.state.sorterSet,
      this.state.aggregs,
      this.state.filterSet.size
    );
    this.updateSets(dataSets, sorterSet);
  };

  updateAggregs = aggregs => {
    this.setState({ aggregs, dataTitle: '' }, () => this.updateAggregatorData());
    this.dataTable && this.dataTable.reset();
  };

  updateSet = (k, set) => {
    this.updateAggregatorData();

    const { dataSets } = this.state;
    dataSets[k] = set;
    // this.updateSets(dataSets, this.state.sorterSet);
  };

  updateFilter = filterSet => {
    const parser = new FormulaParser();
    parser.parse(filterSet);
    this.setState({ filterSet }, () => this.updateAggregatorData());
    this.dataTable && this.dataTable.reset();
  };

  updateSorter = sorterSet => {
    const parser = new FormulaParser();
    parser.parse(sorterSet);
    this.setState({ sorterSet }, () => this.updateAggregatorData());
    this.dataTable && this.dataTable.reset();
  };

  render = () => {
    const toolbarContents = (
      <>
        <FilterBuilder
          set={this.state.filterSet}
          aggregs={this.state.aggregs}
          onChange={set => this.updateFilter(set)}
        />
        <SorterBuilder
          set={this.state.sorterSet}
          aggregs={this.state.aggregs}
          onChange={set => this.updateSorter(set)}
        />
      </>
    );

    return (
      <div>
        <span style={{ color: 'firebrick', fontWeight: 'bold' }}>[ OLD ChartBuilder ]</span>
        <br /> <br /> <h3> 1 / Sélection de données </h3>
        {this.state.dataSets.map((d, k) => (
          <FormulaBuilder
            key={k}
            set={d}
            chartType={this.state.chartType}
            aggregs={this.state.aggregs}
            onChange={set => this.updateSet(k, set)}
            onDelete={() => {
              this.state.dataSets.splice(k, 1);
              this.setState(this.state);
            }}
            ref={f => (this.formulae[k] = f)}
          />
        ))}
        <div style={{ float: 'right', marginTop: '-11px' }}>
          {/* !!this.state.dataSets.length && <Button title="Dupliquer un jeu de données" icon="pi pi-copy" onClick={this.onDuplicateSet.bind(this)}
                            style={{borderRadius:'0 0 0 3px', borderColor:'#c8c8c8'}} className="p-button-secondary" /> // FIXME */}
          <Button
            label="Ajouter un jeu de données"
            icon="pi pi-plus-circle"
            onClick={this.onNewSet.bind(this)}
            style={{ borderRadius: '0 0 3px 3px' }}
          />
        </div>
        <br clear="both" />
        <div id="div3">
          {' '}
          <br />
          <br />
          <br /> <br /> <h3> 2 / Filtres additionnels </h3>{' '}
        </div>
        <Toolbar
          left={toolbarContents}
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            padding: '10px',
            backgroundColor: '#fafafa',
            display: 'flex',
            whiteSpace: 'nowrap',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
          }}
        />
        <br clear="both" />
        <br />
        <br />
        <br /> <h3>3 / Résultat graphique</h3>
        <Fieldset style={{ marginBottom: '10px', border: 0 }}>
          <Aggregator
            dataSets={this.state.dataSets}
            updateDataSets={this.updateSets}
            updateAggregs={this.updateAggregs}
            ref={a => (this.aggregator = a)}
            size={this.state.filterSet.size}
            filterSet={this.state.filterSet}
            sorterSet={this.state.sorterSet}
            updateImmediately
          />
        </Fieldset>
        <TabView>
          <TabPanel header="Graphique">
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <label htmlFor="chart_type">Type de graphique : </label>
              <Dropdown
                inputId="chart_type"
                value={this.state.chartType}
                options={this.chartTypes}
                onChange={e => {
                  this.setState({ chartType: e.value || 'bar' });
                }}
                /* itemTemplate={this.carTemplate} */ placeholder="Histogramme"
              />
            </div>
            <br />
            <br />
            <ProfilChart
              type={this.state.chartType}
              dataSets={this.state.dataSets}
              sorterSet={this.state.sorterSet}
              dataTitle={this.state.dataTitle}
              size={this.state.filterSet.size}
            />
          </TabPanel>
          <TabPanel header="Données">
            <ResultDataTable
              dataTitle={this.state.dataTitle}
              dataSets={this.state.dataSets}
              sorterSet={this.state.sorterSet}
              onBrowse={stateData => this.setState(stateData)}
              ref={a => (this.dataTable = a)}
            />
          </TabPanel>
          <TabPanel header="Carte">
            <ResultMap dataSets={this.state.dataSets} />
          </TabPanel>
        </TabView>
      </div>
    );
  };
}

export default ChartBuilder;
