import React, { Component } from 'react';

import { BaseMapDepartements, BaseMapRegions } from 'components/Map';

export class Donnees extends Component {
  constructor(props) {
    super(props);
    this.state = { dataSets: this.props.dataSets, sorterSet: this.props.sorterSet, dataTitle: this.props.dataTitle };
  }

  render = () => {
    const listeDepart = [
      'Paris',
      'Bouches-du-Rhône',
      'Nord',
      'Alpes-Maritimes',
      'Rhône',
      'Hauts-de-Seine',
      'Haute-Garonne',
      'Gironde',
      'Seine-Saint-Denis',
      'Pas-de-Calais',
      'Hérault',
      'Seine-Maritime',
      'Loire-Atlantique',
      'Val-de-Marne',
      'Isère',
      'Réunion',
      'Bas-Rhin',
      'Essonne',
      'Seine-et-Marne',
      'Var',
      'Yvelines',
      'Ille-et-Vilaine',
      "Val-d'Oise",
      'Calvados',
      'Finistère',
      'Moselle',
      'Haute-Savoie',
      'Gard',
      'Oise',
      'Loire',
      'Aisne',
      'Ain',
      'Meurthe-et-Moselle',
      'Charente-Maritime',
      'Pyrénées-Atlantiques',
      'Puy-de-Dôme',
      'Martinique',
      'Aude',
      'Haut-Rhin',
      'Loiret',
      'Morbihan',
      'Guadeloupe',
      'Maine-et-Loire',
      'Vendée',
      "Côtes-d'Armor",
      'Vaucluse',
      'Somme',
      'Drôme',
      "Côte-d'Or",
      'Indre-et-Loire',
      'Pyrénées-Orientales',
      'Allier',
      'Saône-et-Loire',
      'Sarthe',
      'Savoie',
      'Eure',
      'Manche',
      'Doubs',
      'Ardèche',
      'Charente',
      'Dordogne',
      'Aveyron',
      'Marne',
      'Eure-et-Loir',
      'Landes',
      'Aube',
      'Ardennes',
      'Vienne',
      'Tarn',
      'Vosges',
      'Lot-et-Garonne',
      'Guyane',
      'Haute-Vienne',
      'Yonne',
      'Cher',
      'Deux-Sèvres',
      'Corse-du-Sud',
      'Hautes-Pyrénées',
      'Loir-et-Cher',
      'Orne',
      'Mayenne',
      'Corrèze',
      'Tarn-et-Garonne',
      'Nièvre',
      'Jura',
      'Mayotte',
      'Alpes-de-Haute-Provence',
      'Hautes-Alpes',
      'Gers',
      'Haute-Saône',
      'Haute-Loire',
      'Meuse',
      'Cantal',
      'Ariège',
      'Lot',
      'Indre',
      'Haute-Marne',
      'Haute-Corse',
      'Creuse',
      'Territoire de Belfort',
      'Lozère',
    ];
    const listReg = [
      'Île-de-France',
      'Auvergne - Rhône-Alpes',
      "Provence-Alpes-Côte d'Azur",
      'Occitanie',
      'Hauts-de-France',
      'Nouvelle-Aquitaine',
      'Grand-Est',
      'Normandie',
      'Pays de la Loire',
      'Bretagne',
      'Bourgogne - Franche-Comté',
      'Centre',
      'Centre - Val de Loire',
      'La Réunion',
      'Guadeloupe',
      'Corse',
      'Réunion',
      'Martinique',
      'Guyane',
      'Mayotte',
    ];
    const COLORS = ['#800026', '#BD0026', '#E31A1C', '#FC4E2A', '#FD8D3C'];
    const tabItem = [];
    const tabVal = [];

    if (this.state.dataSets.length > 0 && this.state.dataSets[0].sets[0]) {
      let jsonDatasets = this.state.dataSets[0].sets[0].values;
      let nbItemsRequete = Object.keys(jsonDatasets).length;
      let ItemsRequete = Object.keys(jsonDatasets);
      let counItemReqDept = 0;
      let countItemReqReg = 0;
      let reqDpt = false;
      let reqReg = false;

      for (let i = 0; i < listeDepart.length; i++) {
        if (listeDepart.includes(ItemsRequete[i])) {
          counItemReqDept = counItemReqDept + 1;
        }
      }

      for (let i = 0; i < listReg.length; i++) {
        if (listReg.includes(ItemsRequete[i])) {
          countItemReqReg = countItemReqReg + 1;
        }
      }
      if (counItemReqDept > 0 && counItemReqDept <= nbItemsRequete) {
        reqDpt = true;
      }
      // console.log('Départements', reqDpt, counItemReqDept, nbItemsRequete);
      if (countItemReqReg > 0 && countItemReqReg <= nbItemsRequete) {
        reqReg = true;
      }
      const stringDatasets = JSON.stringify(jsonDatasets);

      function RecupTabMontants() {
        let tabMontants = [];
        for (var key in jsonDatasets) {
          tabMontants.push(jsonDatasets[key]);
        }
        return tabMontants;
      }
      const montants = RecupTabMontants();

      function minMaxMontants(montants) {
        let maxValues = montants[0];
        let minValues = montants[0];
        let maxMin = [];
        for (let i = 1; i < montants.length; i++) {
          if (maxValues <= montants[i + 1]) {
            maxValues = montants[i + 1];
          }
          if (minValues >= montants[i + 1]) {
            minValues = montants[i + 1];
          }
        }
        maxMin.push(maxValues);
        maxMin.push(minValues);
        return maxMin;
      }

      const maxMin = minMaxMontants(montants);

      function limIntervals(maxMin) {
        let tabLIm = [];
        var largeurInterval = (maxMin[0] - maxMin[1]) / 5;
        var lim1 = maxMin[1] + largeurInterval;
        tabLIm.push(lim1);
        var lim2 = maxMin[1] + 2 * largeurInterval;
        tabLIm.push(lim2);
        var lim3 = maxMin[1] + 3 * largeurInterval;
        tabLIm.push(lim3);
        var lim4 = maxMin[1] + 4 * largeurInterval;
        tabLIm.push(lim4);
        var lim5 = maxMin[1] + 5 * largeurInterval;
        tabLIm.push(lim5);
        return tabLIm;
      }

      const tablimIntervals = limIntervals(maxMin);
      let lim1 = tablimIntervals[0];
      let lim2 = tablimIntervals[1];
      let lim3 = tablimIntervals[2];
      let lim4 = tablimIntervals[3];
      let lim5 = tablimIntervals[4];

      function sansAccent(chaine) {
        var accent = [
          /[\300-\306]/g,
          /[\340-\346]/g, // A, a
          /[\310-\313]/g,
          /[\350-\353]/g, // E, e
          /[\314-\317]/g,
          /[\354-\357]/g, // I, i
          /[\322-\330]/g,
          /[\362-\370]/g, // O, o
          /[\331-\334]/g,
          /[\371-\374]/g, // U, u
          /[\321]/g,
          /[\361]/g, // N, n
          /[\307]/g,
          /[\347]/g, // C, c
        ];
        var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

        var str = chaine;
        for (var i = 0; i < accent.length; i++) {
          str = str.replace(accent[i], noaccent[i]);
        }

        return str;
      }

      const affectationCouleurs = JSON.parse(stringDatasets, (key, value) => {
        if (value <= lim1) {
          tabVal.push(COLORS[0]); // verifier que COLORS NON {COLORS}
          tabItem.push(sansAccent(key.toUpperCase()));
        } else if (value > lim1 && value <= lim2) {
          tabVal.push(COLORS[1]); // verifier que COLORS NON {COLORS}
          tabItem.push(sansAccent(key.toUpperCase()));
        } else if (value > lim2 && value <= lim3) {
          tabVal.push(COLORS[2]); // verifier que COLORS NON {COLORS}
          tabItem.push(sansAccent(key.toUpperCase()));
        } else if (value > lim3 && value <= lim4) {
          tabVal.push(COLORS[3]); // verifier que COLORS NON {COLORS}
          tabItem.push(sansAccent(key.toUpperCase()));
        } else if (value > lim4 && value <= lim5) {
          tabVal.push(COLORS[4]); // verifier que COLORS NON {COLORS}
          tabItem.push(sansAccent(key.toUpperCase()));
        }
        let tabItemsColors = [];
        tabItemsColors.push(tabItem);
        tabItemsColors.push(tabVal);
        return tabItemsColors;
      });

      let affectationCouleursJson = {};

      for (var i = 0; i < affectationCouleurs[0].length; i++) {
        affectationCouleursJson[affectationCouleurs[0][i]] = affectationCouleurs[1][i];
      }

      if (reqDpt === true) {
        return (
          <div className="result-map-container">
            <BaseMapDepartements
              dataTitle={this.state.dataTitle}
              dataSets={this.state.dataSets}
              sorterSet={this.state.sorterSet}
            />
          </div>
        );
      }
      if (reqReg === true) {
        return (
          <div className="result-map-container">
            <BaseMapRegions
              dataTitle={this.state.dataTitle}
              dataSets={this.state.dataSets}
              sorterSet={this.state.sorterSet}
            />
          </div>
        );
      } else {
        return (
          //"counItemReqDept :"+counItemReqDept+"countItemReqReg :"+countItemReqReg+" nbItemsRequete :"+ nbItemsRequete+" ItemsRequete :"+ItemsRequete
          'Non disponible'
        );
      }
    } else {
      return '(aucune donnée)';
    }
  };
}

export default Donnees;
