import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'primereact/button';

import './Pager.scss';

export default function Pager({
  displaySizeSelector = true,
  displayResultsNumber = true,
  pageNumber = 1,
  total,
  pageSize = 10,
  onPagerChange = () => {},
}) {
  const onFirstPage = () => {
    if (pageNumber > 1) {
      onPagerChange(pageSize, 1);
    }
  };
  const onPrevPage = () => {
    if (pageNumber > 1) {
      onPagerChange(pageSize, pageNumber - 1);
    }
  };
  const onNextPage = () => {
    if (pageNumber * pageSize < total) {
      onPagerChange(pageSize, pageNumber + 1);
    }
  };
  const onLastPage = () => {
    if (pageNumber * pageSize < total) {
      onPagerChange(pageSize, Math.ceil(total / pageSize));
    }
  };

  return (
    <div className="profil-pager">
      {displaySizeSelector && <div className="page-info-item">{`Taille de page : ${pageSize}`}</div>}
      <div className="pager-centered-content">
        <div className="pager-pages-control">
          <Button
            className="p-button-secondary p-button-rounded p-button-text"
            icon="pi pi-angle-double-left"
            onClick={onFirstPage}
            disabled={pageNumber === 1}
          />
          <Button
            className="p-button-secondary p-button-rounded p-button-text"
            icon="pi pi-angle-left"
            onClick={onPrevPage}
            disabled={pageNumber === 1}
          />

          <span className="current-page-number">
            {displayResultsNumber ? `Page ${pageNumber} sur ${Math.ceil(total / pageSize)}` : `Page ${pageNumber}`}
          </span>

          <Button
            className="p-button-secondary p-button-rounded p-button-text"
            icon="pi pi-angle-right"
            onClick={onNextPage}
            disabled={pageNumber * pageSize >= total}
          />
          {displayResultsNumber && (
            <Button
              className="p-button-secondary p-button-rounded p-button-text"
              icon="pi pi-angle-double-right"
              onClick={onLastPage}
              disabled={pageNumber * pageSize >= total}
            />
          )}
        </div>
      </div>
      {displayResultsNumber && total >= 0 && (
        <div className="page-info-item">
          {`Éléments ${pageNumber * pageSize - pageSize + 1} à ${
            pageNumber * pageSize > total ? total : pageNumber * pageSize
          } sur ${total}`}
        </div>
      )}
    </div>
  );
}

Pager.propTypes = {
  displaySizeSelector: PropTypes.bool,
  displayResultsNumber: PropTypes.bool,
  pageNumber: PropTypes.number,
  total: PropTypes.number,
  pageSize: PropTypes.number,
  onPagerChange: PropTypes.func,
};
