import React, { useCallback, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { ImportCsvModal } from '../../..';

import './SidebarNavigation.scss';

export default function SidebarNavigation() {
  const history = useHistory();
  const location = useLocation();
  const [showImportModal, setShowImportModal] = useState(false);
  const toast = useRef(null);

  const navigateToPage = useCallback(
    path => {
      history.push(path);
    },
    [history]
  );
  const navLinks = [
    {
      label: 'Dashboard',
      icon: 'pi pi-fw pi-table',
      command: () => {
        navigateToPage('/');
      },
      link: '/',
    },
    // {
    //   label: 'Dashboard (tableau)',
    //   icon: 'pi pi-fw pi-table',
    //   command: () => {
    //     navigateToPage('/dash');
    //   },
    //   link: '/dash',
    // },
    {
      label: 'Requêtes enregistrées',
      icon: 'pi pi-fw pi-save',
      command: () => {
        navigateToPage('/formules');
      },
      link: '/formules',
    },
    {
      label: 'Importer un fichier',
      icon: 'pi pi-fw pi-upload',
      command: () => {
        setShowImportModal(true);
      },
    },
    {
      label: 'À propos',
      icon: 'pi pi-fw pi-info',
      command: () => {
        navigateToPage('/a-propos');
      },
      link: '/a-propos',
    },
    // {
    //   label: 'Profil',
    //   icon: 'pi pi-fw pi-user',
    //   command: () => {
    //     navigateToPage('/profil');
    //   },
    // },
    // {
    //   label: 'Page invalide',
    //   icon: 'pi pi-fw pi-times',
    //   command: () => {
    //     navigateToPage('/invalid');
    //   },
    // },
  ];

  return (
    <>
      <ul className="navigation-links">
        {navLinks.map(link => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
          <li
            className={`nav-link ${location.pathname === link.link ? 'active' : ''}`}
            key={link.label}
            onClick={link.command}
          >
            <i className={link.icon} />
            <div>{link.label}</div>
          </li>
        ))}
      </ul>

      {showImportModal && (
        <ImportCsvModal visible={showImportModal} onHide={() => setShowImportModal(false)} growl={toast} />
      )}
      <Toast ref={toast} />
    </>
  );
}
