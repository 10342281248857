import React, { useRef, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Mention, MentionsInput } from 'react-mentions';

import { FormulaHelper } from 'utils';
import { defaultMentionStyle, exerciceMentionStyle, style } from './mentionsStyle';

import './FormulaInputNew.scss';

const debugMode = false;

/**
 * Version of the FormulaInput with text
 * @param formula the formula to display
 * @param cursorPosition the position of the cursor when we have to force it
 * @param onFormulaChange when the string is modified, notify parent
 * @param onMemberSelected when we click or move into the formula, notify parent
 */
export default function FormulaInputNew({
  formula = '',
  cursorPosition,
  onFormulaChange = () => {},
  onMemberSelected = () => {},
}) {
  const inputRef = useRef(null);

  useEffect(() => {
    // if formula criteria changes, move the cursor to ensure it stays on the same member
    // or at the end of the previous one if deleted
    inputRef.current.selectionStart = cursorPosition;
    inputRef.current.selectionEnd = cursorPosition;
  }, [cursorPosition]);

  const getCursorPosition = () => inputRef.current.selectionStart || 0;

  const onChange = newValue => {
    onFormulaChange(newValue);
  };

  // Locate the cursor and determine which member is selected
  const onMentionInputCursor = () => {
    const position = getCursorPosition();
    const selectedId = FormulaHelper.getSelectedSetId(formula, position);
    onMemberSelected(selectedId, position);
  };

  const onMentionInputClick = () => {
    onMentionInputCursor();
  };

  const onMentionInputKeyUp = () => {
    onMentionInputCursor();
  };

  return (
    <div style={{ display: 'flex', flexGrow: '1', alignItems: 'flex-start', flexDirection: 'column' }}>
      {debugMode && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>formula : {formula}</div>
        </div>
      )}
      <div className="p-inputgroup" style={{ flex: 1 }}>
        <MentionsInput
          value={formula}
          onChange={(e, newValue) => onChange(newValue)}
          onClick={onMentionInputClick}
          onKeyUp={onMentionInputKeyUp}
          inputRef={inputRef}
          style={style}
          // disabled={this.state.processing}
          placeholder="Sélectionnez des données"
          autoFocus
        >
          <Mention
            markup="@[__display__](N__id__)"
            trigger="N"
            data={[
              { id: '-1', display: 'N-1' },
              { id: '+0', display: 'N' },
              { id: '+1', display: 'N+1' },
            ]}
            style={exerciceMentionStyle}
          />
          <Mention trigger="@" data={[].map((s, k) => ({ id: k, display: s.label }))} style={defaultMentionStyle} />
        </MentionsInput>
      </div>
    </div>
  );
}

FormulaInputNew.propTypes = {
  formula: PropTypes.string,
  cursorPosition: PropTypes.number,
  onFormulaChange: PropTypes.func,
  onMemberSelected: PropTypes.func,
};
