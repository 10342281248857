import React, { useState } from 'react';
import { ReactiveList } from '@appbaseio/reactivesearch';

import { Constants } from 'utils';
import { TableDetails, LoadDots } from '../../../../..';

import './DetailedData.scss';

export default function DetailedData() {
  const [nbPerPage, setNbPerPage] = useState(10);

  const renderStats = ({ numberOfPages, numberOfResults, time }) => {
    const nbResults = new Intl.NumberFormat('fr-FR').format(numberOfResults);
    const nbFoundLines = `${numberOfPages === 1000 ? '+ de ' : ''}${nbResults} lignes trouvées`;
    return (
      <p>
        {nbFoundLines} <small>en {time} ms</small>
      </p>
    );
  };

  const renderLoader = () => (
    <div className="data-loading">
      <div>Chargement</div> <LoadDots />
    </div>
  );

  return (
    <ReactiveList
      componentId="result"
      dataField="_source"
      title="Results"
      size={nbPerPage}
      loader={renderLoader()}
      // loader="Chargement..."
      react={{ and: Constants.SEARCH_FIELDS_LABELS }}
      renderResultStats={renderStats}
      style={{ textAlign: 'left' }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <></>;
        }
        if (error) {
          return <div>Erreur... {JSON.stringify(error)}</div>;
        }
        if (!data) {
          return <div>Aucune donnée</div>;
        }
        return <TableDetails value={data} onNbPerPageChange={setNbPerPage} nbPerPage={nbPerPage} />;
      }}
    </ReactiveList>
  );
}
