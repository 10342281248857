import * as PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import './LoadDots.scss';

export default function LoadDots({ inverted = false, disabled = false, secondary = false }) {
  const dotsClass = useMemo(() => {
    if (inverted) {
      return 'dot-color-white';
    }
    if (disabled) {
      return 'dot-color-disabled';
    }
    if (secondary) {
      return 'dot-color-secondary';
    }
    return '';
  }, [inverted, disabled, secondary]);

  return (
    <div id="circleG">
      <div id="circleG_1" className={`circleG ${dotsClass}`} />
      <div id="circleG_2" className={`circleG ${dotsClass}`} />
      <div id="circleG_3" className={`circleG ${dotsClass}`} />
    </div>
  );
}

LoadDots.propTypes = {
  inverted: PropTypes.bool,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
};
