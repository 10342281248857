/* eslint-disable react/forbid-prop-types */
import * as PropTypes from 'prop-types';

export const SubsetType = PropTypes.shape({
  label: PropTypes.string,
  formula: PropTypes.string,
  id: PropTypes.number,
  formulaMember: PropTypes.string,
  lists: PropTypes.object,
  query: PropTypes.object,
  values: PropTypes.object,
  inset: PropTypes.object,
  unitPlaceholder: PropTypes.string,
});

export const DatasetType = PropTypes.shape({
  color: PropTypes.string,
  sets: PropTypes.arrayOf(SubsetType),
  formula: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object,
  values: PropTypes.object,
  defaultValue: PropTypes.object,
  defaultError: PropTypes.object,
  inset: PropTypes.object,
  unitPlaceholder: PropTypes.string,
});

export const FiltersetType = PropTypes.shape({
  formula: PropTypes.string,
  placeholder: PropTypes.string,
  sets: PropTypes.arrayOf(SubsetType),
  size: PropTypes.string,
  errors: PropTypes.object,
  values: PropTypes.object,
  defaultValue: PropTypes.object,
  defaultError: PropTypes.object,
  inset: PropTypes.object,
  unitPlaceholder: PropTypes.string,
});

export const SortersetType = PropTypes.shape({
  formula: PropTypes.string,
  placeholder: PropTypes.string,
  sets: PropTypes.arrayOf(SubsetType),
  reverse: PropTypes.bool,
  errors: PropTypes.object,
  values: PropTypes.object,
  defaultValue: PropTypes.object,
  defaultError: PropTypes.object,
  inset: PropTypes.object,
  unitPlaceholder: PropTypes.string,
});

export const Dashboardtype = PropTypes.shape({
  name: PropTypes.string,
  description: PropTypes.string,
  aggregs: PropTypes.arrayOf(PropTypes.string),
  chartTitle: PropTypes.string,
  // TODO use oneOf
  chartType: PropTypes.string,
  thumbnail: PropTypes.string,
  dataSets: PropTypes.arrayOf(DatasetType),
  filterSet: FiltersetType,
  sorterSet: SortersetType,
  createDate: PropTypes.string,
  updateDate: PropTypes.string,
  id: PropTypes.string,
  owner: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
});
