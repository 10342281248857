import React, { useRef, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';

import './AdjustableInput.scss';
import { InputText } from 'primereact/inputtext';

function getElemContentWidth(window, elem, text) {
  if (!elem) {
    return 0;
  }
  const myCanvas = document.createElement('canvas');
  const context = myCanvas.getContext('2d');
  const { fontSize } = window.getComputedStyle(elem);
  const { fontFamily } = window.getComputedStyle(elem);
  context.font = `normal ${fontSize} ${fontFamily}`;
  const result = context.measureText(text).width;
  return result + 14;
}

export default function AdjustableInput({ value, selected = false, onChange = () => {}, onCursorChange = () => {} }) {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(getElemContentWidth(window, ref.current, value));
  }, [value]);

  const getCursorPosition = () => ref.current.selectionStart || 0;

  const onInputTextChanged = newValue => {
    onChange(newValue);
  };

  const onInputSelected = () => {
    onCursorChange(getCursorPosition());
  };

  return (
    <div className={`adjustable-input ${selected ? 'selected' : ''}`}>
      <InputText
        ref={ref}
        value={value}
        onChange={event => onInputTextChanged(event.target.value)}
        onClick={onInputSelected}
        onKeyUp={onInputSelected}
        style={{ width, padding: 'inherit 1px' }}
      />
    </div>
  );
}

AdjustableInput.propTypes = {
  value: PropTypes.string,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  onCursorChange: PropTypes.func,
};
