import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Logo } from 'logo.svg';
import { SidebarNavigation } from './components';

import './AppSidebar.scss';

const gitRevision = process.env.REACT_APP_GIT_REV;
const buildTime = process.env.REACT_APP_BUILD_TIME;

export default function AppSidebar() {
  const history = useHistory();

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      history.push('/');
    }
  };
  return (
    <div className="app-sidebar">
      <div className="title" onClick={() => history.push('/')} tabIndex={0} role="menu" onKeyPress={handleKeyPress}>
        <Logo width={220} alt="profil - dataviz" />
        <span className="header-build-time" title={buildTime}>
          v.{gitRevision || '0-dev'}
        </span>
      </div>
      <div className="sidebar-content">
        <SidebarNavigation />
      </div>
    </div>
  );
}
