import React, { Component, useEffect, useRef } from 'react'; // ajout de useEffect, useRef (utile pour le nouveau graphe)
import { Chart } from 'primereact/chart';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import * as d3 from 'd3'; // ajout de d3, mathjs (utile pour le nouveau graphe)
import * as Math from 'mathjs';
import ChartJS from 'chart.js/dist/chart';

import { unitFormatter } from 'utils';

import './ProfilChart.scss';

const debugIntegration = false;

// Plugin for title click

const handleEvent = function (titleBlock, e, opts) {
  // from core.legend.js
  const me = titleBlock;
  const type = e.type === 'mouseup' ? 'click' : e.type;

  if (type === 'mousemove') {
    if (!opts.onHover) return;
  } else if (type === 'click') {
    if (!opts.onClick) return;
  } else {
    return;
  }

  const { x, y } = e;

  const hitBox = {
    left: me.left,
    top: me.top,
    width: me.width,
    height: me.height,
  };

  if (x >= hitBox.left && x <= hitBox.left + hitBox.width && y >= hitBox.top && y <= hitBox.top + hitBox.height) {
    // Touching an element
    console.log('######### Touching an element');
    if (type === 'click') {
      console.log('click event, call onClick');
      // use e.native for backwards compatibility
      opts.onClick.call(me, e.native, me);
    } else if (type === 'mousemove') {
      // use e.native for backwards compatibility
      opts.onHover.call(me, e.native, me);
    }
  } else if (type === 'mousemove' && opts.onLeave) {
    opts.onLeave.call(me, e.native, me);
  }
};

const titleClickPlugin = {
  id: 'titleClickPlugin',
  version: '0.1',
  afterEvent(chartInstance, e) {
    const { titleBlock, config } = chartInstance;
    const { event } = e;
    if (titleBlock) {
      handleEvent(titleBlock, event, config.options.plugins.title);
    }
  },
};

// End plugin

const defaultColors = [
  '#8a633c',
  '#db4242',
  '#e98350',
  '#fdde6c',
  '#8bbb42',
  '#2cb377',
  '#328dbd',
  '#4150ad',
  '#583b97',
  '#9d3fa3',
  '#df5d8e',
  '#f1a5c7',
  '#87858e',
  '#e7dcdc',
].sort(() => Math.random() - 0.5);
const listener = null;

/*    La fonction Treemap sert à construire le graphique en mosaïque   */
function Treemap({ width, height, max, min, data }) {
  const ref = useRef();

  useEffect(() => {
    d3.select(ref.current).attr('viewBox', `0 0 1150 700`).style('border', '2px solid black');
    // .attr("width", width)
    // .attr("height", height)
  }, []);

  const draw = () => {
    const svg = d3.select(ref.current);

    // Give the data to this cluster layout:
    const root = d3.hierarchy(data).sum(d => d.value);

    // initialize treemap
    d3.treemap().size([width, height]).paddingTop(0).paddingRight(0).paddingLeft(0).paddingInner(0)(root);

    const color = d3.scaleOrdinal().domain(['.']).range(defaultColors);

    const opacity = d3.scaleLinear().domain([min, max]).range([0.7, 1]);

    // Select the nodes
    const nodes = svg.selectAll('rect').data(root.leaves());

    // draw rectangles
    nodes
      .enter()
      .append('rect')
      .transition()
      .duration(1200)
      .attr('x', d => d.x0)
      .attr('y', d => d.y0)
      .attr('width', d => d.x1 - d.x0)
      .attr('height', d => d.y1 - d.y0)
      .style('stroke', 'black')
      .style('fill', d => color(d.parent.data.name))
      .style('opacity', d => opacity(d.data.value));

    nodes.exit().remove();

    // select node titles
    const nodeText = svg.selectAll('text').data(root.leaves());

    // add the text
    nodeText
      .enter()
      .append('text')
      .transition()
      .duration(1400)
      .attr('x', d => d.x0 + 5) // +10 to adjust position (more right)
      .attr('y', d => d.y0 + 20) // +20 to adjust position (lower)
      .text(d => d.data.name)
      .attr('font-size', '12px')
      .attr('fill', 'black');

    // select node titles
    const nodeVals = svg.selectAll('vals').data(root.leaves());

    // add the values
    nodeVals
      .enter()
      .append('text')
      // .transition().duration(1500)
      .attr('x', d => d.x0 + 5) // +10 to adjust position (more right)
      .attr('y', d => d.y0 + 35) // +20 to adjust position (lower)
      .text(d => d.data.value)
      .attr('font-size', '8px')
      .attr('fill', 'black');
    // add the parent node titles
  };

  useEffect(draw, [data]);

  return (
    <div className="chart">
      <svg ref={ref} />
    </div>
  );
}

class ProfilChart extends Component {
  state = {
    hidden: false,
  };

  inView = false;

  lastConfig = {};

  constructor(props) {
    super(props);
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  getData = () => {
    const dataSets = this.props.dataSets || [{}];
    const sorterSet = this.props.sorterSet || {};

    const dataValues = dataSets.map(set => set.values || {});
    let sortValues = sorterSet.values || {};
    if (!Object.keys(sortValues).length) {
      sortValues = dataValues[dataValues.length - 1] || {};
    }
    // console.log(dataValues.map(d => Object.keys(d)));
    const labels = [...new Set(dataValues.map(d => Object.keys(d)).flat())].sort(
      this.sortLabels(sortValues, sorterSet.reverse)
    );

    // console.log(labels);
    // if(!labels.length) labels.push('');

    const opacity = ['polarArea'].includes(this.props.type) ? 0.75 : 1;
    const { color } = ChartJS.helpers;

    const data = dataSets.map(set => ({
      label: set.label || set.placeholder || '',
      backgroundColor: ['bar', 'stack', 'line', 'radar'].includes(this.props.type)
        ? color(set.color).alpha(opacity).rgbString()
        : defaultColors.slice(0, labels.length).map(c => color(c).alpha(opacity).rgbString()),
      unit: set.unit || set.unitPlaceholder || '',
      inset: set.inset,
      type: ['bar', 'stack'].includes(this.props.type) ? set.chartType || 'bar' : undefined,
      fill: !(['line', 'radar', 'polarArea'].includes(this.props.type) || ['line'].includes(set.chartType)),
      borderColor: ['bar', 'stack', 'line', 'radar'].includes(this.props.type) ? set.color : color.white,
      data: labels.map(l => (set.values || {})[l] || set.defaultValue || null),
    }));

    // console.log('chart', labels, data);

    return {
      labels: labels.slice(0, this.props.size || undefined),
      // eslint-disable-next-line no-sequences
      datasets: data.map(d => ((d.data = d.data.slice(0, this.props.size || undefined)), d)),
      sorterSet,
    };
  };

  getAxis(unit, idx) {
    return {
      type: ['bar', 'stack', 'line'].includes(this.props.type)
        ? 'linear'
        : ['polarArea', 'radar'].includes(this.props.type)
        ? 'radialLinear'
        : 'radial',
      // display: true,
      position: idx ? 'right' : 'left',
      id: ['polarArea', 'radar'].includes(this.props.type) ? undefined : `y-axis-${unit}`,
      stacked: ['stack'].includes(this.props.type),
      gridLines: { offsetGridLines: true, display: !!idx },
      grid: { display: false, drawBorder: false },
      ticks: {
        beginAtZero: true,
        unit,
        callback: v => unitFormatter(v, unit, 0, true),
      },
    };
  }

  sortLabels = (sortValues, reverse) => (l1, l2) => {
    /* Les années sont triées dans l'ordre */
    if (!isNaN(parseFloat(l1)) && !isNaN(parseFloat(l2))) {
      return (reverse ? -1 : 1) * (l1 - l2);
    }
    const v1 = sortValues[l1] || 0;
    const v2 = sortValues[l2] || 0;
    return (reverse ? -1 : 1) * (v2 - v1);
  };

  onScroll = () => {
    if (this.lastConfig.ctx) {
      if (this.isScrolledIntoView(this.lastConfig.canvas)) {
        if (this.inView) {
          return;
        }
        this.inView = true;
        console.log('new chart update');
        this.setState({ hidden: false });
        window.removeEventListener('scroll', listener);
      } else {
        this.inView = false;
        this.setState({ hidden: true });
      }
    }
  };

  getOptions = data => {
    const self = this;

    const units = [...new Set(data.datasets.map(set => set.unit || set.unitPlaceholder))];

    const axis = units
      .map((u, k) => [u, this.getAxis(u, k)])
      .reduce((prev, curr) => {
        prev[curr[0]] = curr[1];
        return prev;
      }, {});

    data.datasets.map(set => (set.yAxisID = (axis[set.unit || set.unitPlaceholder] || {}).id));

    const { sortLabels } = this;

    const options = {
      responsive: true,
      size: this.props.size,
      animation: {
        duration: 2000,
        onComplete() {
          const dl = document.getElementById('chart_download');
          dl.setAttribute('href', this.toBase64Image());

          const { canvas, ctx, config, destroy } = this;
          self.lastConfig = { canvas, ctx, config, destroy: destroy.bind(this) };
        },
      },
      // TODO Repair onClick
      // onClick: function (evt) {
      //   let firstPoint = this.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, false)[0];
      //
      //   if (!firstPoint) {
      //     const bottomAxis = Object.values(this.scales).find(a => a.position === 'bottom');
      //     if (
      //       bottomAxis &&
      //       evt.x > bottomAxis.left &&
      //       evt.x < bottomAxis.right &&
      //       evt.y > bottomAxis.top &&
      //       evt.y < bottomAxis.bottom
      //     ) {
      //       const nbTicks = bottomAxis.ticks.length;
      //
      //       const left = evt.offsetX - bottomAxis.left;
      //       const tickSize = (bottomAxis.right - bottomAxis.left) / nbTicks;
      //       const index = bottomAxis.ticks
      //         .map((t, idx) => (left > idx * tickSize && left < (idx + 1) * tickSize ? idx : null))
      //         .filter(f => f !== null)
      //         .pop();
      //
      //       firstPoint = { index };
      //     }
      //   }
      //
      //   if (firstPoint) {
      //     const label = this.data.labels[firstPoint.index];
      //
      //     const dataSets = this.data.datasets;
      //     const { sorterSet } = this.data;
      //     const dataValues = dataSets.map(set => ((set.inset && set.inset[label]) || {}).values || {});
      //     let sortValues = ((sorterSet.inset && sorterSet.inset[label]) || {}).values || {};
      //     if (!Object.keys(sortValues).length) {
      //       sortValues = dataValues[dataValues.length - 1] || {};
      //     }
      //     const labels = [...new Set(dataValues.map(d => Object.keys(d)).flat())]
      //       .sort(sortLabels(sortValues, sorterSet.reverse))
      //       .slice(0, this.options.size || undefined);
      //     console.log('click', label);
      //
      //     if (labels.length) {
      //       let dataSets = this.data.datasets;
      //       const grandparent = dataSets._parent;
      //       let parent = dataSets;
      //       parent = parent.map(p => {
      //         delete p._meta;
      //         return JSON.parse(JSON.stringify(p));
      //       });
      //       parent._parent = grandparent;
      //
      //       dataSets = dataSets.map(set =>
      //         Object.assign(set, {
      //           unit: ((set.inset || {})[label] || {}).unit || '',
      //           data: labels.map(
      //             l => (((set.inset || {})[label] || {}).values || {})[l] || (set.inset || {}).defaultValue || null
      //           ),
      //           inset: ((set.inset || {})[label] || {}).inset,
      //         })
      //       );
      //       dataSets._parent = { datasets: parent, title: this.options.title, labels: this.data.labels };
      //
      //       // eslint-disable-next-line no-sequences
      //       this.data.datasets = dataSets;
      //       this.data.labels = labels;
      //
      //       this.options.plugins.title = {
      //         display: true,
      //         text: [this.options.plugins.title.text || '', label].filter(i => i).join(' - '),
      //         font: { weight: '400', size: '16px' },
      //         onClick(e, titleBlock) {
      //           console.log('title clicked (if firstPoint)', e, titleBlock);
      //           const parent = this.data.datasets._parent;
      //           this.data.datasets = parent.datasets;
      //           this.data.labels = parent.labels;
      //           this.options.plugins.title = parent.title;
      //           this.update();
      //         },
      //       };
      //       this.update();
      //       return;
      //     }
      //   }
      //
      //   const title = this.titleBlock;
      //   if (title && evt.x >= title.left && evt.x <= title.right && evt.y >= title.top && evt.y <= title.bottom) {
      //     console.log('title clicked (onClick with titleBox)');
      //     const parent = this.data.datasets._parent;
      //     this.data.datasets = parent.datasets;
      //     this.data.labels = parent.labels;
      //     console.log('this.options.plugins.title :', this.options.plugins.title);
      //     this.options.plugins.title = parent.title;
      //     this.update();
      //   }
      // }, // End onClick
    };

    if (['bar', 'stack', 'line'].includes(this.props.type)) {
      if (Object.values(axis).length) {
        const yAxes = Object.values(axis);

        options.scales = {
          x: { grid: { display: false, drawBorder: false } },
        };

        yAxes.forEach(yAxis => {
          options.scales[yAxis.id] = yAxis;
        });

        if (['stack'].includes(this.props.type)) {
          options.scales.x.stacked = true;
        }
      }

      Object.assign(options, { borderRadius: 7, verticalMargin: 4 });

      // All corners are rounded if chart type is bar (only the top corners are rounded in Stacked chart)
      if (this.props.type === 'bar') {
        options.borderSkipped = true;
      }
    } else if (['polarArea', 'radar'].includes(this.props.type)) {
      options.scale = Object.values(axis).shift() || this.getAxis('€');
      delete options.scale.position;
    } else if (['doughnut', 'pie'].includes(this.props.type)) {
    }

    options.plugins = {};
    options.plugins.tooltip = {
      mode: 'nearest',
      intersect: true,
      callbacks: {
        label: tooltipItem => {
          const datasetName = tooltipItem.dataset.label;
          const xValueLabel = ['polarArea', 'pie', 'doughnut'].includes(this.props.type) ? tooltipItem.label || '' : '';
          const yValue = unitFormatter(tooltipItem.raw, tooltipItem.dataset.unit, 0, true) || 'N/A';
          return [datasetName, xValueLabel, yValue].filter(f => f).join(' : ');
        },
      },
    };
    options.plugins.legend = { position: 'bottom', align: 'end' };

    if (this.props.dataTitle) {
      options.plugins.title = {
        display: true,
        text: [this.props.dataTitle || ''].filter(i => i).join(' - '),
        font: { weight: '400', size: '16px' },
        onClick(e, titleBlock) {
          console.log('title clicked', e, titleBlock);
        },
      };
    }

    return [data, options];
  };

  integrate = () => {
    const data1 = this.getData();

    if (!data1.labels.length) {
      return null;
    }

    const [data, options] = this.getOptions(data1);
    options.maintainAspectRatio = false;

    const config = JSON.stringify({ type: this.props.type, data, options });

    const inline = value =>
      value
        .toString()
        .replace(/\/\/.*?\n/g, '')
        .replace(/\n/g, '');

    const scriptHeader = `<script src="https://cdn.jsdelivr.net/npm/chart.js@3.5.1/dist/chart.min.js" integrity="sha256-bC3LCZCwKeehY6T4fFi9VfOU0gztUa+S4cnkIhVPZ5E=" crossorigin="anonymous"></script>`;
    const scriptBody = `
<canvas id="profilChart" width="400" height="400"></canvas>
<script>
  const ctx = document.getElementById('profilChart').getContext('2d');
  const round = (n, precision) => {
      const prec = Math.pow(10, precision);
      return Math.round(n * prec)/prec;
  }
  // const sortLabels = $ {inline(this.sortLabels)};
  const unitFormatter = ${inline(unitFormatter)};
  const config = ${config};
  // config.options.onClick = $ {inline(options.onClick)};
  config.options.plugins.tooltip.callbacks.label =  tooltipItem => {
    const datasetName = tooltipItem.dataset.label;
    const xValueLabel = ['polarArea', 'pie', 'doughnut'].includes('${this.props.type}') ? tooltipItem.label || '' : '';
    const yValue = unitFormatter(tooltipItem.raw, tooltipItem.dataset.unit, 0, true) || 'N/A';
    return [datasetName, xValueLabel, yValue].filter(f => f).join(' : ');
  };

  if (Object.keys(config.options.scales).length > 1) {
    const lastKey = Object.keys(config.options.scales)[Object.keys(config.options.scales).length - 1];
    config.options.scales[lastKey].ticks.callback = v => unitFormatter(v, config.options.scales[lastKey].ticks.unit, 0, true);
  }
  new Chart(ctx, config);
</script>`;

    this.setState({ integrate: true, scriptBody, scriptHeader });
  };

  selectAll = e => {
    e.target.select();
  };

  /* getDataMosaic permet de récuperer et traiter les données à envoyer à la fonction Treemap */
  /* Permet de créer un objet JSON acceptable pour la fonction Treemap */
  getDataMosaic = () => {
    const Montants = [0];
    let mydata = [];
    const debut = '{"children":[';
    const fin = ']}';

    mydata.push(debut);

    let i = 0;

    while (i < this.props.dataSets.length) {
      /* Si il y a plus d'un jeu de données, cela permet de ne pas planter */
      for (let j = 0; j < this.props.dataSets.length; j++) {
        if (JSON.stringify(this.props.dataSets[j].sets) === '[]') {
          const mydata = '{"children":[{"name":"_","children":[]}]}';
          const Max = 0;
          const Min = 0;
          return { mydata, Max, Min };
        }
      }
      /* */

      const jsonDatasets = this.props.dataSets[i].sets[0].values || {};
      const debutset = `{"name":"${i}","children":[`;
      let finset = ']},';
      mydata.push(debutset);
      const montants = Object.values(jsonDatasets);
      let x = 0;
      while (x < montants.length) {
        montants[x] = Math.round(montants[x]);
        x++;
      }
      Montants.push(montants);
      for (let k = 0; k < Object.keys(jsonDatasets).length; k++) {
        const minidata = {
          name: Object.keys(jsonDatasets)[k],
          value: montants[k],
        };
        let stringMinidata = JSON.stringify(minidata);
        if (k < Object.keys(jsonDatasets).length - 1) {
          stringMinidata += ',';
        }
        mydata.push(stringMinidata);
      }
      if (i === this.props.dataSets.length - 1) {
        finset = ']}';
      }
      mydata.push(finset);
      i++;
    }
    /* Connaitre le max et le min des Montants permet de données les nuances de couleurs */
    const Max = Math.max(Montants);
    const Min = Math.min(Montants);

    mydata.push(fin);
    mydata = JSON.parse(mydata.join('').toString());

    return { mydata, Max, Min };
  };

  isScrolledIntoView(el) {
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    // Only completely visible elements return true:
    // const isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    // const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return elemTop < window.innerHeight * 0.8 && elemBottom >= 0;
  }

  hasAxis(type) {
    return ['bar', 'line', 'stack'].includes(this.props.type) && ['bar', 'line'].includes(type);
  }

  openInPage(header, body) {
    const myWindow = window.open('', 'Intégration');
    myWindow.document.write(`
    <!DOCTYPE html>
    <html lang='en'>
    <head>
      <meta charset='UTF-8'>
      <title>${this.state.dataTitle}</title>
      ${header}
    </head>
    <body>${body}</body>
    </html>
    `);
  }

  render = () => {
    const data1 = this.getData();
    if (!data1.labels.length) {
      return null;
    }
    const [data, options] = this.getOptions(data1);

    /* Données pour MosaicPlot */
    const { mydata, Max, Min } = this.getDataMosaic();
    const chart = JSON.stringify(this.props.type); // permet de connaitre le type de graphique que l'utilisateur sélectionne
    /* Mosaic */

    if (chart !== '"mosaic"') {
      return (
        <div>
          {this.state.hidden || (
            <Chart
              id="chart"
              type={this.props.type === 'stack' ? 'bar' : this.props.type || 'bar'}
              data={data}
              options={options}
              // plugins={[titleClickPlugin]}
            />
          )}
          <a
            href="about:blank"
            className="p-button p-component p-button-secondary p-button-text-icon-left p-button-outlined"
            id="chart_download"
            download="chart"
            style={{ float: 'right', fontSize: '0.8em', textDecoration: 'none' }}
          >
            <span className="pi pi-download p-c p-button-icon-left" />
            <span className="p-button-text p-c">Télécharger</span>
          </a>
          <Button
            label="Intégrer"
            icon="pi pi-paperclip"
            className="p-button-secondary p-button-outlined"
            onClick={this.integrate.bind(this)}
            style={{ float: 'right', fontSize: '0.8em', marginRight: '10px' }}
          />
          <br clear="both" />

          <Dialog
            header="Intégrer sur un site externe"
            visible={this.state.integrate}
            style={{ width: '50vw' }}
            modal
            onHide={() => this.setState({ integrate: false })}
          >
            <p>
              Pour utiliser ce graphique de façon déconnectée sur un site externe, insérez ces lignes une fois entre les
              balises <code>&lt;header/></code> de votre page&nbsp;:
            </p>
            <InputTextarea
              style={{ width: '100%' }}
              value={this.state.scriptHeader}
              rows={3}
              onClick={this.selectAll}
              readOnly
            />
            <p>
              Appelez ensuite le graphique à l'endroit de votre choix entre les balises <code>&lt;body/></code> :
            </p>
            <InputTextarea
              style={{ width: '100%' }}
              value={this.state.scriptBody}
              rows={3}
              onClick={this.selectAll}
              readOnly
            />

            {debugIntegration && (
              <div className="dialog-footer">
                <Button
                  label="ouvrir dans une page"
                  icon="pi pi-sign-out"
                  className="p-button-secondary p-button-outlined"
                  onClick={() => this.openInPage(this.state.scriptHeader, this.state.scriptBody)}
                  style={{ float: 'right', fontSize: '0.8em' }}
                />
              </div>
            )}
          </Dialog>
        </div>
      );
    }

    /* Si il y a plus d'un jeu de données, évite les bug d'affichage */
    for (let j = 0; j < this.props.dataSets.length; j++) {
      if (JSON.stringify(this.props.dataSets[j].sets) === '[]') {
        return null;
      }
    }
    /* */

    const treemap = <Treemap id="chart" width={1150} height={700} max={Max} min={Min} data={mydata} />;

    // TODO réparer eslint-disable ci-dessous
    return (
      <div>
        {treemap}
        {/* eslint-disable-next- line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="p-button p-component p-button-secondary p-button-text-icon-left"
          id="chart_download"
          download={treemap}
          style={{ float: 'right', fontSize: '0.8em' }}
        >
          <span className="pi pi-download p-c p-button-icon-left" />
          <span className="p-button-text p-c">Télécharger</span>
        </a>
        <Button
          label="Intégrer"
          icon="pi pi-paperclip"
          className="p-button-secondary"
          onClick={this.integrate.bind(this)}
          style={{ float: 'right', fontSize: '0.8em' }}
        />
        <br clear="both" />
        <Dialog
          header="Intégrer sur un site externe"
          visible={this.state.integrate}
          style={{ width: '50vw' }}
          modal
          onHide={() => this.setState({ integrate: false })}
        >
          <p>
            Pour utiliser ce graphique de façon déconnectée sur un site externe, insérez ces lignes une fois entre les
            balises <code>&lt;header/></code> de votre page&nbsp;:
          </p>
          <InputTextarea
            style={{ width: '100%' }}
            value={this.state.scriptHeader}
            rows={3}
            onClick={this.selectAll}
            readOnly
          />
          <p>
            Appelez ensuite le graphique à l'endroit de votre choix entre les balises <code>&lt;body/></code> :
          </p>
          <InputTextarea
            style={{ width: '100%' }}
            value={this.state.scriptBody}
            rows={3}
            onClick={this.selectAll}
            readOnly
          />
        </Dialog>
      </div>
    );
  };
}

export default ProfilChart;
