export default {
  AGGREGATIONS: [
    { label: 'Exercice', value: 'EXER' },
    { label: 'Territoire régional', value: 'CREGI' },
    { label: 'Territoire départemental', value: 'NDEPT' },
    { label: 'Catégorie', value: 'CATEG' },
    { label: "Type d'établissement", value: 'CTYPE' },
    { label: 'Source', value: 'SOURCE' },
    { label: 'Nomenclature', value: 'NOMEN' },
    { label: 'Dépense / Recette', value: 'RD' },
    { label: 'Fonct. / Invest.', value: 'FI' },
    { label: 'Type de budget', value: 'CBUDG' },
    { label: 'Compte abrégé', value: 'COMPTE_ABBR' },
    { label: 'Compte de base', value: 'COMPTE_BASE' },
    { label: 'Compte développé', value: 'COMPTE' },
    { label: 'Fonction', value: 'FONCTION_0' },
    { label: 'Sous-Fonction', value: 'FONCTION_1' },
    { label: 'Code Fonction', value: 'FONCTION' },
    { label: 'Entité budgétaire', value: 'LBUDG' },
    { label: 'Requête', value: 'formulaLabel' },
    { label: 'Agrégat', value: 'AGREGAT' },
    { label: 'SIREN', value: 'IDENT' },
  ],
  DEFAULT_AGGREGATION: 'EXER',
  DEFAULT_IMPORT_AGGREGATIONS: ['EXER', 'CREGI', 'NDEPT', 'CATEG', 'CTYPE'],

  SEARCH_FIELDS_LABELS: [
    'Source',
    'Exercice',
    'Territoire régional',
    'Territoire départemental',
    "Type d'établissement",
    'Type',
    'Section',
    'Nomenclature',
    'Entité budgétaire',
    'Type de budget',
    'Catégorie',
    'Chapitre Nature',
    'Compte développé',
    'Compte de base',
    'Compte abrégé',
    'Chapitre Fonction',
    'Fonction',
    'Sous-Fonction',
    'Code Fonction',
    'Données',
    'Requêtes enregistrées',
    'Agrégats',
    'IDENT',
  ],

  // Do not change the labels or elasticsearch goes wrong
  SEARCH_FIELDS_FULL: {
    SOURCE: { label: 'Source', name: 'SOURCE' },
    EXER: { label: 'Exercice', name: 'EXER' },
    CREGI: { label: 'Territoire régional', name: 'CREGI' },
    NDEPT: { label: 'Territoire départemental', name: 'NDEPT' },
    CTYPE: { label: "Type d'établissement", name: 'CTYPE' },
    RD: { label: 'Type', name: 'RD' }, // Recette/Dépense
    FI: { label: 'Section', name: 'FI' }, // Fonctionnement / Investissement
    NOMEN: { label: 'Nomenclature', name: 'NOMEN' },
    LBUDG: { label: 'Entité budgétaire', name: 'LBUDG' },
    IDENT: { label: 'IDENT', name: 'IDENT' },
    CBUDG: { label: 'Type de budget', name: 'CBUDG' },
    CATEG: { label: 'Catégorie', name: 'CATEG' },
    CHAPITRE: { label: 'Chapitre Nature', name: 'CHAPITRE' },
    COMPTE: { label: 'Compte développé', name: 'COMPTE' },
    COMPTE_BASE: { label: 'Compte de base', name: 'COMPTE_BASE' },
    COMPTE_ABBR: { label: 'Compte abrégé', name: 'COMPTE_ABBR' },
    CHAPITRE_FN: { label: 'Chapitre Fonction', name: 'CHAPITRE_FN' },
    FONCTION_0: { label: 'Fonction', name: 'FONCTION_0' },
    FONCTION_1: { label: 'Sous-Fonction', name: 'FONCTION_1' },
    FONCTION: { label: 'Code Fonction', name: 'FONCTION' },
    description: { label: 'Données', name: 'description' },
    formulaLabel: { label: 'Requêtes enregistrées', name: 'formulaLabel' },
    AGREGAT: { label: 'Agrégats', name: 'AGREGAT' },
  },

  CSV: {
    defaultValue: {
      unit: '',
      // RD: 'I',
      // CHAPITRE: 'Données extra-comptables',
      SOURCE: 'Import',
    },
    headersThatKeepCode: ['CHAPITRE', 'COMPTE', 'COMPTE_ABBR', 'COMPTE_BASE', 'CHAPITRE_FN', 'FONCTION', 'NOMEN'],
    NA: 'N/A',
    aliases: {
      EXER: ['Exer', 'Année', 'An', 'Annee', 'Exercice', 'Date'],
      NOMEN: ['Type de collectivité', 'Nomenclature', 'Niveau'],
      NDEPT: ['Nom du département', 'Département', 'Dep', 'Dept'],
      CREGI: ['Région', 'Nom de la région', 'Reg'],
      CBUDG: ['Type de budget', 'BP'],
      CATEG: ['Catégorie'],
      CTYPE: ["Type d'établissement", 'Niveau'],
      LBUDG: [
        'Entité budgétaire',
        'Collectivité',
        'Collecteur',
        'Coll',
        'Coll.',
        'Ville',
        'Commune',
        'Nom de la commune',
      ],
      RD: ['Recette/dépense/information', 'RDI', 'R/D/I', 'RD', 'Recette/dépense', 'RD', 'R/D'],
      FI: ['Fonctionnement/Investissement', 'FI', 'F/I'],
      CHAPITRE: ['Chap', 'Chapitre', 'Chptr', 'Chapitre Nat', 'Chapitre Nature'],
      COMPTE: ['Compte', 'Cpte'],
      FONCTION_0: ['Nom Fonction', 'F°', 'Func'],
      FONCTION_1: ['Sous-Fonction', 'Ss-F°', 'Sous-F°', 'Sous-Func'],
      FONCTION: ['Code Fonction', 'Fonction', 'Code F°', 'Code Func'],
      CHAPITRE_FN: ['Chapitre Fn', 'Chapitre Fonction'],
      description: ['Nature', 'Description', 'Desc', 'Label', 'Libellé', 'Titre', 'Donnée', 'Données'],
      montant: ['mt', 'somme', 'montant', 'amount', 'valeur', 'value'],
      unit: ['unit', 'unité', 'monnaie'],
      origine: ['src', 'source', 'origine'],
      _id: ['id'],
    },
    mandatoryKeys: ['montant', 'EXER', 'NDEPT', 'CREGI'],
    valueAliases: {
      NOMEN: {
        M52: ['Département', 'dep', 'Dept', 'Départements'],
        M14: ['Ville', 'Commune', 'City'],
        M71: ['Région'],
      },
      RD: {
        R: ['Recette', 'Recettes', 'R'],
        D: ['Dépense', 'Dépenses', 'D'],
        I: ['Information', 'Info', 'I'],
      },
      FI: {
        F: ['Fonctionnement'],
        I: ['Investissement'],
      },
      unit: {
        'hab.': ['habitants', 'personnes', 'hab', 'population', 'pop.', 'habitant', 'personne'],
      },
    },
  },
  // Role names as defined into Auth0
  ROLES: {
    ADMIN: 'Admin',
  },
};
