const elasticsearch = require('elasticsearch');

export const Credentials = 'plm:plmplmplm';
//export const HostName = '51.91.142.87:9200';
export const HostName = 'app.profil-dataviz.fr';

// This namespace is used to store additional data into Auth0 user info
export const AppNamespace = `https://${HostName}`;

export const App = 'data';
export const FormulaApp = 'formula';
export const DashboardApp = 'dashboard';
export const Host = `https://${Credentials}@${HostName}/`;

export const Client = new elasticsearch.Client({
  host: Host,
});
