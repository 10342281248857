import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { SelectedFilters } from '@appbaseio/reactivesearch';
import { Panel } from 'primereact/panel';

import { Constants } from 'utils';
import { formulaService } from 'services';
import { SearchAttributeListNew as SearchAttributeList, SearchCriteriaGroup } from './components';
import elasticSearchState from '../../ElasticSearchState';

import './SearchCriteria.scss';

/**
 * All the search criteria, agregates all the values by field and emits aggregated changes by field
 * @param value selected values by field, format: { field: [value1, value2] }
 * @param options options by field, format: { field: [option1, option2] }
 * @param onChange Notify parent when a criterion changes
 */
// eslint-disable-next-line no-unused-vars
export default function SearchCriteria({ value = {}, options = {}, onChange = () => {} }) {
  const [formulae, setFormulae] = useState([]);
  useEffect(() => {
    formulaService.getFormulae().then(items => {
      setFormulae(items);
    });
  }, []);

  const onListItemChange = (field, newValue) => {
    // Remove the key if there is no more associated value
    if (newValue.length === 0) {
      const d = { ...value };
      delete d[field];
      onChange(d);
    } else {
      onChange({ ...value, [field]: newValue });
    }
  };

  // Clear one field or all fields (when field is null)
  const onClear = fieldLabel => {
    // Get field name given its label
    const fieldName = Object.values(Constants.SEARCH_FIELDS_FULL).find(item => item.label === fieldLabel)?.name;
    onChange(fieldLabel ? { ...value, [fieldName]: [] } : {});
  };

  const getFieldsToUpdate = (excludedFields = []) => {
    excludedFields.forEach(field => {
      if (field && !Constants.SEARCH_FIELDS_LABELS.includes(field)) {
        console.warn(`ATTENTION : ${field} n'est pas dans la liste React`);
      }
    });
    return { and: Constants.SEARCH_FIELDS_LABELS.filter(i => !excludedFields.includes(i)) };
  };

  const onListToggleAll = (fieldLabel, fieldKey) => {
    const elasticField = `${fieldKey}.keyword`;
    // We get the options from the state class (see in class why)
    // TODO See if it works with last version of reactiveSearch
    // const allOptions = options;
    const allOptions = elasticSearchState.getAggregatedOptions();
    const opts =
      allOptions?.[fieldLabel]?.[elasticField]?.buckets.map(item =>
        // Items with search Remote have an object as key {dataField: label}
        item.key[elasticField] ? item.key[elasticField] : item.key
      ) || [];
    onListItemChange(fieldKey, value[fieldKey]?.length > 0 ? [] : opts);
  };

  const filterSavedSearches = items => {
    // Get only saved searches available to the user
    return items.filter(item => {
      return formulae.some(formula => formula._source.label === item.key);
    });
  };

  return (
    <Panel
      header={
        <div style={{ minHeight: '35px' }}>
          <SelectedFilters
            style={{ display: 'inline-block', marginRight: '10px' }}
            showClearAll
            clearAllLabel="Tout effacer"
            onClear={onClear}
            innerClass={{ button: 'p-button p-button-secondary p-button-text' }}
          />
        </div>
      }
    >
      <div className="grid">
        <div className="search col-12 md:col-6 lg:col-4">
          <h4>Informations générales</h4>

          <SearchAttributeList
            field={Constants.SEARCH_FIELDS_FULL.SOURCE.name}
            value={value.SOURCE}
            sortBy="count"
            onChange={onListItemChange}
            react={getFieldsToUpdate}
            className="box-5-lignes"
          />

          <SearchAttributeList
            field={Constants.SEARCH_FIELDS_FULL.EXER.name}
            accordionTab
            sortBy="desc"
            value={value.EXER}
            onChange={onListItemChange}
            react={getFieldsToUpdate}
            className="box-5-lignes"
          />
          <SearchCriteriaGroup title="Territoire">
            <SearchAttributeList
              field={Constants.SEARCH_FIELDS_FULL.CREGI.name}
              accordionTab
              showSearch
              size={200}
              value={value.CREGI}
              onChange={onListItemChange}
              onToggleAll={onListToggleAll}
              react={except => getFieldsToUpdate([...except, Constants.SEARCH_FIELDS_FULL.NDEPT.label])}
            />
            <SearchAttributeList
              field={Constants.SEARCH_FIELDS_FULL.NDEPT.name}
              accordionTab
              showSearch
              size={200}
              value={value.NDEPT}
              onChange={onListItemChange}
              onToggleAll={onListToggleAll}
              react={getFieldsToUpdate}
            />
          </SearchCriteriaGroup>

          <SearchAttributeList
            field={Constants.SEARCH_FIELDS_FULL.CTYPE.name}
            accordionTab
            showSearch={false}
            value={value.CTYPE}
            onChange={onListItemChange}
            react={getFieldsToUpdate}
            className="box-5-lignes"
          />
        </div>
        <div className="search col-12 md:col-6 lg:col-4">
          <h4>Comptabilité</h4>

          <SearchAttributeList
            field={Constants.SEARCH_FIELDS_FULL.RD.name}
            value={value.RD}
            onChange={onListItemChange}
            react={getFieldsToUpdate}
            style={{ minHeight: '78px' }}
          />

          <hr style={{ border: '1px solid' }} />

          <SearchAttributeList
            field={Constants.SEARCH_FIELDS_FULL.FI.name}
            // showMissing={true}
            value={value.FI}
            onChange={onListItemChange}
            react={getFieldsToUpdate}
            style={{ minHeight: '78px' }}
          />

          <SearchAttributeList
            field={Constants.SEARCH_FIELDS_FULL.NOMEN.name}
            accordionTab
            value={value.NOMEN}
            onChange={onListItemChange}
            onToggleAll={onListToggleAll}
            react={getFieldsToUpdate}
          />

          <SearchAttributeList
            field={Constants.SEARCH_FIELDS_FULL.LBUDG.name}
            accordionTab
            // accordionExpanded
            showSearch="remote"
            // showLoadMore // Bug ReactiveSearch
            value={value.LBUDG}
            onChange={onListItemChange}
            onToggleAll={onListToggleAll}
            react={getFieldsToUpdate}
          />

          <SearchAttributeList
            field={Constants.SEARCH_FIELDS_FULL.CBUDG.name}
            accordionTab
            value={value.CBUDG}
            onChange={onListItemChange}
            react={getFieldsToUpdate}
          />

          <SearchCriteriaGroup title="Recherche par nature">
            <SearchAttributeList
              field={Constants.SEARCH_FIELDS_FULL.CHAPITRE.name}
              accordionTab
              showSearch
              value={value.CHAPITRE}
              onChange={onListItemChange}
              onToggleAll={onListToggleAll}
              react={getFieldsToUpdate}
              // selectAllLabel="(tous)"
            />
            <SearchAttributeList
              field={Constants.SEARCH_FIELDS_FULL.COMPTE_ABBR.name}
              accordionTab
              showSearch
              value={value.COMPTE_ABBR}
              onChange={onListItemChange}
              onToggleAll={onListToggleAll}
              react={except =>
                getFieldsToUpdate([
                  ...except,
                  Constants.SEARCH_FIELDS_FULL.COMPTE_BASE.label,
                  Constants.SEARCH_FIELDS_FULL.COMPTE.label,
                ])
              }
              // selectAllLabel="(tous)"
            />
            <SearchAttributeList
              field={Constants.SEARCH_FIELDS_FULL.COMPTE_BASE.name}
              accordionTab
              showSearch
              value={value.COMPTE_BASE}
              onChange={onListItemChange}
              onToggleAll={onListToggleAll}
              react={except => getFieldsToUpdate([...except, Constants.SEARCH_FIELDS_FULL.COMPTE.label])}
              // selectAllLabel="(tous)"
            />
            <SearchAttributeList
              field={Constants.SEARCH_FIELDS_FULL.COMPTE.name}
              accordionTab
              showSearch="remote"
              // showLoadMore // Bug ReactiveSearch
              value={value.COMPTE}
              onChange={onListItemChange}
              onToggleAll={onListToggleAll}
              react={getFieldsToUpdate}
              // selectAllLabel="(tous)"
            />
          </SearchCriteriaGroup>

          <SearchCriteriaGroup title="Recherche par fonction">
            <SearchAttributeList
              field={Constants.SEARCH_FIELDS_FULL.CHAPITRE_FN.name}
              accordionTab
              showSearch
              value={value.CHAPITRE_FN}
              onChange={onListItemChange}
              onToggleAll={onListToggleAll}
              react={getFieldsToUpdate}
              // selectAllLabel="(tous)"
            />

            <SearchAttributeList
              field={Constants.SEARCH_FIELDS_FULL.FONCTION_0.name}
              accordionTab
              showSearch
              value={value.FONCTION_0}
              onChange={onListItemChange}
              onToggleAll={onListToggleAll}
              react={except =>
                getFieldsToUpdate([
                  ...except,
                  Constants.SEARCH_FIELDS_FULL.FONCTION.label,
                  Constants.SEARCH_FIELDS_FULL.FONCTION_1.label,
                ])
              }
              // selectAllLabel="(tous)"
            />
            <SearchAttributeList
              field={Constants.SEARCH_FIELDS_FULL.FONCTION_1.name}
              accordionTab
              showSearch
              value={value.FONCTION_1}
              onChange={onListItemChange}
              onToggleAll={onListToggleAll}
              react={except => getFieldsToUpdate([...except, Constants.SEARCH_FIELDS_FULL.FONCTION.label])}
              // selectAllLabel="(tous)"
            />

            <SearchAttributeList
              field={Constants.SEARCH_FIELDS_FULL.FONCTION.name}
              accordionTab
              showSearch="remote"
              // showLoadMore // Bug ReactiveSearch
              value={value.FONCTION}
              onChange={onListItemChange}
              onToggleAll={onListToggleAll}
              react={getFieldsToUpdate}
              // selectAllLabel="(toutes)"
            />
          </SearchCriteriaGroup>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <h4>Autres données</h4>

          <SearchAttributeList
            field={Constants.SEARCH_FIELDS_FULL.description.name}
            accordionTab
            accordionExpanded={false}
            showSearch
            // showLoadMore
            value={value.description}
            onChange={onListItemChange}
            react={getFieldsToUpdate}
          />
          <SearchAttributeList
            field={Constants.SEARCH_FIELDS_FULL.AGREGAT.name}
            accordionTab
            accordionExpanded={false}
            showSearch
            // showLoadMore
            value={value.AGREGAT}
            onChange={onListItemChange}
            react={getFieldsToUpdate}
          />
          <SearchAttributeList
            field={Constants.SEARCH_FIELDS_FULL.formulaLabel.name}
            accordionTab
            accordionExpanded={false}
            size={2000}
            showSearch
            // showLoadMore // Bug ReactiveSearch
            value={value.formulaLabel}
            transformData={filterSavedSearches}
            onChange={onListItemChange}
            onToggleAll={onListToggleAll}
            react={getFieldsToUpdate}
          />
        </div>
      </div>
    </Panel>
  );
}

SearchCriteria.propTypes = {
  value: PropTypes.shape({}),
  options: PropTypes.shape({}),
  onChange: PropTypes.func,
};
