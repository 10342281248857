import React, { useState } from 'react';
import { StateProvider, ReactiveBase } from '@appbaseio/reactivesearch';

import { App, Credentials, Host } from 'config/elastic';
import { Constants, useDebouncedCallback } from 'utils';
import { SearchAttributeListNew2 as SearchAttributeList2 } from 'components/SearchModal/components/SearchCriteria/components';
import { MasterQuery } from '../../components/SearchModal/components';

import './Test.scss';

export default function Test() {
  const [budg, setBudg] = useState([]);
  const budgChange = (field, newValue) => {
    console.log('budgChange field :', field);
    console.log('budgChange newValue :', newValue);
    setBudg(newValue);
  };

  const onStateChange = useDebouncedCallback((prev, next) => {
    console.log('onStateChange', prev, next);
  }, 250);

  const onQueryChange = useDebouncedCallback(query => {
    console.log('onQueryChange', query);
  }, 300);

  return (
    <div style={{ border: '1px solid red', padding: '15px' }}>
      <h1>Page de test reactiveSearch</h1>
      <ReactiveBase url={Host} app={App} credentials={Credentials} className="reactive-block">
        <StateProvider includeKeys={['value', 'aggregations']} strict onChange={onStateChange} />
        <div style={{}}>
          <pre>Valeurs : {JSON.stringify(budg)}</pre>
          <SearchAttributeList2
            // accordionTab
            field={Constants.SEARCH_FIELDS_FULL.LBUDG.name}
            showSearch="remote"
            showLoadMore // Bug ReactiveSearch
            value={budg}
            onChange={budgChange}
            // onToggleAll={onListToggleAll}
            react={() => ({
              and: [
                ...Constants.SEARCH_FIELDS_LABELS.filter(i => i !== 'Entité budgétaire'),
                `Entité budgétaire (recherche)`,
              ],
            })}
          />
        </div>
        <MasterQuery onMasterQueryChange={onQueryChange} />
      </ReactiveBase>
    </div>
  );
}
