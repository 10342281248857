import React, { Component } from 'react';
import { DataSearch, MultiList } from '@appbaseio/reactivesearch';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';

import './SearchAttributeList.scss';

const numberFormatter = new Intl.NumberFormat('fr-FR');

class SearchAttributeList extends Component {
  constructor(props) {
    super(props);
    this.state = { values: [], search: '' };
  }

  onListItemChange = value => {
    let { values } = this.state;
    // console.log('list change', l, value, lists[l]);

    if (Array.isArray(value)) {
      values = value;
    } else {
      const list = (values || []).concat();
      if (list.includes(value)) {
        list.splice(values.indexOf(value), 1);
      } else {
        list.push(value);
      }
      values = list;
    }

    this.props.onChange(this.props.placeholder)(values);
    this.setState({ values });
  };

  setValues = values => {
    if (values.toString !== this.state.values.toString()) {
      this.setState({ values });
    }
  };

  getValues = () => {
    console.log(this.props.placeholder, this.state.values);
    return this.state.values;
  };

  render = () => {
    const {
      accordionTab,
      accordionExpanded,
      placeholder,
      dataField,
      sortBy,
      selectAllLabel,
      react,
      showSearch,
      style,
      size,
      className,
      showMissing,
      showLoadMore,
      onToggleAll,
    } = this.props;
    const { values } = this.state;

    const count = (values || []).length;
    const isActive = accordionTab ? count > 0 || accordionExpanded : true;

    const reactComponents = react(placeholder);

    if (showSearch === 'remote') {
      reactComponents.and.push(`${placeholder} (recherche)`);
    }

    const list = (
      <div style={style} className={`${className} my-multilist`}>
        {
          showSearch === 'remote' && (
            <DataSearch
              componentId={`${placeholder} (recherche)`}
              dataField={dataField.replace('.keyword', '')}
              placeholder={placeholder}
              autosuggest={false}
              debounce={300}
              showFilter
              filterLabel={`${placeholder} contient `}
              URLParams={false}
            />
          )
          //   <input onChange={event => this.setState({ search: event.target.value })} type="search" placeholder={placeholder}/>
        }
        <MultiList
          componentId={placeholder}
          // defaultValue="TOTEM"
          dataField={dataField}
          filterLabel={`${placeholder} `}
          placeholder={placeholder}
          value={values}
          // onValueChange={this.onListChange('Source')}
          onChange={this.onListItemChange}
          showSearch={(showSearch && showSearch !== 'remote') || false}
          // showMissing={!!accordionTab || showMissing}
          showMissing={showMissing || false} // Paramètre par défaut 'false', permet de ne pas voir les N/A affichées. Si on souhaite les voir, il faut aller dans ReactiveSearch.js et changer le paramètre par défaut par 'true'
          sortBy={sortBy}
          loader="Chargement en cours…"
          showLoadMore={!!showLoadMore}
          loadMoreLabel="Afficher plus"
          URLParams={false}
          react={reactComponents}
          size={size || (showSearch === 'remote' ? 20 : 100)}
          selectAllLabel={selectAllLabel}
          renderItem={(key, docCount, isChecked) => (
            <span>
              <span>{key}</span>
              <span>{numberFormatter.format(docCount)}</span>
            </span>
          )}
        />
        {onToggleAll && (
          <div
            onClick={onToggleAll(placeholder, dataField)}
            style={{ fontSize: '.8em', fontStyle: 'italic', cursor: 'pointer', margin: '5px 2px' }}
          >
            <Button
              type="button"
              icon="pi pi-check"
              className="p-button-secondary p-button-outlined"
              iconPos="left"
              title={values && values.length ? 'Tout décocher' : 'Tout cocher'}
              style={{ fontSize: '.8em', marginRight: '10px', width: '20px', height: '20px' }}
            />
            ({values && values.length ? 'Tout décocher' : 'Tout cocher'})
          </div>
        )}
      </div>
    );

    return accordionTab ? (
      <Accordion activeIndex={isActive ? 0 : null}>
        <AccordionTab header={placeholder}>{list}</AccordionTab>
      </Accordion>
    ) : (
      list
    );
  };
}

export default SearchAttributeList;
