import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

import { dashboardService, userService } from 'services';
import { PageTitle } from 'components';
import { DashboardCard, DashboardFilter, DashboardSorter, DashboardPreview, Pager } from './components';

import './Dashboard.scss';

const pageSize = 10;

export default function Dashboard() {
  const history = useHistory();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const [internalDashboards, setInternalDashboards] = useState([]);
  const [sort, setSort] = useState({ sortBy: 'createDate', direction: -1 });
  const [filter, setFilter] = useState('');
  const [workingDashboardId, setWorkingDashboardId] = useState();
  const [previewDashboardId, setPreviewDashboardId] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalFiltered, setTotalFiltered] = useState(1);

  const { id: userId } = userService.getUser();

  const isAdminOrOwner = owner => userService.isAdmin() || owner.id === userId;

  const sortDashboards = (items, sortValue) =>
    items.sort((a, b) => {
      if (a?.[sortValue.sortBy] === b?.[sortValue.sortBy]) {
        // Have a fallback sort attribute in case of identical values
        return a.id > b.id ? sortValue.direction : sortValue.direction * -1;
      }
      return a?.[sortValue.sortBy].toLowerCase() > b?.[sortValue.sortBy].toLowerCase()
        ? sortValue.direction
        : sortValue.direction * -1;
    });

  const getCurrentPage = (items, pageNum) => {
    const start = (pageNum - 1) * pageSize;
    return items.slice(start, start + pageSize);
  };

  const getDashboards = useCallback(async () => {
    setLoading(true);
    try {
      const data = await dashboardService.getDashboards();
      const sortedData = sortDashboards(data, sort);
      setTotalFiltered(sortedData.length);
      const pagedData = getCurrentPage(sortedData, 1);
      setDashboards(pagedData);
      setInternalDashboards(sortedData);
      setFilter('');
      setLoading(false);
    } catch (error) {
      console.log('getDashboards error :', error);
      toast.current.show({
        severity: 'error',
        sticky: false,
        summary: "Impossible d'afficher les données",
        detail: `Une erreur est survenue, veuillez réessayer.\n\n${error && error.message}`,
      });
      setDashboards([]);
      setLoading(false);
    }
  }, [sort]);

  useEffect(() => {
    getDashboards();
  }, [getDashboards]);

  const createDashboard = async () => {
    history.push(`/builder`);
  };
  const edit = dashboardId => {
    history.push(`/builder/${dashboardId}`);
  };
  const preview = dashboardId => {
    setPreviewDashboardId(dashboardId);
  };
  const refresh = async dashboardId => {
    setWorkingDashboardId(dashboardId);
    try {
      await dashboardService.refreshDashboardData(dashboardId);
      setWorkingDashboardId(null);
      await getDashboards();
      toast.current.show({
        severity: 'success',
        summary: 'Traitement terminé !',
        detail: 'Les données ont été mises à jour',
      });
    } catch (error) {
      console.log('error :', error);
      toast.current.show({
        severity: 'error',
        summary: 'Erreur de mise à jour',
        detail: error?.message || 'Erreur lors du rafraîchissement des données',
      });
      setWorkingDashboardId(null);
    }
  };
  const duplicate = async dashboard => {
    try {
      if (isAdminOrOwner(dashboard.owner) || dashboard.shared) {
        setWorkingDashboardId(dashboard.id);
        await dashboardService.duplicateDashboard(dashboard.id);
        await getDashboards();
        setWorkingDashboardId(null);
        toast.current.show({ severity: 'success', summary: 'Dupliquer le dashboard', detail: 'Traitement terminé !' });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Erreur',
        detail: "L'opération n'a pu aboutir",
      });
      console.log('Duplicate dashboard error ', error);
      setWorkingDashboardId(null);
    }
  };
  const toggleSharing = async (dashboard, shareValue) => {
    try {
      if (isAdminOrOwner(dashboard.owner)) {
        setWorkingDashboardId(dashboard.id);
        await dashboardService.toggleDashboardSharing(dashboard.id, shareValue);
        await getDashboards();
        setWorkingDashboardId(null);
        toast.current.show({
          severity: 'success',
          summary: `${shareValue ? 'Partager' : 'Arrêter le partage'}`,
          detail: 'Traitement terminé !',
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Erreur',
        detail: "L'opération n'a pu aboutir",
      });
      console.log('Toggle dashboard sharing error ', error);
      setWorkingDashboardId(null);
    }
  };
  const deleteDashboard = async dashboard => {
    setWorkingDashboardId(dashboard.id);
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer ${dashboard.name} ?`)) {
      try {
        await dashboardService.deleteDashboard(dashboard.id);
        setWorkingDashboardId(null);
        await getDashboards();
      } catch (error) {
        console.log('error :', error);
        toast.current.show({
          severity: 'error',
          summary: 'Erreur de suppression',
          detail: error?.message || 'Erreur lors de la suppression des données',
        });
        setWorkingDashboardId(null);
      }
    }
    setWorkingDashboardId(null);
  };

  const onFilterChange = filterValue => {
    const filteredData = internalDashboards.filter(
      item =>
        `${item.name} ${item.description} ${item.owner.name}`.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
    );
    const pagedData = getCurrentPage(filteredData, 1);
    setDashboards(pagedData);
    setTotalFiltered(filteredData.length);
    setFilter(filterValue);
    setPageNumber(1);
  };

  const onSortChange = sortValue => {
    setSort(sortValue);
    const sortedData = sortDashboards(dashboards, sort);
    const pagedData = getCurrentPage(sortedData, 1);
    setDashboards(pagedData);
  };

  const onPagerChange = newPageNumber => {
    const pagedData = getCurrentPage(internalDashboards, newPageNumber);
    setDashboards(pagedData);
    setPageNumber(newPageNumber);
  };

  return (
    <section className="dashboard2-page">
      <Toast ref={toast} />
      <PageTitle title="Dashboard" loading={loading}>
        <Button onClick={createDashboard}>Nouveau dashboard</Button>
      </PageTitle>

      <div className="filter-bar">
        <div className="filter">
          <DashboardFilter value={filter} onChange={onFilterChange} />
        </div>
        <div className="sorter">
          <DashboardSorter value={sort} onChange={onSortChange} />
        </div>
      </div>
      <div className="dash-list">
        {dashboards.map(dashboard => (
          <DashboardCard
            dashboard={dashboard}
            key={dashboard.id}
            onEdit={edit}
            working={dashboard.id === workingDashboardId}
            onPreview={preview}
            onRefresh={refresh}
            onDuplicate={duplicate}
            onToggleSharing={toggleSharing}
            onDelete={deleteDashboard}
          />
        ))}
      </div>

      {dashboards.length > 0 && (
        <div className="pager-wrapper">
          <Pager
            displaySizeSelector={false}
            total={totalFiltered}
            pageNumber={pageNumber}
            onPagerChange={(size, number) => onPagerChange(number)}
            pageSize={pageSize}
          />
        </div>
      )}

      {dashboards.length === 0 && !loading && (
        <div className="dashboard-nodata">
          <h3 className="splash-message">Aucune donnée</h3>
        </div>
      )}

      {!!previewDashboardId && (
        <DashboardPreview
          dashboardId={previewDashboardId}
          visible={!!previewDashboardId}
          onHide={() => setPreviewDashboardId(null)}
        />
      )}
    </section>
  );
}

Dashboard.propTypes = {};
