import { Parser } from 'hot-formula-parser';
import { simplify } from 'mathjs';

class FormulaParser {
  isUsingSet(set, k) {
    // console.log(set.formula, k,set.formula.match(new RegExp("@\\[([^\\]]+?)\\]\\(("+1+"+)\\)") ));
    return set.formula.match(new RegExp('@\\[([^\\]]+?)\\]\\((' + set.sets[k].id + '+)\\)'));
  }

  parse = set => {
    this.computeFormula(set);
    this.updateLabel(set);
    this.updateUnit(set);
    return set;
  };

  updateLabel(set) {
    set.placeholder = set.formula.replace(/@\[(.+?)\]\((?:\d+|N([+-]\d)?)\)/g, '[$1]').replace(/^\[([^\]]+)\]$/, '$1');
  }

  updateUnit(set) {
    let nextVar = 0;
    let usedUnits = {};
    const formulaUnit =
      set.formula
        .replace(/@\[.+?\]\((\d+|N([+-]\d)?)\)/g, v => {
          const setId = +v.match(/@\[.+?\]\((\d+|N([+-]\d)?)\)/)[1];
          const s = set.sets.find(s => s.id === setId) || {};
          const unit = s.unit || s.unitPlaceholder || '';

          if (!usedUnits[unit]) {
            let variable = 'VAR_' + String.fromCharCode(97 + nextVar++);
            usedUnits[unit] = variable;
          }
          return usedUnits[unit];
        })
        .replace(/\d+/g, '1') || 0;

    try {
      let units = simplify(formulaUnit).toString();

      // simplify(A/A/A) = "(1 / A) ^ 2 * A" donc on refait une passe
      units = simplify(units.replace(/\(1 \/ (\w+)\) \^ (\d+) /, '1 / $1 ^ $2 ')).toString();

      Object.entries(usedUnits).map(([k, v]) => (units = units.replace(new RegExp(v, 'g'), k)));

      units = units.replace(/\s/g, '');

      units = units.replace(/\^2/g, '²');
      units = units.replace(/\^3/g, '³');
      units = units.replace(/[+\-*]\d+$/g, '');
      units = units.replace(/\d[+\-*]/g, '');
      units = units.replace(/^\d+$/g, '');
      units = units.replace(/^-/g, '');

      set.unitPlaceholder = units || '';
    } catch (e) {
      console.debug('unité par défaut');
      const variables = (set.formula.match(/@\[.+?\]\((\d+)\)/g) || []).map(v => +v.match(/@\[.+?\]\((\d+)\)/)[1]);

      const units = [...new Set(variables.map(k => (set.sets.find(s => s.id === k) || {}).unit))];
      set.unitPlaceholder = units.length === 1 ? units[0] : '';
    }
  }

  // Parse a formula and extract the subformulas, including N+/- info
  getMembers(formula) {
    const regex = /@\[.+?\]\((\d+|N([+-]\d)?)\)/g;
    const result = formula.match(regex);
    // console.log('result :', result);
    return result;
  }

  // Parse a formula and extract the subformulas
  getSetMembers(formula) {
    const regex = /@\[.+?\]\((\d+?)\)/g;
    const result = formula.match(regex);
    // console.log('result :', result);
    return result;
  }

  // Get all members, including when they are not associated to a set
  getAllMembers(formula) {
    const regex = /(@\[.+?\]\((\d+|N([+-]\d)?)\)|[^@]+)/g;
    const result = formula.match(regex);
    // console.log('result :', result);
    return result;
  }

  analyse(formula) {
    let analyse = [];
    let position = 0;
    let formulaPosition = 0;

    formula.replace(/(@\[.+?\]\((\d+|N([+-]\d)?)\)|[^@]+)/g, r => {
      const m = r.match(/@\[(.+?)\]\((\d+|N([+-]\d)?)\)/);
      let start = position;
      if (m) {
        position += m[1].length;
        if (m[2].match(/^N/)) {
          analyse.push({
            setId: -1,
            exer: +m[3] || 0,
            formulaPosition,
            start,
            end: position,
            label: m[1],
            isMember: true,
          });
        } else {
          analyse.push({ setId: +m[2], formulaPosition, start, end: position, label: m[1], isMember: true });
        }
      } else {
        position += r.length;
        analyse.push({ setId: -1, formulaPosition, start, end: position, label: r, isMember: false });
      }
      formulaPosition += r.length;
    });

    return analyse;
  }

  // Find labels, values and insets, then calculate the formula expression
  computeFormula(set) {
    const { formula, sets } = set;

    sets.map(s => this.setDecalageExer(formula, s));

    // Associate member index with matching values [{ 0: { 2019: 12356789, 2020: 8765432} }, { 1: { 2019: 23423245, 2020: 1432} }]
    const values = sets
      .map((v, k) => [v.id, v.values])
      .reduce(function (prev, curr) {
        prev[curr[0]] = curr[1];
        return prev;
      }, {});

    // Associate member index with matching insets
    const insets = sets
      .map((v, k) => [v.id, v.inset || {}])
      .reduce(function (prev, curr) {
        prev[curr[0]] = curr[1];
        return prev;
      }, {});

    // for current set, run the formula on values and insets
    Object.assign(set, this.computeSetFormula(formula, values, insets));
  }

  // Shift values and inset -1 or +1 step, to match the desired exercice
  setDecalageExer(formula, set) {
    if (set.exer) {
      return;
    }

    const analyse = this.analyse(formula);

    const index = analyse.findIndex(a => a.setId === set.id);
    const exer = (analyse[index - 1] || {}).exer || (analyse[index + 1] || {}).exer;

    if (exer && set.values) {
      // On va supposer que les exercices sont nécessairement en premier dans ce cas là

      Object.assign(set, {
        exer,
        values: Object.fromEntries(Object.entries(set.values).map(([k, v]) => [+k - exer, v])),
        inset: Object.fromEntries(Object.entries(set.inset).map(([k, v]) => [+k - exer, v])),
      });
    }

    return set;
  }

  computeSetFormula(formula, values, insets) {
    // Obtenir toutes les variables de la formule et leurs valeurs

    // Get all members indexes in an array
    const variables = (formula.match(/@\[.+?\]\((\d+)\)/g) || []).map(v => +v.match(/@\[.+?\]\((\d+)\)/)[1]);
    // console.log('computeSetFormula, variables', variables);

    // Get values associated to the index in { VAR_<idx>: { 2019: 12345, 2020: 876543 } }
    const valuesBySet = variables
      .map(k => ['VAR_' + k, values[k]])
      .reduce(function (prev, curr) {
        prev[curr[0]] = curr[1];
        return prev;
      }, {});

    // Obtenir tous les labels utilisés par les sets utilisés
    // Get all keys of values, ie ["2010", "2011", "2012", "2013", "2014", "2015", ...]
    const labels = [...new Set(variables.map(k => Object.keys(values[k] || {})).flat())].sort();

    const errors = {},
      results = {};

    // Get the formula expression, ie (VAR_0 + VAR_1) / 2
    const formulaVars = formula
      .replace(/@\[[^\]]+?\]\((N[+-]\d)\)/g, '') // delete exer
      .replace(/@\[.+?\]\((\d+)\)/g, 'VAR_$1')
      .replace(/,/g, '.');

    // Pour chaque label, calculer la valeur

    // For each label, compute the expression with corresponding values, ie (set0.values[2015] + set1.values[2015]) / 2
    const parser = new Parser();
    labels.forEach(label => {
      Object.entries(valuesBySet).map(([k, v]) => parser.setVariable(k, (v || {})[label] || 0));
      const res = parser.parse(formulaVars);
      if (res.error) {
        errors['' + label] = res.error;
      } else {
        results['' + label] = res.result;
      }
    });

    let defaultValue = null,
      defaultError = null;
    // TODO Find out what this is
    variables.map(k => parser.setVariable('VAR_' + k, Math.pow(10, -15)));
    const res = parser.parse(formulaVars);
    if (!res.error) {
      // TODO On fait le calcul de la valeur par défaut (dans le cas d'une constante par exemple)
      defaultValue = null; // res.result;
    } else {
      defaultError = labels.length ? [...new Set(Object.values(errors))].join(' ') : res.error;
    }

    // Calculer les inset
    let resInsets = {};
    if (insets) {
      // Get values and insets, descending to the next aggregation
      // Then compute formula expression with the values
      labels.forEach(label => {
        // console.log(JSON.parse(JSON.stringify(insets)), label);
        const v = Object.entries(insets)
          .map(([k, w]) => [k, (w[label] || {}).values || {}])
          .reduce(function (prev, curr) {
            prev[curr[0]] = curr[1];
            return prev;
          }, {});
        const i = Object.entries(insets)
          .map(([k, w]) => [k, (w[label] || {}).inset || {}])
          .reduce(function (prev, curr) {
            prev[curr[0]] = curr[1];
            return prev;
          }, {});
        resInsets[label] = this.computeSetFormula(formula, v, i);
      });
    }

    return { errors, values: results, defaultValue, defaultError, inset: resInsets };
  }

  repair = set => {
    set.sets
      .filter(s => s.label && !s.id)
      .forEach(s => {
        s.id = +s.formulaMember.match(/@\[.+?\]\((\d+)\)/)[1];
      });
    return set;
  };
}

export default FormulaParser;
