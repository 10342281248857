import { App, Client as elastic } from 'config/elastic';
import { FormulaParser } from 'utils';

/**
 * Computes the body of the request to perform
 * @param aggregations Aggregations to use
 * @param filterSize Desired number of results
 * @returns {*}
 */
const buildAggBody = (aggregations = [], filterSize) => {
  const body = { size: 0 };
  const aggregs = [...aggregations];
  let aggreg = aggregs.pop();
  while (aggreg) {
    body.aggs = {
      AGG: {
        terms: {
          size: 1000000 || filterSize || 10, // by default
          field: `${aggreg}.keyword`,
          missing: 'N/A',
          order: { montant: 'desc' },
        },
        aggs: {
          montant: {
            sum: {
              field: 'montant',
            },
          },
          unit: {
            terms: {
              field: 'unit.keyword',
              missing: '',
            },
          },
          ...body.aggs,
        },
      },
    };
    aggreg = aggregs.pop();
  }

  return body;
};

/**
 * Extract values, insest and unitPlaceholder from the query result
 * @param queryResult
 * @returns {{values:[], inset:{}, unitPlaceholder: string}}
 */
const getAggValues = queryResult => {
  const values =
    !queryResult || !queryResult.AGG
      ? []
      : queryResult.AGG.buckets.map(item => {
          const inset = item.AGG ? getAggValues(item) : undefined;
          return {
            key: item.key,
            value: (item.montant || {}).value,
            units: item.unit.buckets.map(i => i.key),
            inset,
          };
        });

  const res = {};

  res.values = values.reduce((acc, { key, value }) => {
    acc[key] = value;
    return acc;
  }, {});

  const hasInset = values.some(value => !!value.inset);
  if (hasInset) {
    res.inset = values.reduce((acc, { key, inset }) => {
      acc[key] = inset;
      return acc;
    }, {});
  } else {
    res.inset = {};
  }
  const units = [...new Set(values.map(v => v.units).flat())];
  res.unitPlaceholder = units.length === 1 ? units[0] : null;

  return res;
};

class AggregationService {
  /**
   * Gather data based on the queries of the dataSets and the sorterSet
   * @param dataSets the dataSets to update
   * @param filterSet Filters
   * @param sorterSet the sorterSet to update
   * @param aggregations Aggregation attributes
   * @returns {Promise<{dataSets: *[], sorterSet: (*|null)}>} the updated dataSets and sorterSet
   */
  async updateData(dataSets = [{}], filterSet = {}, sorterSet, aggregations = []) {
    const newDatasets = await Promise.all(dataSets.map(set => this.updateDataset(set, filterSet, aggregations)));
    // TODO remove sorterSet case and call this method instead
    const newSorterset = await this.updateDataset(sorterSet || {}, filterSet, aggregations);

    const parser = new FormulaParser();

    // TODO return only parsed dataSets
    return {
      dataSets: [...newDatasets.map(set => parser.parse(set))],
      sorterSet: sorterSet ? { ...parser.parse(newSorterset) } : null,
    };
  }

  /**
   * Query Elastic instance for the data
   * @param innerSet Set containing the query to process
   * @param filterSet Filters
   * @param aggregs Aggregation attributes
   * @returns {Promise<*>} the innerSet containing the data
   */
  async updateInnerSet(innerSet, filterSet, aggregs) {
    const body = { ...innerSet.query, ...buildAggBody(aggregs, filterSet.size) };
    const filters = (filterSet.sets || []).map(set => set.query.query);
    if (filters) {
      body.query = { bool: { must: [body.query, ...filters] } };
    }

    try {
      const { aggregations } = await elastic.search({ index: App, body, requestTimeout: 300000 });
      const res = getAggValues(aggregations);

      const newInnerSet = { ...innerSet, ...res };
      delete newInnerSet.exer;
      return newInnerSet;
    } catch (error) {
      console.log('ERROR elastic.search', error);
      return { ...innerSet, ...getAggValues(null) };
    }
  }

  /**
   * Update a dataset : gather the data of all the inner sets
   * @param dataset dataSet containing the sets to process
   * @param filterSet Filters
   * @param aggregs Aggregation attributes
   * @returns {Promise<*>} a copy of the datasets containing updates inner sets
   */
  async updateDataset(dataset, filterSet, aggregs) {
    const promises = (dataset.sets || []).map(innerSet => this.updateInnerSet(innerSet, filterSet, aggregs));
    const innerSets = await Promise.all(promises);
    return { ...dataset, sets: innerSets };
  }
}

export default new AggregationService();
