import React from 'react';
import * as PropTypes from 'prop-types';
import { InputText } from 'primereact/inputtext';

import './DashboardFilter.scss';

export default function DashboardFilter({ value = '', onChange = () => {} }) {
  return (
    <div className="dashboard-filter">
      <InputText
        value={value}
        onChange={event => onChange(event.target.value)}
        placeholder="Rechercher dans le nom, la description ou le propriétaire"
      />
    </div>
  );
}

DashboardFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};
