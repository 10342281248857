import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import './UserProfile.scss';
import { LoadDots } from 'components';

export default function UserProfile() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const tt = useAuth0();
  console.log('tt :', tt);
  if (isLoading) {
    return (
      <div>
        Chargement <LoadDots />
      </div>
    );
  }

  console.log('user :', user);
  return (
    isAuthenticated && (
      <div className="user-profile-page">
        <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
        <p>{user.email}</p>
      </div>
    )
  );
}

UserProfile.propTypes = {};
