import React from 'react';
import { Route } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { withAuthenticationRequired } from '@auth0/auth0-react';

export default function ProtectedRoute({ component, ...args }) {
  return <Route component={withAuthenticationRequired(component)} {...args} />;
}
ProtectedRoute.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any,
};
