import React, { Component } from 'react';
import {
  ReactiveBase,
  ReactiveComponent,
  ReactiveList,
  SelectedFilters,
  StateProvider,
} from '@appbaseio/reactivesearch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { Panel } from 'primereact/panel';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { debounce } from 'lodash';

import { unitFormatter } from 'utils';
import { App, Credentials, Host } from 'config/elastic';
import { TableDetails } from '..';
import { FormulaInputLegacy, SearchAttributeList, ImportModal } from './components';

import './SearchModalNew.scss';
// Le script ReactiveSearch.js permet la sélection de nos jeux de données.
const getReadableQuery = query => {
  const items = query.query.bool.must[0].bool.must;
  const values = items.map(i => i.bool.should[0].terms);
  return JSON.stringify(values);
};
class SearchModalLegacy extends Component {
  allFields = [
    'Source',
    'Exercice',
    'Territoire régional',
    'Territoire départemental',
    "Type d'établissement",
    'Type',
    'Section',
    'Nomenclature',
    'Entité budgétaire',
    'Type de budget',
    'Catégorie',
    'Chapitre Nature',
    'Compte développé',
    'Compte de base',
    'Compte abrégé',
    'Chapitre Fonction',
    'Fonction',
    'Sous-Fonction',
    'Code Fonction',
    'Données',
    'Requêtes enregistrées',
    'Agrégats',
  ];

  data = {
    query: {},
    values: {},
    unit: '€',
    lists: {},
  };

  listsEl = [];

  constructor(props) {
    super(props);
    this.state = {
      label: '',
      placeholder: '',
      visible: false,
      formula: props.formula || '',
      nbPerPage: 10,
    };
  }

  onQueryChange(prevQuery, nextQuery) {
    console.log('Query change, newQuery=', getReadableQuery(nextQuery));
    this.input && this.input.setQuery(nextQuery);
    // this.props.set.query = nextQuery;
  }

  onQueryChangeRemoveMatchNone(prevQuery, nextQuery) {
    // Remove Match None from nextQuery
    // console.log('next query', nextQuery);
    const lastMust = nextQuery.query.bool.must[0].bool.must;
    if (lastMust && 'match_none' in lastMust[lastMust.length - 1]) {
      nextQuery.query.bool.must[0].bool.must.pop();
    }

    this.onQueryChange(prevQuery, nextQuery);
  }

  setFilters() {}

  onValuesChange(values, units) {
    this.data.values = values.reduce((o, { key, value }) => {
      o[key] = value;
      return o;
    }, {});
    this.data.unitPlaceholder = units.length === 1 ? units[0] : null;
  }

  onSave(set) {
    this.setState({ visible: false });
    this.props.onSave(set);
  }

  onCancel() {
    this.setState({ visible: false });
  }

  importData(fromFormula) {
    this.setState({ importVisible: true, importFromFormula: !!fromFormula });
  }

  /*
    getSelectedSetId = () => {
        const textarea = this.input;

        const selection = [textarea.selectionStart, textarea.selectionEnd];
        const formula = this.props.set.formula;


        let results = [];
        let offset = 0;

        formula.replace(/@\[.+?\]\((\d+)\)/g, (v,k,start) => {
            const [label, setId] = v.match(/@\[(.+?)\]\((\d+)\)/).slice(1);
            start -= offset;
            const end = start+label.length;

            const inRange = ((selection[0]-start)*(selection[0]-end) <= 0) && ((selection[1]-start)*(selection[1]-end) <= 0);

            results.push({label, setId, start, end, inRange });
            offset += v.length-label.length;
        });

        const selected = results.find(f => f.inRange);
        if(selected && this.props.set.sets[selected.setId]) {
            return selected.setId;
        }
    }
    */

  onListClear = l => {
    // console.log('onListClear', l);
    if (l === null) {
      Object.entries(this.listsEl).forEach(([l, v]) => {
        v.setValues([]);
        this.onListItemChange(l)([]);
      });
      // this.setState(Object.fromEntries(this.allFields.map(l => [l, []])));
    } else if (this.listsEl[l]) {
      this.listsEl[l].setValues([]);
      this.onListItemChange(l)([]);
      // this.setState(Object.fromEntries([[l, []]]));
    }
  };

  onListItemChange = l => values => {
    this.data.lists[l] = values;
    // console.log("onListItemChange change", l, this.data.lists);

    // console.log('filters', filters);
    const placeholder = Object.entries(this.data.lists)
      .map(([label, value]) => ({ label, value }))
      .sort((a, b) => `${a.label}`.localeCompare(b.label))
      .map(f => (Array.isArray(f.value) ? f.value.join(', ') : f.value))
      .filter(d => d)
      .join(' | ');

    if (placeholder !== this.placeholder) {
      this.placeholder = placeholder;
      // this.setState({placeholder});
      // console.log('change', JSON.stringify(this.props.set.query));
      this.input.onAdd({
        placeholder,
        lists: this.data.lists,
        query: this.props.set.query,
        unitPlaceholder: this.data.unitPlaceholder,
      });
    }

    // this.setFilters(this.data.lists, Object.fromEntries([[l, lists[l]]]));
  };

  onListToggleAll = (l, dataField) => () => {
    const values =
      this.data.lists[l] && this.data.lists[l].length
        ? []
        : this.data.aggregations[l][dataField].buckets.map(b => b.key);

    this.listsEl[l].setValues(values);
    this.onListItemChange(l)(values);
  };

  onStateChange = (prevState, nextState) => {
    if (Object.keys(prevState).length === 0) {
      // console.log("State change ignore");
      return;
    }

    // console.log('State', nextState);

    // Values
    const lists = Object.fromEntries(
      Object.entries(nextState)
        .map(([k, v]) => [k, v.value || []])
        .filter(v => v[0] in this.listsEl)
    );
    // console.log("State change", lists);
    this.data.lists = lists;
    this.setFilters(lists);

    // Agregations
    this.data.aggregations = Object.fromEntries(
      Object.entries(nextState)
        .map(([k, v]) => [k, v.aggregations || []])
        .filter(v => v[0] in this.listsEl)
    );
  };

  show = () => {
    this.setState({ visible: true }, () => {
      this.data.lists = Object.fromEntries(Object.entries(this.listsEl).map(([k]) => [k, []]));
      this.input && this.input.focus();
    });
  };

  reactUpdateListValues = (...excepts) => {
    const all = this.allFields;

    excepts.forEach(except => {
      if (except && !all.includes(except)) {
        console.warn(`ATTENTION : ${except} n'est pas dans la liste React`);
      }
    });

    return {
      and: all.filter(i => !excepts.includes(i)),
    };
  };

  reactUpdateList = (...except) => this.reactUpdateListValues(...except);

  getValuesFromAggregations = aggregations =>
    !aggregations || !aggregations.AGG
      ? []
      : aggregations.AGG.buckets.map(item => ({
          key: item.key,
          value: (item.montant || {}).value,
          units: item.unit.buckets.map(i => i.key),
        }));

  onFormulaChange(formula) {
    this.props.set.formula = formula;
    // this.setState(this.state);
    // console.warn('>STATE<', 'onFormulaChange', {formula});
  }

  setListsValues = lists => {
    if (JSON.stringify(this.data.lists) === JSON.stringify(lists)) {
      // console.log('not set', lists);
      return;
    }
    // on reprend les nouvelles listes si jamais ça vient d'une vieille formule
    lists = Object.fromEntries(Object.entries(this.listsEl).map(([k]) => [k, lists[k] || []]));
    // console.log('values set', lists);
    this.data.lists = lists;

    Object.entries(lists).forEach(([l, v]) => this.listsEl[l] && this.listsEl[l].setValues(v));
  };

  clearListsValues = () => {
    const lists = Object.fromEntries(Object.entries(this.listsEl).map(([k]) => [k, []]));
    this.setListsValues(lists);
    this.placeholder = '';
  };

  render = () => {
    const { formula } = this.props.set;

    return (
      // return !this.state.visible ? null : (
      <Sidebar
        visible={this.state.visible !== false}
        fullScreen
        baseZIndex={1000000}
        style={{ padding: 0 }}
        onHide={() => this.setState({ visible: false }, this.props.onHide)}
      >
        <div style={{ maxHeight: '90vh', overflowY: 'auto' }}>
          {/*<div style={{ border: '1px solid red' }}>*/}
          {/*  <p>data : {JSON.stringify(this.data)}</p>*/}
          {/*  <p>props.set : {JSON.stringify(this.props.set)}</p>*/}
          {/*</div>*/}

          <div style={{ padding: '0.571em 1em', maxWidth: '1200px', margin: '0 auto', whiteSpace: 'normal' }}>
            <Toast ref={el => (this.growl = el)} />
            {this.state.importVisible && (
              <ImportModal
                visible={this.state.importVisible}
                onHide={() => this.setState({ importVisible: false })}
                growl={this.growl}
                formula={this.state.importFromFormula ? this.props.set : false}
                title={this.props.defaultImportLabel}
                defaultAggregs={this.props.defaultImportAggregs}
              />
            )}

            {this.props.defaultImportLabel && <h3>{this.props.defaultImportLabel}</h3>}
            <FormulaInputLegacy
              value={formula}
              onChange={v => this.onFormulaChange(v)}
              importData={() => this.importData(true)}
              onCancel={() => this.setState({ visible: false })}
              onSave={set => this.onSave(this.props.set, set)}
              ref={el => (this.input = el)}
              set={this.props.set}
              setListsValues={this.setListsValues}
              clearListsValues={this.clearListsValues}
            />

            <ReactiveBase url={Host} app={App} credentials={Credentials}>
              <StateProvider
                includeKeys={['value', 'aggregations']}
                strict
                onChange={debounce(this.onStateChange, 250)}
              />

              <Panel
                header={
                  <div style={{ minHeight: '35px' }}>
                    <SelectedFilters
                      style={{ display: 'inline-block', marginRight: '10px' }}
                      showClearAll
                      clearAllLabel="Tout effacer"
                      // onChange={this.setFilters.bind(this)}
                      onClear={component => {
                        this.onListClear(component);
                      }}
                      innerClass={{
                        button: 'p-button p-button-secondary p-button-text',
                      }}
                    />
                    {/* <Button label="+ Sélectionner" onClick={this.onAdd.bind(this)} className="p-button-warning"/> */}
                  </div>
                }
              >
                <div className="grid" style={{ minHeight: '80vh' }}>
                  <div className="search col-12 md:col-6 lg:col-4">
                    {/*
                      <SingleRange
                        componentId="ratingsfilter"
                        title="Filter by ratings"
                        dataField="rating"
                        data={[
                          {"start": "4", "end": "5", "label": "4 stars and up"},
                          {"start": "3", "end": "5", "label": "3 stars and up"},
                          {"start": "2", "end": "5", "label": "2 stars and up"},
                          {"start": "1", "end": "5", "label": "see all ratings"},
                        ]}
                        defaultSelected="4 stars and up"
                        style={{
                          padding: "5px",
                          "marginTop": "10px"
                        }}
                                <DynamicRangeSlider
                                    componentId="Exercice"
                                    dataField="EXER"
                                    title="Exercices"
                                    showMissing={true}
                                    URLParams={true}
                                    react={{
                                        and: ["Recherche", "Montant", "Type", "Section", "Compte", "Fonction", "Collectivite", "Collecteur", "Chapitre"]
                                    }}
                                />
                      /> */}

                    <h4>Informations générales</h4>

                    <SearchAttributeList
                      placeholder="Source"
                      ref={el => (this.listsEl.Source = el)}
                      dataField="SOURCE.keyword"
                      onChange={this.onListItemChange}
                      react={this.reactUpdateList}
                      className="box-5-lignes"
                    />

                    <hr style={{ border: '1px solid' }} />

                    <SearchAttributeList
                      accordionTab
                      placeholder="Exercice"
                      ref={el => (this.listsEl.Exercice = el)}
                      dataField="EXER.keyword"
                      sortBy="desc"
                      onChange={this.onListItemChange}
                      react={this.reactUpdateList}
                      className="box-5-lignes"
                    />
                    <hr style={{ border: '1px solid' }} />
                    <Accordion>
                      <AccordionTab header="Territoire">
                        <SearchAttributeList
                          accordionTab
                          placeholder="Territoire régional"
                          ref={el => (this.listsEl['Territoire régional'] = el)}
                          dataField="CREGI.keyword"
                          sortBy="asc"
                          showSearch
                          size={200}
                          onChange={this.onListItemChange}
                          onToggleAll={this.onListToggleAll}
                          react={except => this.reactUpdateList(except, 'Territoire départemental')}
                        />

                        <SearchAttributeList
                          accordionTab
                          placeholder="Territoire départemental"
                          ref={el => (this.listsEl['Territoire départemental'] = el)}
                          dataField="NDEPT.keyword"
                          sortBy="asc"
                          showSearch
                          size={200}
                          onChange={this.onListItemChange}
                          onToggleAll={this.onListToggleAll}
                          react={this.reactUpdateList}
                        />
                      </AccordionTab>
                    </Accordion>
                    <hr style={{ border: '1px solid' }} />

                    <SearchAttributeList
                      accordionTab
                      placeholder="Type d'établissement"
                      ref={el => (this.listsEl["Type d'établissement"] = el)}
                      dataField="CTYPE.keyword"
                      showSearch={false}
                      onChange={this.onListItemChange}
                      react={this.reactUpdateList}
                      className="box-5-lignes"
                    />
                    {/*<hr style={{ border: '1px solid' }} />*/}

                    {/*
                                    <SearchAttributeList
                                        accordionTab={true}
                                        placeholder="Catégorie"
                                        ref={(el) => this.listsEl['Catégorie'] = el}
                                        dataField="CATEG.keyword"
                                        sortBy="asc"
                                        onChange={this.onListItemChange}
                                        react={this.reactUpdateList}
                                    />
                                    */}
                  </div>
                  <div className="search col-12 md:col-6 lg:col-4">
                    <h4>Comptabilité</h4>

                    <SearchAttributeList
                      placeholder="Type"
                      ref={el => (this.listsEl.Type = el)}
                      dataField="RD.keyword"
                      sortBy="asc"
                      onChange={this.onListItemChange}
                      react={this.reactUpdateList}
                      style={{ minHeight: '78px' }}
                    />

                    <hr style={{ border: '1px solid' }} />

                    <SearchAttributeList
                      placeholder="Section"
                      ref={el => (this.listsEl.Section = el)}
                      dataField="FI.keyword"
                      sortBy="asc"
                      // showMissing={true}
                      onChange={this.onListItemChange}
                      react={this.reactUpdateList}
                      style={{ minHeight: '78px' }}
                    />

                    <SearchAttributeList
                      accordionTab
                      placeholder="Nomenclature"
                      ref={el => (this.listsEl.Nomenclature = el)}
                      dataField="NOMEN.keyword"
                      sortBy="asc"
                      onChange={this.onListItemChange}
                      onToggleAll={this.onListToggleAll}
                      react={this.reactUpdateList}
                    />

                    <SearchAttributeList
                      accordionTab
                      placeholder="Entité budgétaire"
                      ref={el => (this.listsEl['Entité budgétaire'] = el)}
                      dataField="LBUDG.keyword"
                      showSearch="remote"
                      showLoadMore // Bug ReactiveSearch
                      sortBy="asc"
                      onChange={this.onListItemChange}
                      onToggleAll={this.onListToggleAll}
                      react={this.reactUpdateList}
                    />

                    <SearchAttributeList
                      accordionTab
                      placeholder="Type de budget"
                      ref={el => (this.listsEl['Type de budget'] = el)}
                      dataField="CBUDG.keyword"
                      sortBy="asc"
                      onChange={this.onListItemChange}
                      react={this.reactUpdateList}
                    />

                    <Accordion>
                      <AccordionTab header="Recherche par nature">
                        <SearchAttributeList
                          accordionTab
                          placeholder="Chapitre Nature"
                          ref={el => (this.listsEl['Chapitre Nature'] = el)}
                          dataField="CHAPITRE.keyword"
                          showSearch
                          sortBy="asc"
                          onChange={this.onListItemChange}
                          onToggleAll={this.onListToggleAll}
                          react={this.reactUpdateList}
                          // selectAllLabel="(tous)"
                        />

                        {/* <CategorySearch
                                        componentId="Recherche"
                                        dataField={["COMPTE", "FONCTION"]}
                                        categoryField="FONCTION.keyword"
                                        placeholder="Recherche par compte ou fonction"
                                        showClear={true}
                                        URLParams={false}
                                        style={{
                                            margin: "10px 0 5px",
                                        }}
                                        react={{
                                            and: ["Type", "Montant", "Section", "Exercice", "Collectivite", "Collecteur", "Chapitre", "Source"]
                                        }}
                                    /> */}

                        <SearchAttributeList
                          accordionTab
                          placeholder="Compte abrégé"
                          ref={el => (this.listsEl['Compte abrégé'] = el)}
                          dataField="COMPTE_ABBR.keyword"
                          showSearch
                          sortBy="asc"
                          onChange={this.onListItemChange}
                          onToggleAll={this.onListToggleAll}
                          react={except => this.reactUpdateList(except, 'Compte de base', 'Compte développé')}
                          // selectAllLabel="(tous)"
                        />
                        <SearchAttributeList
                          accordionTab
                          placeholder="Compte de base"
                          ref={el => (this.listsEl['Compte de base'] = el)}
                          dataField="COMPTE_BASE.keyword"
                          showSearch
                          sortBy="asc"
                          onChange={this.onListItemChange}
                          onToggleAll={this.onListToggleAll}
                          react={except => this.reactUpdateList(except, 'Compte développé')}
                          // selectAllLabel="(tous)"
                        />
                        <SearchAttributeList
                          accordionTab
                          placeholder="Compte développé"
                          ref={el => (this.listsEl['Compte développé'] = el)}
                          dataField="COMPTE.keyword"
                          showSearch="remote"
                          showLoadMore // Bug ReactiveSearch
                          sortBy="asc"
                          onChange={this.onListItemChange}
                          onToggleAll={this.onListToggleAll}
                          react={this.reactUpdateList}
                          // selectAllLabel="(tous)"
                        />
                      </AccordionTab>

                      <AccordionTab header="Recherche par fonction">
                        <SearchAttributeList
                          accordionTab
                          placeholder="Chapitre Fonction"
                          ref={el => (this.listsEl['Chapitre Fonction'] = el)}
                          dataField="CHAPITRE_FN.keyword"
                          showSearch
                          sortBy="asc"
                          onChange={this.onListItemChange}
                          onToggleAll={this.onListToggleAll}
                          react={this.reactUpdateList}
                          // selectAllLabel="(tous)"
                        />

                        <SearchAttributeList
                          accordionTab
                          placeholder="Fonction"
                          ref={el => (this.listsEl.Fonction = el)}
                          dataField="FONCTION_0.keyword"
                          showSearch
                          sortBy="asc"
                          onChange={this.onListItemChange}
                          onToggleAll={this.onListToggleAll}
                          react={except => this.reactUpdateList(except, 'Sous-Fonction', 'Code Fonction')}
                          // selectAllLabel="(tous)"
                        />
                        <SearchAttributeList
                          accordionTab
                          placeholder="Sous-Fonction"
                          ref={el => (this.listsEl['Sous-Fonction'] = el)}
                          dataField="FONCTION_1.keyword"
                          showSearch
                          sortBy="asc"
                          onChange={this.onListItemChange}
                          onToggleAll={this.onListToggleAll}
                          react={except => this.reactUpdateList(except, 'Code Fonction')}
                          // selectAllLabel="(tous)"
                        />

                        <SearchAttributeList
                          accordionTab
                          placeholder="Code Fonction"
                          ref={el => (this.listsEl['Code Fonction'] = el)}
                          dataField="FONCTION.keyword"
                          showSearch="remote"
                          showLoadMore // Bug ReactiveSearch
                          sortBy="asc"
                          onChange={this.onListItemChange}
                          onToggleAll={this.onListToggleAll}
                          react={this.reactUpdateList}
                          // selectAllLabel="(toutes)"
                        />
                      </AccordionTab>
                    </Accordion>

                    {/* <DynamicRangeSlider
                                    componentId="Montant"
                                    dataField="montant"
                                    title="Montants"
                                    URLParams={false}
                                    rangeLabels={(min, max) => (
                                        {
                                            "start": unitFormatter(min, '€'),
                                            "end": unitFormatter(max, '€')
                                        }
                                    )}
                                    react={{
                                        and: ["Recherche", "Type", "Section", "Exercice", "Compte", "Fonction", "Collectivite", "Collecteur", "Chapitre", "Source"]
                                    }}
                                /> */}
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                    <h4>Autres données</h4>

                    <SearchAttributeList
                      accordionTab
                      accordionExpanded={false}
                      placeholder="Données"
                      ref={el => (this.listsEl['Données'] = el)}
                      dataField="description.keyword"
                      showSearch
                      showLoadMore
                      sortBy="asc"
                      onChange={this.onListItemChange}
                      react={this.reactUpdateList}
                    />
                    <SearchAttributeList
                      accordionTab
                      accordionExpanded={false}
                      placeholder="Agrégats"
                      ref={el => (this.listsEl['Agrégats'] = el)}
                      dataField="AGREGAT.keyword"
                      showSearch
                      showLoadMore
                      sortBy="asc"
                      onChange={this.onListItemChange}
                      react={this.reactUpdateList}
                    />
                    <SearchAttributeList
                      accordionTab
                      accordionExpanded={false}
                      placeholder="Requêtes enregistrées"
                      ref={el => (this.listsEl['Requêtes enregistrées'] = el)}
                      dataField="formulaLabel.keyword"
                      size={200}
                      showSearch
                      showLoadMore // Bug ReactiveSearch
                      sortBy="asc"
                      onChange={this.onListItemChange}
                      onToggleAll={this.onListToggleAll}
                      react={this.reactUpdateList}
                    />
                  </div>
                  <div className="col-12">
                    {/* <InputText value={this.state.label} onChange={(e) => this.setState({label: e.target.value})}
                                               placeholder={this.state.placeholder || '(sans nom)'}
                                               style={{width: '50%', marginRight: '10px'}}/> */}

                    <ReactiveComponent
                      componentId="master_query"
                      react={this.reactUpdateListValues()}
                      onQueryChange={this.onQueryChangeRemoveMatchNone.bind(this)}
                      defaultQuery={() => ({
                        query: { match_none: {} },
                      })}
                    />

                    <TabView style={{ padding: 0, marginTop: 0 }} activeIndex={0}>
                      <TabPanel rightIcon="pi pi-arrow-right" header="Résultats">
                        <small>
                          Finalisez vos filtres avant de visualiser les données pour améliorer la performance de
                          l'interface.
                        </small>
                      </TabPanel>
                      <TabPanel header="Données aggrégées">
                        <ReactiveComponent
                          componentId="summary"
                          react={this.reactUpdateListValues()}
                          defaultQuery={() => ({
                            aggs: {
                              AGG: {
                                terms: {
                                  field: `${this.props.aggreg}.keyword`,
                                  missing: 'N/A',
                                  order: { montant: 'desc' },
                                },
                                aggs: {
                                  montant: {
                                    sum: {
                                      field: 'montant',
                                    },
                                  },
                                  unit: {
                                    terms: {
                                      field: 'unit.keyword',
                                      missing: '',
                                    },
                                  },
                                },
                              },
                            },
                          })}
                          onData={({ aggregations }) => {
                            const values = this.getValuesFromAggregations(aggregations);
                            const units = [...new Set(values.map(v => v.units).flat())];
                            this.onValuesChange(values, units);
                          }}
                        >
                          {({ aggregations, loading, error }) => {
                            if (loading) {
                              return <div>Chargement…</div>;
                            }
                            if (error) {
                              return <div>Erreur… {JSON.stringify(error)}</div>;
                            }

                            const values = this.getValuesFromAggregations(aggregations);

                            const footer =
                              aggregations && aggregations.AGG && aggregations.AGG.sum_other_doc_count
                                ? '… (données tronquées) …'
                                : null;

                            return (
                              <div>
                                <DataTable value={values} responsive footer={footer}>
                                  <Column field="key" style={{ overflow: 'visible' }} header="" />
                                  <Column
                                    field="value"
                                    header="Somme"
                                    body={(rowData, column) =>
                                      unitFormatter(
                                        rowData[column.field],
                                        rowData.units.length === 1 ? rowData.units[0] : ''
                                      )
                                    }
                                    className="montant"
                                  />
                                </DataTable>
                              </div>
                            );
                          }}
                        </ReactiveComponent>
                      </TabPanel>
                      <TabPanel header="Détail">
                        <ReactiveList
                          componentId="result"
                          dataField="_source"
                          title="Results"
                          size={this.state.nbPerPage}
                          pagination
                          loader="Chargement…"
                          react={this.reactUpdateListValues()}
                          renderResultStats={function (stats) {
                            const nb =
                              (stats.numberOfPages === 1000 ? '+ de ' : '') +
                              new Intl.NumberFormat('fr-FR').format(stats.numberOfResults);
                            return (
                              <p>
                                {nb} lignes trouvées <small>en {stats.time} ms</small>
                              </p>
                            );
                          }}
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          {({ loading, error, data }) => {
                            if (loading) {
                              return <div />;
                            }
                            if (error) {
                              return <div>Erreur… {JSON.stringify(error)}</div>;
                            }
                            if (data) {
                              return (
                                <div>
                                  <TableDetails
                                    value={data}
                                    paginator={this.state.nbPerPage !== 10}
                                    onNbPerPageChange={nbPerPage => this.setState({ nbPerPage })}
                                    nbPerPage={this.state.nbPerPage}
                                  />
                                </div>
                              );
                            }
                          }}
                        </ReactiveList>
                      </TabPanel>
                    </TabView>
                  </div>
                </div>
              </Panel>
            </ReactiveBase>
          </div>
        </div>
      </Sidebar>
    );
  };
}

export default SearchModalLegacy;
