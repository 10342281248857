import React from 'react';
import * as PropTypes from 'prop-types';

import './LayoutWithSidebar.scss';
import { AppSidebar } from '..';

export default function LayoutWithSidebar({ children }) {
  return (
    <div className="layout-with-left-nav">
      <div className="left-sidebar-container">
        <div className="left-sidebar">
          <AppSidebar />
        </div>
      </div>
      <div className="viewport">{children}</div>
    </div>
  );
}

LayoutWithSidebar.propTypes = {
  children: PropTypes.node,
};
