import React, { useState, useRef, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ReactiveBase, StateProvider } from '@appbaseio/reactivesearch';

import { App, Credentials, Host } from 'config/elastic';
import { DatasetType } from 'propTypes';
import { useDebouncedCallback } from 'utils';
import { FormulaInput2, SearchCriteria, SearchResultsPane, ImportModal } from './components';
import SearchModalHelper from './SearchModalHelper';
import elasticSearchState from './ElasticSearchState';

import './SearchModal.scss';

const debugMode = false;

// Select the first member having a set OR the first OR nothing
const getInitialSelectedMember = members => {
  if (members.length > 0) {
    const firstSetMember = members.find(member => member.setId > -1);
    return firstSetMember || members[0];
  }
};

export default function SearchModal({
  set = {},
  title = '',
  aggreg = '',
  defaultImportAggregs,
  onClose = () => {},
  onSave = () => {},
}) {
  const toast = useRef(null);
  const [importVisible, setImportVisible] = useState(false);
  const [cursorPosition, setCursorPosition] = useState();
  const [criteriaBySet, setCriteriaBySet] = useState(SearchModalHelper.getCriteriaBySet(set));
  const [queryBySet, setQueryBySet] = useState(SearchModalHelper.getQueryBySet(set));
  const [members, setMembers] = useState(SearchModalHelper.getMembersFromSet(set));
  // TODO See if it works with last version of reactiveSearch
  // const [aggregatedOptions, setAggregatedOptions] = useState({});
  const [selectedMember, setSelectedMember] = useState(getInitialSelectedMember(members));
  const [currentSetCriteria, setCurrentSetCriteria] = useState(
    criteriaBySet.find(item => item.setId === selectedMember.setId && selectedMember.setId !== -1)?.criteria
  );

  // The prop 'blockScroll' of Sidebar does not work
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);

  const onFormulaChange = newMembers => {
    if (newMembers.length !== members.length) {
      // A member has been deleted.
      const mergedMembers = SearchModalHelper.getMergedMembers(newMembers);
      // Select previous member
      const selectedMemberIndex = members.findIndex(m => m.id === selectedMember.id);
      const newMember = selectedMemberIndex === 0 ? members[0] : members[selectedMemberIndex - 1];
      setSelectedMember(newMember);
      setMembers(mergedMembers);

      // Delete query items if the corresponding member no longer exists
      const existingSetIds = newMembers.map(item => item.setId).filter(setId => setId > -1);
      const filteredQueryItems = queryBySet.filter(item => existingSetIds.indexOf(item.setId) > -1);
      setQueryBySet(filteredQueryItems);

      const setCriteria =
        newMember.setId > -1 ? criteriaBySet.find(item => item.setId === newMember.setId)?.criteria : {};
      setCurrentSetCriteria(setCriteria);
    } else {
      setMembers(newMembers);
    }
  };

  // Build an Elastic query based on the selected
  const buildQuery = criteria => {
    const queryTerms = Object.entries(criteria).map(([key, value]) => ({
      bool: { should: [{ terms: { [`${key}.keyword`]: value } }] },
    }));

    const aggsTerm = { 'FI.keyword': { terms: { field: 'FI.keyword', size: 1000, order: { _term: 'asc' } } } };

    return { query: { bool: { must: [{ bool: { must: queryTerms } }] } }, size: 0, aggs: aggsTerm };
  };

  const onMemberSelected = (memberId, newCursorPosition) => {
    const member = members.find(m => m.id === memberId);
    setSelectedMember(member);
    setCursorPosition(newCursorPosition);

    const setCriteria = member.setId > -1 ? criteriaBySet.find(item => item.setId === member.setId)?.criteria : {};
    setCurrentSetCriteria(setCriteria);
  };

  const onCriteriaChange = newValue => {
    if (selectedMember.setId === -1 && Object.prototype.hasOwnProperty.call(selectedMember, 'exer')) {
      return;
    }

    const newQuery = buildQuery(newValue);

    if (selectedMember.setId < 0) {
      const newMember = SearchModalHelper.createNewMember(members, newValue);
      const updatedMembers =
        cursorPosition === selectedMember.formula.length || cursorPosition === 0
          ? SearchModalHelper.addMemberBeforeOrAfterText(members, selectedMember, newMember, cursorPosition)
          : SearchModalHelper.addMemberInsideText(members, selectedMember, newMember, cursorPosition);

      setMembers(updatedMembers);
      setSelectedMember(newMember);

      const updatedCriteriaBySet = [...criteriaBySet, { setId: newMember.setId, criteria: newValue }];
      const updatedQueryBySet = [...queryBySet, { setId: newMember.setId, query: newQuery }];
      setCriteriaBySet(updatedCriteriaBySet);
      setQueryBySet(updatedQueryBySet);
      setCurrentSetCriteria(newValue);
    } else {
      const updatedMembers = SearchModalHelper.getUpdatedMembersWhenSetMemberSelected(
        members,
        selectedMember,
        newValue
      );
      const newSelectedMember = SearchModalHelper.getNewSelectedMember(members, selectedMember, updatedMembers);
      setMembers(updatedMembers);
      setSelectedMember(newSelectedMember);

      const updatedCriteriaBySet = SearchModalHelper.isCriteriaEmpty(newValue)
        ? criteriaBySet.filter(item => item.setId !== selectedMember.setId)
        : criteriaBySet.map(item => {
            if (item.setId === selectedMember.setId) {
              return { ...item, criteria: newValue };
            }
            return item;
          });

      const updatedQueryBySet = SearchModalHelper.isCriteriaEmpty(newValue)
        ? queryBySet.filter(item => item.setId !== selectedMember.setId)
        : queryBySet.map(item => {
            if (item.setId === selectedMember.setId) {
              return { ...item, query: newQuery };
            }
            return item;
          });

      setCriteriaBySet(updatedCriteriaBySet);
      setQueryBySet(updatedQueryBySet);
      setCurrentSetCriteria(newValue);
    }
  };

  const onStateChange = useDebouncedCallback((prev, next) => {
    if (Object.keys(prev).length === 0) {
      console.log('onStateChange: State change ignore');
      return;
    }
    const mappedEntries = Object.entries(next).map(([k, v]) => [k, v.aggregations || []]);
    const options = Object.fromEntries(mappedEntries);
    // TODO See if it works with last version of reactiveSearch
    // setAggregatedOptions(options);
    elasticSearchState.setAggregatedOptions(options);
  }, 250);

  const save = () => {
    // Rebuild dataset and send it to parent
    onSave(SearchModalHelper.rebuildSet(set, members, criteriaBySet, queryBySet));
    onClose();
  };

  return (
    <Sidebar
      fullScreen
      visible
      onHide={() => onClose()}
      appendTo={document.getElementById('modal-placeholder')}
      // icons={() => (
      //   <div className="sidebar-buttons">
      //     <Button icon="pi pi-save" label="Enregistrer" onClick={() => setImportVisible(true)} />
      //     <Button label="OK" onClick={save} className="p-button-success" />
      //   </div>
      // )}
    >
      <div className="search-modal">
        <div className="search-modal-content">
          {title && <h3>{title}</h3>}

          {debugMode && (
            <div style={{ border: '1px solid firebrick', padding: '10px', margin: '10px 0' }}>
              <pre>Members : {JSON.stringify(members, null, 2)}</pre>
              <pre>selectedMember : {JSON.stringify(selectedMember, null, 2)}</pre>
              <pre>cursorPosition : {cursorPosition}</pre>
              <pre>currentSetCriteria : {JSON.stringify(currentSetCriteria, null, 2)}</pre>
              {/* <pre>criteriaBySet : {JSON.stringify(criteriaBySet, null, 2)}</pre> */}
              <pre>
                queryBySet :{' '}
                {JSON.stringify(
                  queryBySet.map(o => o.setId),
                  null,
                  2
                )}
              </pre>
            </div>
          )}

          <div className="p-toolbar">
            <FormulaInput2
              members={members}
              onChange={onFormulaChange}
              selectedMemberId={selectedMember?.id}
              onMemberSelected={onMemberSelected}
            />
            <div className="search-modal-controls">
              <Button icon="pi pi-save" onClick={() => setImportVisible(true)} />
              <Button label="OK" onClick={save} className="p-button-success" />
            </div>
          </div>

          <ReactiveBase url={Host} app={App} credentials={Credentials} className="reactive-block">
            <StateProvider includeKeys={['value', 'aggregations']} strict onChange={onStateChange} />
            <SearchCriteria
              value={currentSetCriteria}
              // TODO See if it works with last version of reactiveSearch
              // options={aggregatedOptions}
              onChange={onCriteriaChange}
            />
            <SearchResultsPane
              datasetLabel={selectedMember.setId > -1 ? selectedMember.label : ''}
              aggregation={aggreg}
            />
          </ReactiveBase>
        </div>
      </div>

      {importVisible && (
        <ImportModal
          visible={importVisible}
          onHide={() => setImportVisible(false)}
          growl={toast.current}
          formula={SearchModalHelper.rebuildSet(set, members, criteriaBySet, queryBySet)}
          title={title}
          defaultAggregs={defaultImportAggregs}
        />
      )}
      <Toast ref={toast} />
    </Sidebar>
  );
}

SearchModal.propTypes = {
  set: DatasetType,
  title: PropTypes.string,
  aggreg: PropTypes.string,
  defaultImportAggregs: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};
