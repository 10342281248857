import { App, Client as elastic, FormulaApp } from 'config/elastic';
import { FormulaParser } from '../utils';
import { aggregationService, userService } from './index';

const doDeleteFormula = async (index, termSearch) => {
  const res = await elastic.deleteByQuery({
    body: { query: { bool: { must: { term: termSearch } } } },
    index,
    conflicts: 'proceed',
    refresh: 'wait_for',
  });
  return res?.deleted || 0;
};

const deleteFormulaById = async formulaId => {
  const nbDeleted = await doDeleteFormula(App, { 'formula.keyword': formulaId });
  await elastic.delete({ index: FormulaApp, id: formulaId, refresh: 'wait_for' });
  return nbDeleted;
};

const deleteFormulaByLabel = async label => {
  const nbDeleted = await doDeleteFormula(App, { 'formulaLabel.keyword': label });
  await doDeleteFormula(FormulaApp, { label });
  return nbDeleted;
};

/**
 * Delete a formula : by its ID then by its label
 * @param formulaId
 * @param label
 * @returns {Promise<number>}
 */
const internalDeleteFormula = async ({ _id: formulaId }, label) => {
  let nbDeleted = 0;
  if (formulaId) {
    nbDeleted += await deleteFormulaById(formulaId);
  }
  if (label) {
    nbDeleted += await deleteFormulaByLabel(label);
  }
  return nbDeleted;
};

/**
 * Create a formula entry in formula index
 * @param formula the formula
 * @param label the name of the formula
 * @param aggregs aggregations
 * @param shared boolean the formula is shared
 * @param userId id of connected user
 * @param username name of connected user
 * @returns {Promise<*>}
 */
const doCreateFormula = async (formula, label, aggregs, shared, userId, username) =>
  elastic.index({
    index: FormulaApp,
    refresh: 'true',
    body: {
      date: new Date(),
      label,
      shared,
      aggregs,
      formula: JSON.stringify({
        ...formula,
        inset: undefined,
        values: undefined,
        sets: formula.sets.map(s => ({ ...s, inset: undefined, values: undefined })),
      }),
      owner: { id: userId, name: username },
    },
  });

// Get table rows from dataset : stack all values of all aggregations into separate rows
const extractRowsFromDataset = (data, aggregs, res) => {
  const aggreg = aggregs.shift();
  const isLastLevel = aggregs.length === 0;
  let ret = Object.entries(isLastLevel ? data.values : data.inset).map(([key, value]) => {
    res[aggreg] = key;
    if (isLastLevel) {
      res.montant = value;
      return JSON.parse(JSON.stringify(res));
    }
    return extractRowsFromDataset(value, aggregs.concat(), res);
  });
  if (!isLastLevel) {
    ret = ret.flat();
  }
  return ret;
};

const listFormulae = async (query = {}) => {
  const searchResult = await elastic.search({
    index: FormulaApp,
    body: { ...query, size: 10000, sort: [{ date: { order: 'desc' } }, 'label.keyword'] },
  });

  const parser = new FormulaParser();
  return searchResult.hits.hits.map(hit => {
    const item = { ...hit };
    // eslint-disable-next-line no-underscore-dangle
    item.formula = parser.repair(JSON.parse(item._source.formula));
    // eslint-disable-next-line no-underscore-dangle
    item.formula._id = item._id;
    return item;
  });
};

const getFormulaDependencies = async () => {
  // Get all formulae
  const allFormulae = await listFormulae();

  return allFormulae.map(formula => {
    // Extract used formulae in formula.formula.sets[].lists[].formulaLabel -> names
    const formulaDeps = formula.formula.sets.reduce((acc, item) => {
      const items = [...(item.lists.formulaLabel || []), ...(item.lists['Requêtes enregistrées'] || [])];
      acc.push(items);
      return acc;
    }, []);
    return { formula, deps: [...new Set(formulaDeps.flat())] };
  });
};

const getFormulaParents = async label => {
  const deps = await getFormulaDependencies();
  return deps.filter(item => item.deps.find(name => name === label));
};

// Prints a log of all formulae using deleted ones
const getInvalidFormulae = async () => {
  const deps = await getFormulaDependencies();
  // eslint-disable-next-line no-underscore-dangle
  const formulaNames = deps.map(dep => dep.formula._source.label);
  const invalidFormulae = deps
    .filter(item => item.deps.some(dep => formulaNames.indexOf(dep) < 0))
    .map(item =>
      // Keep only invalid dependencies
      ({ ...item, deps: item.deps.filter(dep => formulaNames.indexOf(dep) < 0) })
    );
  console.log('invalidFormulae :', invalidFormulae);

  invalidFormulae.forEach(item => {
    // eslint-disable-next-line no-underscore-dangle
    console.log(`La formule "${item.formula._source.label}" utilise :`);
    item.deps.forEach(dep => {
      console.log(` - ${dep}`);
    });
  });
};

class FormulaService {
  async printInvalidFormulae() {
    return getInvalidFormulae();
  }

  /**
   * List formulae belonging to a user + public formulae (shared)
   * If the user is admin, get all items
   * @returns {Promise<*>}
   */
  async getFormulae() {
    const isAdmin = userService.isAdmin();
    const { id: userId } = userService.getUser();
    const ownerConstraint = isAdmin
      ? {}
      : { query: { bool: { should: [{ match: { 'owner.id': userId } }, { match: { shared: true } }] } } };
    return listFormulae(ownerConstraint);
  }

  /**
   * Delete a formula : by its ID then by its label
   * @param formula
   * @param label
   * @returns {Promise<number>}
   */
  async deleteFormula(formula, label) {
    const parents = await getFormulaParents(label);
    if (parents.length > 0) {
      // eslint-disable-next-line no-underscore-dangle
      const parentLabels = parents.map(parent => parent.formula._source.label).join(', ');
      throw new Error(`La formule est utilisée par ${parentLabels}`);
    }
    return internalDeleteFormula(formula, label);
  }

  // Bulk insert formula values into data index
  /**
   * Bulk insert formula values or csv values into data index
   * @param rows data rows
   * @param formulaId id of related formula, if any
   * @returns {Promise<{body}|*>}
   */
  async bulkIndexRows(rows, formulaId) {
    const body = rows
      .map(row =>
        // eslint-disable-next-line no-underscore-dangle
        [{ index: { _index: App, _id: row._id } }, { ...row, _id: undefined, formula: formulaId }]
      )
      .flat();

    const res = await elastic.bulk({ body, index: App, timeout: '120s', refresh: 'true' });

    if (res) {
      if (res?.body?.errors) {
        console.error('bulk error');
        const errorsList = res.body.items
          .filter(i => i.index.status !== 200)
          .map(i => [i.index.status, i.index.error])
          .reduce((acc, [k, v]) => {
            acc[k] = v;
            return acc;
          }, {});
        console.debug(
          'Erreurs',
          res.body.items
            .filter(i => i.index.status !== 200)
            .map(i => [i.index.status, i.index.error])
            .reduce((acc, [k, v]) => {
              acc[k] = v;
              return acc;
            }, {})
        );
        throw new Error(`Erreur d'indexation des données :${errorsList}`);
      }
      if (res.warnings) {
        console.warn('bulk warning', res.warnings);
      }
    }
    console.debug('bulk success');
    return res;
  }

  /**
   * Creates a formula : delete previous formula and values if any, then create formula + insert values into /data
   * @param formula the formula
   * @param formulaLabel the name of the formula
   * @param formulaValues the formula values to insert into /data
   * @param aggregs aggregations
   * @param shared boolean the formula is shared
   * @returns {*} Number of values inserted
   */
  async createFormula(formula, formulaLabel, formulaValues, aggregs, shared) {
    const allFormulaNames = await listFormulae();
    // eslint-disable-next-line no-underscore-dangle
    const isDuplicateName = allFormulaNames.filter(item => item._source.label === formulaLabel).length > 0;

    if (isDuplicateName) {
      throw new Error(`Le nom de formule ${formulaLabel} est déjà utilisé`);
    }
    const user = userService.getUser();
    await internalDeleteFormula(formula, formulaLabel);
    const creationResult = await doCreateFormula(formula, formulaLabel, aggregs, shared, user.id, user.name);
    const { _id: formulaId } = creationResult;
    await this.bulkIndexRows(formulaValues, formulaId);
    return formulaValues.length;
  }

  /**
   * Refresh a formula : calculate formula values then recreate the formula
   * @param formula the formula to refresh
   * @param formulaLabel the name of the formula
   * @param aggregs aggregations
   * @param shared is the formula shared of not
   * @returns {*} the number of rows inserted into /data
   */
  async refreshFormula(formula, formulaLabel, aggregs, shared) {
    // We don't check that the formula name is unique
    const user = userService.getUser();
    const formulaValues = await this.getFormulaData(formula, formulaLabel, aggregs);

    await internalDeleteFormula(formula, formulaLabel);
    const creationResult = await doCreateFormula(formula, formulaLabel, aggregs, shared, user.id, user.name);
    const { _id: formulaId } = creationResult;
    await this.bulkIndexRows(formulaValues, formulaId);
    return formulaValues.length;
  }

  async getFormulaData(formula, formulaLabel, aggregs) {
    const parser = new FormulaParser();
    const parsedFormula = { ...parser.parse(formula) };
    const conditions = [];

    // eslint-disable-next-line no-underscore-dangle
    if (parsedFormula._id) {
      // eslint-disable-next-line no-underscore-dangle
      conditions.push({ term: { 'formula.keyword': parsedFormula._id } });
    }
    const label = formulaLabel || parsedFormula.placeholder;
    if (label) {
      conditions.push({ term: { 'formulaLabel.keyword': label } });
    }

    const query = {
      bool: { must_not: conditions },
    };
    const filterSet = { sets: [{ query: { query } }] };

    // Calculate values
    const { dataSets } = await aggregationService.updateData([parsedFormula], filterSet, null, aggregs, 100000);

    const rows = extractRowsFromDataset(dataSets[0], [...aggregs], {
      formulaLabel: label,
      SOURCE: dataSets[0].SOURCE || 'Requêtes enregistrées',
      unit: dataSets[0].unit,
    }).filter(v => v.montant > 0);

    return rows;
  }

  async toggleFormulaSharing(formulaId, newSharingValue) {
    const res = await elastic.updateByQuery({
      body: {
        script: { source: `ctx._source.shared = ${newSharingValue}`, lang: 'painless' },
        query: { bool: { must: [{ match: { _id: formulaId } }] } },
      },
      index: FormulaApp,
      conflicts: 'proceed',
      refresh: 'wait_for',
    });
    return res?.updated || 0;
  }
}

export default new FormulaService();
