import React, { useState, useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { ColorPicker } from 'primereact/colorpicker';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';

import { DatasetType } from 'propTypes';
import { SearchModalNew, SearchModal, SearchModalLegacy } from 'components';

import './FormulaBuilder.scss';

/**
 * Defines a dataSet characteristics and uses the SearchModal to write formulae
 * @param set the current dataset to build
 * @param chartType toggles between bar/line. Allows overriding chartType in ProfilChart and mix bar + line
 * @param aggregs aggregations
 * @param disabled
 * @param onChange Triggered when the formula, color, unit, chartType changes
 * @param onDelete Triggered when clicking on the delete button
 * @param onDuplicate Triggered when clicking on the duplicate button
 */
export default function FormulaBuilder({
  set,
  chartType,
  aggregs = [],
  disabled = false,
  onChange = () => {},
  onDelete = () => {},
  onDuplicate = () => {},
}) {
  const [showSearch, setShowSearch] = useState(false);
  const [showSearchNew, setShowSearchNew] = useState(false);
  const searchPaneLegacy = useRef(null);
  const hasColor = useMemo(() => ['stack', 'bar', 'line', 'radar'].includes(chartType), [chartType]);
  const hasCurve = useMemo(() => ['stack', 'bar'].includes(chartType), [chartType]);

  // Merge given properties with the set before onChanges
  const onSetUpdate = newValues => {
    onChange({ ...set, ...newValues });
  };

  const showSearchPane = () => {
    setShowSearch(true);
  };

  // TODO remove Legacy search pane
  // const showSearchPaneLegacy = () => {
  //   searchPaneLegacy.current.show();
  // };

  // TODO remove new search pane (with new text formula)
  // const showSearchPaneNew = () => {
  //   setShowSearchNew(true);
  // };

  const toolbarContents = (
    <div className="formula-builder-wrapper">
      <div className="left-block">
        <div className="inputs-row">
          <div className="p-inputgroup" style={{ display: 'inline-flex', maxWidth: '100%' }}>
            {hasColor && (
              <ColorPicker
                value={`${set.color || ''}`.replace('#', '')}
                onChange={event => onSetUpdate({ color: `#${event.value}` })}
                disabled={disabled}
              />
            )}
            {hasCurve && (
              <Button
                label=""
                className={set.chartType === 'line' ? 'p-button-info' : 'p-button-secondary p-button-outlined'}
                icon="pi pi-chart-bar"
                style={{ borderColor: '#a6a6a6', borderLeft: 'none', borderRight: 'none' }}
                title="Basculer courbe / histogrammes"
                onClick={() => onSetUpdate({ chartType: set.chartType === 'line' ? 'bar' : 'line' })}
                disabled={disabled}
              />
            )}
            <InputText
              value={set.label || ''}
              onChange={event => onSetUpdate({ label: event.target.value })}
              onFocus={() => {
                if (!set.formula) {
                  showSearchPane();
                }
              }}
              onDoubleClick={showSearchPane}
              placeholder={set.placeholder || 'Nom du jeu de données'}
              style={{ width: '600px', margin: '0' }}
              disabled={disabled}
            />

            <Button
              label=""
              icon="pi pi-folder-open"
              style={{ marginRight: '5px', borderRadius: '0 5px 5px 0' }}
              className="p-button-warning"
              onClick={showSearchPane}
              disabled={disabled}
            />

            {/* //TODO remove */}
            {/* <Button */}
            {/*  label="Legacy" */}
            {/*  style={{ marginRight: '5px' }} */}
            {/*  className="p-button-warning" */}
            {/*  onClick={showSearchPaneLegacy} */}
            {/* /> */}

            {/* //TODO remove */}
            {/* <Button */}
            {/*  label="New" */}
            {/*  // icon="pi pi-folder" */}
            {/*  style={{ marginRight: '5px' }} */}
            {/*  className="p-button-warning" */}
            {/*  onClick={showSearchPaneNew} */}
            {/* /> */}
          </div>
          <InputText
            value={set.unit || ''}
            onChange={event => onSetUpdate({ unit: event.target.value })}
            placeholder={set.unitPlaceholder || 'unité'}
            style={{ width: '5em', margin: '0 0 0 0' }}
            disabled={disabled}
          />
        </div>
        {set.label && (
          <div
            style={{
              padding: `5px 5px 5px ${(hasColor ? 33 : 0) + (hasCurve ? 31 : 0) + 5}px`,
              fontSize: '.8em',
            }}
          >
            {set.placeholder}
          </div>
        )}
      </div>
      {set.defaultError && <Message severity="error" text="Erreur de syntaxe" />}

      {/* TODO remove. Old SearchModal with class component */}
      <SearchModalLegacy set={set} onSave={onSetUpdate} ref={searchPaneLegacy} aggreg={aggregs[0]} />

      {/* TODO Remove. SearchModal with a new FormulaInput */}
      {showSearchNew && (
        <SearchModalNew set={set} onSave={onSetUpdate} aggreg={aggregs[0]} onClose={() => setShowSearchNew(false)} />
      )}

      {showSearch && (
        <SearchModal set={set} onSave={onSetUpdate} aggreg={aggregs[0]} onClose={() => setShowSearch(false)} />
      )}
    </div>
  );

  const toolbarCloseBtn = (
    <div>
      {set.sets.length > 0 && (
        <Button
          label=""
          className="p-button-secondary p-button-rounded p-button-text p-button-icon-only"
          icon="pi pi-copy"
          onClick={onDuplicate}
          title="Dupliquer le jeu de données"
          disabled={disabled}
        />
      )}
      <Button
        label=""
        className="p-button-secondary p-button-rounded p-button-text p-button-icon-only"
        icon="pi pi-times"
        onClick={onDelete}
        title="Supprimer le jeu de données"
        disabled={disabled}
      />
    </div>
  );

  return (
    <div className="builder-block">
      {toolbarContents}
      {toolbarCloseBtn}
    </div>
  );
}

FormulaBuilder.propTypes = {
  set: DatasetType,
  chartType: PropTypes.string,
  aggregs: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
};
