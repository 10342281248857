import React, { useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';

import { formulaService } from 'services';
import { Constants } from 'utils';
import { LoadDots, TableDetails, Aggregator } from '../../..';

import './ImportModal.scss';

export default function ImportModal({
  formula,
  title = '',
  defaultAggregs = Constants.DEFAULT_IMPORT_AGGREGATIONS,
  visible,
  onHide,
  growl,
}) {
  const [computingValues, setComputingValues] = useState(false);
  const [importingData, setImportingData] = useState(false);
  const [sample, setSample] = useState([]);
  const [data, setData] = useState([]);
  const [aggregations, setAggregations] = useState(defaultAggregs);
  const [formulaLabel, setFormulaLabel] = useState(title);
  const [shared, setShared] = useState(false);
  const toast = useRef(null);

  const importToElastic = async () => {
    try {
      setImportingData(true);
      const label = formulaLabel || formula.placeholder;
      const importResult = await formulaService.createFormula(formula, label, data, aggregations, shared);

      growl.show({
        severity: 'success',
        summary: 'Traitement terminé !',
        detail: `${importResult} lignes importées`,
      });
      setData([]);
      setSample([]);
      setImportingData(false);
      onHide();
    } catch (error) {
      console.error('Create formula error :', error);
      toast.current.show({
        severity: 'error',
        sticky: false,
        summary: "Impossible d'enregistrer les données",
        detail: `Une erreur est survenue, veuillez réessayer ou rafraîchir les données.\n\n${error && error.message}`,
      });
      setImportingData(false);
      onHide();
    }
  };

  const computeFormula = async () => {
    setComputingValues(true);
    const label = formulaLabel || formula.placeholder;
    const formulaValues = await formulaService.getFormulaData(formula, label, aggregations);
    if (formulaValues.length === 0) {
      growl.show({ severity: 'warn', summary: 'Aucune ligne à importer' });
    } else {
      setData(formulaValues);
      setSample(formulaValues);
    }
    setComputingValues(false);
  };

  const onAggregatorChange = newValue => {
    setAggregations(newValue);
    if (sample.length > 0) {
      setSample([]);
      setData([]);
    }
  };

  const onFormulaLabelChange = newValue => {
    setFormulaLabel(newValue);
    setSample([]);
    setData([]);
  };

  const renderForm = () => (
    <div style={{ marginBottom: '10px' }}>
      <Aggregator value={aggregations} onChange={onAggregatorChange} />
      <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
        <label htmlFor="name" style={{ whiteSpace: 'no-wrap' }}>
          Libellé&nbsp;:
        </label>
        <div className="p-inputgroup" style={{ marginLeft: '.5em', display: 'inline-block' }}>
          <InputText
            value={formulaLabel}
            onChange={e => onFormulaLabelChange(e.target.value)}
            placeholder={formula.placeholder || 'Nommer cette formule'}
            keyfilter={/^[^@[\]()]+$/}
            id="name"
            style={{ width: '30em', margin: '0 0 0 0' }}
          />
        </div>
      </div>
      <div className="shared-switch">
        <div>Requête partagée</div>
        <InputSwitch checked={shared} onChange={() => setShared(prev => !prev)} />
      </div>
      <div className="btn-with-loader">
        <Button
          className="p-button-warning p-inputgroup-addon"
          onClick={computeFormula}
          label="Vérifier"
          disabled={!(formulaLabel || formula.placeholder)}
        />
        {computingValues && <LoadDots />}
      </div>
    </div>
  );

  return (
    <Dialog header="Importer" visible={visible} style={{ width: '80vw' }} modal onHide={onHide}>
      <Toast ref={toast} />
      {renderForm()}

      {sample.length > 0 && (
        <div>
          <TableDetails value={sample} filter />

          <p>
            {sample.length} lignes traitées dont {data.length} ligne(s) valide(s).
          </p>

          <div className="btn-with-loader">
            <Button
              label={`Importer ${data.length} ligne(s)`}
              icon="pi pi-upload"
              className="p-button-success "
              onClick={importToElastic}
              disabled={data.length === 0}
            />
            {importingData && <LoadDots />}
          </div>
        </div>
      )}
    </Dialog>
  );
}

ImportModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formula: PropTypes.any,
  title: PropTypes.string,
  defaultAggregs: PropTypes.arrayOf(PropTypes.string),
  visible: PropTypes.bool,
  onHide: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  growl: PropTypes.any,
};
