import React, { useState, useEffect, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { DataTable as DataTablePrime } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { unitFormatter, Constants } from 'utils';

import './ResultDataTable.scss';

/**
 * The labels are sorted by the values of sortValues
 * @param sortValues the values used to sort : {label: value}
 * @param reverse sort direction
 * @returns {function(*=, *=): *} the sorting function
 */
const sortLabels = (sortValues, reverse) => (label1, label2) => {
  /* Les années sont triées dans l'ordre */
  if (!Number.isNaN(parseFloat(label1)) && !Number.isNaN(parseFloat(label2))) {
    return (reverse ? -1 : 1) * (label1 - label2);
  }
  const v1 = sortValues[label1] || 0;
  const v2 = sortValues[label2] || 0;
  return (reverse ? -1 : 1) * (v2 - v1);
};

/**
 *
 * @param dataSets the sets containing the data to display
 * @param sorterSet the set containing the sort direction and the values to use when sorting
 * @param dataTitle the title of the data
 * @param aggregations the list of aggregations
 */
export default function ResultDataTable({ dataSets = [], sorterSet = {}, dataTitle = '', aggregations = [] }) {
  // Extract and sort the data to display, based on the dataSets contents
  const getDataFromDatasets = useCallback(
    sets => {
      // 1- get values by set [ {2019: 12345, 2018: 87654}, {2019: 24242, 2018: 84745} ]
      const setsValues = sets.map(set => set.values || {});

      // 2- get the values of the sorterSet. If no data, use the values of the last set
      let sortValues = sorterSet.values || {};
      if (Object.keys(sortValues).length === 0) {
        sortValues = setsValues[setsValues.length - 1] || {};
      }

      // 3- get all the labels of all the sets and sort them by sorterSet values
      const labels = [...new Set(setsValues.map(d => Object.keys(d)).flat())].sort(
        sortLabels(sortValues, sorterSet.reverse)
      );

      // 4- associate the values of all the sets for each label : [v1, v2, v3, label]
      return labels.map(label => [...sets.map(set => set.values?.[label]), label]);
    },
    [sorterSet]
  );

  // Data to be displayed in the Table
  const [data, setData] = useState(getDataFromDatasets(dataSets));

  // Data structure holding dataSets, title and parent structure if any
  const [dataStructure, setDataStructure] = useState({ sets: dataSets, dataTitle });

  useEffect(() => {
    setDataStructure({ sets: dataSets, dataTitle });
    setData(getDataFromDatasets(dataSets));
  }, [dataSets, dataTitle, getDataFromDatasets]);

  const navigateToParent = () => {
    if (dataStructure.parent) {
      setDataStructure(dataStructure.parent);
      setData(getDataFromDatasets(dataStructure.parent.sets));
    }
  };

  const navigateToChild = label => {
    const childrenSets = dataStructure.sets.map(set => {
      const { values, inset } = set.inset?.[label];
      return {
        ...set,
        unit: set.unit || dataStructure.parent?.unit,
        values,
        inset,
      };
    });

    const newDataStructure = {
      sets: childrenSets,
      dataTitle: [dataTitle, label].filter(i => i).join(' - '),
      parent: JSON.parse(JSON.stringify(dataStructure)),
    };

    setDataStructure(newDataStructure);
    setData(getDataFromDatasets(newDataStructure.sets));
  };

  const exportAllData = () => {
    const isInsetEmpty = inset => {
      const insetKeys = Object.keys(inset);
      return insetKeys.map(k => Object.keys(inset[k].inset).length).every(item => item === 0);
    };

    const flattenDatasets = (localSets, key = []) => {
      // get values by set [ {2019: 12345, 2018: 87654}, {2019: 24242, 2018: 84745} ]
      const setsValues = localSets.map(set => set.values || {});

      // get all the labels associated to the values -> ['2010', '2011', '2012', ...]
      const labels = [...new Set(setsValues.map(d => Object.keys(d)).flat())];

      // if insets are empty we collect data
      if (localSets.map(set => set.inset || {}).every(inset => isInsetEmpty(inset))) {
        // associate the values of all the sets for each label : [key1, key2, ..., label, v1, v2, v3]
        return labels.map(label => [
          ...key,
          label,
          // TODO option to export unformatted data
          // ...localSets.map(set => set.values?.[label]),
          // Format the numbers
          ...localSets.map(set => unitFormatter(set.values?.[label], set.unit || set.unitPlaceholder)),
        ]);
      }

      // We go down the inset
      // For the label we get all the rows of the inset
      return labels.flatMap(value =>
        flattenDatasets(
          // Keep unit and unitPlaceholder for further formatting
          localSets.map(s => ({ ...s.inset[value], unit: s.unit, unitPlaceholder: s.unitPlaceholder })),
          [...key, value]
        )
      );
    };

    const flattenedData = flattenDatasets(dataSets);

    // Get aggregations labels
    const aggregLabels = aggregations.map(
      agg => Constants.AGGREGATIONS.find(item => item.value === agg)?.label || 'XXXX'
    );

    const headerLine = [...aggregLabels, ...dataStructure.sets.map(set => set.label || set.placeholder || '')];

    const SEP = ';';
    const csvData = `data:text/csv;charset=utf-8,${headerLine.join(SEP)}\n${flattenedData
      .map(r => r.join(SEP))
      .join('\n')}`;
    const link = document.createElement('a');
    link.setAttribute('href', encodeURI(csvData));
    link.setAttribute('download', 'export.csv');
    document.body.appendChild(link);
    link.click();
  };

  // Manual export because we want semicolon as separator and comma as decimal separator
  const exportData = () => {
    const SEP = ';';
    const headerLine = ['', ...dataStructure.sets.map(set => set.label || set.placeholder || '')];
    const rows = data.map(dataRow => [
      dataRow[dataStructure.sets.length],
      // TODO option to export unformatted data
      ...dataStructure.sets.map((set, index) => unitFormatter(dataRow[index], set.unit || set.unitPlaceholder)),
    ]);

    const csvData = `data:text/csv;charset=utf-8,${headerLine.join(SEP)}\n${rows.map(r => r.join(SEP)).join('\n')}`;
    const link = document.createElement('a');
    link.setAttribute('href', encodeURI(csvData));
    link.setAttribute('download', 'export.csv');
    document.body.appendChild(link);
    link.click();
  };

  const hasInlineData = label => {
    const values = [
      ...new Set(dataStructure.sets.map(set => Object.keys(set.inset?.[label]?.values || {})).flat()),
    ].sort();
    return values.length > 0;
  };

  const getColumns = () => {
    const firstColumn = (
      <Column
        field={`${dataStructure.sets.length}`}
        header="&nbsp;"
        key="_label"
        sortable
        filter
        filterMatchMode="contains"
        style={{ width: '250px' }}
        body={(rowData, column) => {
          const label = rowData[column.field];
          return (
            <div className="result-data-table-first-col">
              {hasInlineData(label) && (
                <Button className="p-button-info" icon="pi pi-search" onClick={() => navigateToChild(label)} />
              )}
              {label}
            </div>
          );
        }}
      />
    );

    const columns = dataStructure.sets.map((set, index) => {
      const headerLabel = set.label || set.placeholder || '';
      const unit = set.unit || set.unitPlaceholder;
      return (
        <Column
          field={`${index}`}
          header={headerLabel}
          key={headerLabel}
          style={{ width: '100px' }}
          body={rowData => unitFormatter(rowData[index], unit)}
          sortable
          className="montant"
        />
      );
    });
    return [firstColumn, ...columns];
  };

  const header = dataStructure.dataTitle && (
    <div className="data-table-top-header">
      <Button
        className="p-button-info"
        icon="pi pi-angle-double-up"
        onClick={navigateToParent}
        label={dataStructure.dataTitle}
      />
    </div>
  );

  const footer = (
    <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
      <Button
        type="button"
        icon="pi pi-download"
        className="p-button-secondary p-button-outlined"
        iconPos="left"
        label="Exporter tout"
        title="Détail des données agrégées"
        onClick={exportAllData}
        style={{ fontSize: '.8em' }}
      />
      <Button
        type="button"
        icon="pi pi-download"
        className="p-button-secondary p-button-outlined"
        iconPos="left"
        label="Exporter les données affichées"
        onClick={exportData}
        style={{ fontSize: '.8em' }}
      />
    </div>
  );

  return data.length > 0 ? (
    <DataTablePrime
      value={data}
      footer={footer}
      scrollable
      responsive
      resizableColumns
      reorderableColumns
      header={header}
    >
      {getColumns()}
    </DataTablePrime>
  ) : (
    <span>(Aucune donnée)</span>
  );
}
ResultDataTable.propTypes = {
  dataSets: PropTypes.arrayOf(PropTypes.shape({})),
  sorterSet: PropTypes.shape({}),
  dataTitle: PropTypes.string,
  aggregations: PropTypes.arrayOf(PropTypes.string),
};
