import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { DataSearch, MultiList } from '@appbaseio/reactivesearch';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';

import { Constants } from 'utils';

import './SearchAttributeList.scss';

const numberFormatter = new Intl.NumberFormat('fr-FR');

export default function SearchAttributeListNew({
  style,
  className,

  showSearch, // 'remote' / true
  showMissing,
  showLoadMore,

  accordionTab, // bool
  accordionExpanded, // bool

  sortBy = 'asc',
  size,
  selectAllLabel,

  transformData,
  onToggleAll,
  react = () => {},
  //
  field = '',
  value = [], // values of the list : ['Dépense', 'Recette']
  onChange = () => {},
}) {
  const canToggleAll = useMemo(() => !!onToggleAll, [onToggleAll]);
  const placeholder = useMemo(() => Constants.SEARCH_FIELDS_FULL[field]?.label, [field]);
  const dataField = useMemo(() => `${field}.keyword`, [field]);

  const reactComponents = useMemo(() => {
    const result = react([placeholder]);

    if (showSearch === 'remote') {
      result.and.push(`${placeholder} (recherche)`);
    }
    return result;
  }, [placeholder, react, showSearch]);

  const onListItemChange = newValue => {
    onChange(field, newValue);
  };

  const onListToggleAll = () => {
    onToggleAll(placeholder, field);
  };

  const renderRemoteSearch = () => (
    <DataSearch
      componentId={`${placeholder} (recherche)`}
      dataField={field}
      placeholder={placeholder}
      autosuggest={false}
      debounce={300}
      showFilter
      filterLabel={`${placeholder} contient `}
      URLParams={false}
    />
  );
  const renderToggleAll = () => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
    <div
      role="button"
      onClick={onListToggleAll}
      style={{ fontSize: '.8em', fontStyle: 'italic', cursor: 'pointer', margin: '5px 2px' }}
    >
      <Button
        type="button"
        icon="pi pi-check"
        className="p-button-secondary p-button-outlined"
        iconPos="left"
        title={value && value.length ? 'Tout décocher' : 'Tout cocher'}
        style={{ fontSize: '.8em', marginRight: '10px', width: '20px', height: '20px' }}
      />
      ({value && value.length ? 'Tout décocher' : 'Tout cocher'})
    </div>
  );

  const renderList = () => (
    <div style={style} className={`${className} my-multilist`}>
      {showSearch === 'remote' && renderRemoteSearch()}
      <MultiList
        componentId={placeholder}
        dataField={dataField}
        filterLabel={`${placeholder} `}
        placeholder={placeholder}
        value={value}
        onChange={onListItemChange}
        showSearch={(showSearch && showSearch !== 'remote') || false}
        showMissing={showMissing || false} // Paramètre par défaut 'false', permet de ne pas voir les N/A affichées. Si on souhaite les voir, il faut aller dans ReactiveSearch.js et changer le paramètre par défaut par 'true'
        sortBy={sortBy}
        loader="Chargement en cours..."
        showLoadMore={!!showLoadMore}
        loadMoreLabel="Afficher plus"
        URLParams={false}
        react={reactComponents}
        size={size || (showSearch === 'remote' ? 100 : 1000)} // Before = 20 : 100
        selectAllLabel={selectAllLabel}
        transformData={transformData}
        renderItem={(key, docCount) => (
          <span>
            <span>{key}</span>
            <span>{numberFormatter.format(docCount)}</span>
          </span>
        )}
      />
      {canToggleAll && renderToggleAll()}
    </div>
  );
  return accordionTab ? (
    <Accordion activeIndex={(accordionTab ? value.length > 0 || accordionExpanded : true) ? 0 : null}>
      <AccordionTab header={placeholder}>{renderList()}</AccordionTab>
    </Accordion>
  ) : (
    renderList()
  );
}

SearchAttributeListNew.propTypes = {
  // Options passed to the multilist
  style: PropTypes.shape({}),
  className: PropTypes.string,
  showSearch: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  showMissing: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  sortBy: PropTypes.string,
  size: PropTypes.number,
  selectAllLabel: PropTypes.string,
  react: PropTypes.func,
  //
  accordionTab: PropTypes.bool,
  accordionExpanded: PropTypes.bool,
  transformData: PropTypes.func,
  onToggleAll: PropTypes.func,
  //
  field: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};
