import React from 'react';
import * as PropTypes from 'prop-types';

import './SearchCriteriaGroup.scss';

export default function SearchCriteriaGroup({ title, children }) {
  return (
    <div className="search-criteria-group">
      <div className="group-title">{title}</div>
      <div className="group-content">{children}</div>
    </div>
  );
}

SearchCriteriaGroup.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
