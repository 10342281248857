import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { DataSearch, MultiList } from '@appbaseio/reactivesearch';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';

import './SearchAttributeList.scss';
import { Constants } from 'utils';

const numberFormatter = new Intl.NumberFormat('fr-FR');

let idx = 0;

/**
 * This component if for test purposes only.
 * Do not use in production
 */
export default function SearchAttributeListNew({
  style,
  className,

  showSearch, // 'remote' / true
  showMissing,
  showLoadMore,

  accordionTab, // bool
  accordionExpanded, // bool

  sortBy = 'asc',
  size,
  selectAllLabel,

  onToggleAll,
  // eslint-disable-next-line no-unused-vars
  react = () => ({ and: [] }),
  ignoreUpdateFields = [],
  //
  field = '',
  value = [], // values of the list : ['Dépense', 'Recette']
  onChange = () => {},
}) {
  // eslint-disable-next-line no-plusplus
  console.log('### RENDER SearchAttributeList2 ###', idx++);
  const canToggleAll = useMemo(() => !!onToggleAll, [onToggleAll]);
  const placeholder = useMemo(() => Constants.SEARCH_FIELDS_FULL[field]?.label, [field]);
  const dataField = useMemo(() => `${field}.keyword`, [field]);

  // const reactComponents = useMemo(() => {
  //   const result = react([placeholder]);
  //
  //   if (showSearch === 'remote') {
  //     result.and.push(`${placeholder} (recherche)`);
  //   }
  //   if (placeholder === 'Entité budgétaire') {
  //     // console.log('## reactComponents =>', placeholder, '=> result :', result);
  //   }
  //   return result;
  // }, [placeholder, react, showSearch]);

  const reactUpdateQuery = useMemo(() => {
    // ignoreUpdateFields

    // console.log('reactUpdateQuery, excludedFields for ', placeholder, ':', ignoreUpdateFields);
    const res = { and: Constants.SEARCH_FIELDS_LABELS.filter(i => ![...ignoreUpdateFields, placeholder].includes(i)) };

    if (showSearch === 'remote') {
      res.and.push(`${placeholder} (recherche)`);
    }

    // console.log('reactUpdateQuery => res :', res);
    return res;
  }, [ignoreUpdateFields, placeholder, showSearch]);

  const onListItemChange = newValue => {
    onChange(field, newValue);
  };

  const onQueryChange = (prev, next) => {
    console.log('onQueryChange, prev:', prev);
    console.log('onQueryChange, next:', next);
  };

  const onListToggleAll = () => {
    onToggleAll(placeholder, dataField);
  };

  const renderRemoteSearch = () => (
    <DataSearch
      componentId={`${placeholder} (recherche)`}
      dataField={field}
      placeholder={placeholder}
      autosuggest={false}
      debounce={300}
      showFilter
      filterLabel={`${placeholder} contient `}
      URLParams={false}
    />
  );
  const renderToggleAll = () => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
    <div
      role="button"
      onClick={onListToggleAll}
      style={{ fontSize: '.8em', fontStyle: 'italic', cursor: 'pointer', margin: '5px 2px' }}
    >
      <Button
        type="button"
        icon="pi pi-check"
        className="p-button-secondary p-button-outlined"
        iconPos="left"
        title={value && value.length ? 'Tout décocher' : 'Tout cocher'}
        style={{ fontSize: '.8em', marginRight: '10px', width: '20px', height: '20px' }}
      />
      ({value && value.length ? 'Tout décocher' : 'Tout cocher'})
    </div>
  );

  const renderList = () => (
    <div style={style} className={`${className} my-multilist`}>
      {showSearch === 'remote' && renderRemoteSearch()}
      <MultiList
        componentId={placeholder}
        dataField={dataField}
        filterLabel={`${placeholder} `}
        placeholder={placeholder}
        value={value}
        onChange={onListItemChange}
        showSearch={(showSearch && showSearch !== 'remote') || false}
        showMissing={showMissing || false} // Paramètre par défaut 'false', permet de ne pas voir les N/A affichées. Si on souhaite les voir, il faut aller dans ReactiveSearch.js et changer le paramètre par défaut par 'true'
        sortBy={sortBy}
        loader="Chargement en cours..."
        showLoadMore={!!showLoadMore}
        loadMoreLabel="Afficher plus"
        URLParams={false}
        react={reactUpdateQuery}
        onQueryChange={onQueryChange}
        // react={reactComponents}
        size={size || (showSearch === 'remote' ? 3 : 5)}
        // size={size || (showSearch === 'remote' ? 20 : 100)}
        selectAllLabel={selectAllLabel}
        renderItem={(key, docCount) => (
          <span>
            <span>{key}</span>
            <span>{numberFormatter.format(docCount)}</span>
          </span>
        )}
      />
      {canToggleAll && renderToggleAll()}
    </div>
  );
  return accordionTab ? (
    <Accordion activeIndex={(accordionTab ? value.length > 0 || accordionExpanded : true) ? 0 : null}>
      <AccordionTab header={placeholder}>{renderList()}</AccordionTab>
    </Accordion>
  ) : (
    renderList()
  );
}

SearchAttributeListNew.propTypes = {
  // Options passed to the multilist
  style: PropTypes.shape({}),
  className: PropTypes.string,
  showSearch: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  showMissing: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  sortBy: PropTypes.string,
  size: PropTypes.number,
  selectAllLabel: PropTypes.string,
  react: PropTypes.func,
  //
  accordionTab: PropTypes.bool,
  accordionExpanded: PropTypes.bool,
  onToggleAll: PropTypes.func,
  //
  field: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  ignoreUpdateFields: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};
