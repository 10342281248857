export const style = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
    marginLeft: '5px',
    position: 'relative',
  },

  highlighter: {
    overflow: 'hidden',
  },

  input: {
    margin: '0',
    // margin: '5px',
  },

  '&singleLine': {
    control: {
      display: 'inline-block',
      width: 500,
    },

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },

    input: {
      padding: 1,
      border: '2px inset',
    },
  },
  width: '100%',

  '&multiLine': {
    control: {
      //fontFamily: 'monospace',
      width: '100%',
      border: '1px solid silver',
    },

    highlighter: {
      padding: 9,
    },

    input: {
      padding: 9,
      minHeight: 0,
      outline: 0,
      border: 0,
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },

    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',

      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};
export const defaultMentionStyle = {
  backgroundColor: '#cee4e5',
};
export const exerciceMentionStyle = {
  backgroundColor: '#d1c4e9',
};
