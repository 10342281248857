import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import html2canvas from 'html2canvas';

import { Aggregator, DashboardResult, LoadDots, PageTitle } from 'components';
import { Constants, useDebouncedCallback, datasetHelper } from 'utils';
import { aggregationService, dashboardService } from 'services';
import { DatasetSelector, FilterBuilder, SorterBuilder } from './components';

import './ChartBuilder.scss';

const chartTypes = [
  { label: 'Histogrammes', value: 'bar' },
  { label: 'Courbes', value: 'line' },
  { label: 'Histo. empilés', value: 'stack' },
  { label: 'Secteurs', value: 'pie' },
  { label: 'Anneau', value: 'doughnut' },
  { label: 'Radar', value: 'radar' },
  { label: 'Aires polaires', value: 'polarArea' },
  { label: 'Mosaïque', value: 'mosaic' },
];

export default function ChartBuilder() {
  const { id: dashboardId } = useParams();
  const history = useHistory();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [dashboard, setDashboard] = useState({
    name: '',
    description: '',
    dataSets: dashboardId
      ? []
      : [{ color: datasetHelper.defaultColors[0], sets: [], formula: '', id: datasetHelper.getDatasetId() }],
    filterSet: { sets: [], formula: '', size: '10' },
    sorterSet: { sets: [], formula: '' },
    aggregs: [Constants.DEFAULT_AGGREGATION],
    chartType: 'bar',
    chartTitle: '',
    thumbnail: '',
  });

  useEffect(() => {
    if (dashboardId) {
      setLoading(true);
      dashboardService
        .getDashboardForEdit(dashboardId)
        .then(data => {
          if (data) {
            setDashboard(data);
          } else {
            toast.current.show({
              severity: 'error',
              sticky: false,
              summary: "Impossible d'afficher les données",
              detail: `Dashboard non trouvé.\n\n`,
            });
          }
          setLoading(false);
        })
        .catch(error => {
          console.log('Load dashboard error :', error);
          toast.current.show({
            severity: 'error',
            sticky: false,
            summary: "Impossible d'afficher les données",
            detail: `Une erreur est survenue, veuillez réessayer.\n\n${error && error.message}`,
          });
          setLoading(false);
        });
    }
  }, [dashboardId]);

  const getThumbnail = async () => {
    const screenshotTarget = document.getElementById('chart');
    if (screenshotTarget) {
      const canvas = await html2canvas(screenshotTarget);
      return canvas.toDataURL('image/png');
    }
    // Blank image 130px x 119px
    return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOYAAAB3CAQAAAC9WfTWAAAAuElEQVR42u3RAQ0AAAwCoNs/tO/hoAJpjxGRKROZyESmTGQiE5nIlIlMZCITmTKRiUxkykQmMpGJTJnIRCYykSkTmchEpkxkIhOZyJSJTGQiE5kykYlMZMpEJjKRiUyZyEQmMpEpE5nIRKZMmTKRiUxkykQmMpGJTJnIRCYykSkTmchEpkxkIhOZyJSJTGQiE5kykYlMZMpEJjKRiUyZyEQmMpEpE5nIRKZMZCITmciUiUxkIhOZsx5BNu2K9s344wAAAABJRU5ErkJggg==';
  };
  const saveDashboard = async () => {
    if (loading) {
      return;
    }
    try {
      setSaving(true);
      const dataToSave = { ...dashboard, thumbnail: await getThumbnail() };

      if (dashboardId) {
        await dashboardService.updateDashboard(dashboardId, dataToSave);
      } else {
        await dashboardService.createDashboard(dataToSave);
      }
      history.push('/');
    } catch (error) {
      console.log('error :', error);
      toast.current.show({
        severity: 'error',
        sticky: false,
        summary: 'Enregistrement des données impossible',
        detail: `Une erreur est survenue, veuillez réessayer.\n\n${error && error.message}`,
      });
      setSaving(false);
    }
  };

  const attemptSave = () => {
    if (currentTab !== 0) {
      // Hack : we need to activate chart tab for screenshot
      // And save after a delay (wait for chart animation)
      setCurrentTab(0);
      setSaving(true);
      setTimeout(() => {
        saveDashboard();
      }, 1000);
    } else {
      saveDashboard();
    }
  };

  const computeDashboardResult = async ({
    sets = dashboard.dataSets,
    filter = dashboard.filterSet,
    sorter = dashboard.sorterSet,
    aggregs = dashboard.aggregs,
  }) => {
    setLoading(true);
    try {
      // updatedSets is copy of dataSets + sorterSet with Calculated values, insets and unitPlaceholder in it
      const { dataSets, sorterSet } = await aggregationService.updateData(sets, filter, sorter, aggregs);
      setDashboard(previous => ({ ...previous, dataSets, sorterSet, filterSet: filter, aggregs }));
      setLoading(false);
    } catch (error) {
      console.error('computeDashboardResult error :', error);
      toast.current.show({
        severity: 'error',
        sticky: false,
        summary: 'Recalcul des données impossible',
        detail: `Une erreur est survenue, veuillez réessayer.\n\n${error && error.message}`,
      });
      setLoading(false);
    }
  };

  // Trigger computation if formula changes / number of sets changes
  // Otherwise, merge dataSets with all new stuff we receive (want to keep already calculated values and insets)
  const onDatasetsChange = useDebouncedCallback(sets => {
    const setFormulaHasChanged = sets.some(set => {
      const foundSet = dashboard.dataSets.find(s => s.id === set.id);
      // Return true if set has not been found or formula is different
      return foundSet ? foundSet.formula !== set.formula : true;
    });
    if (dashboard.dataSets.length !== sets.length || setFormulaHasChanged) {
      computeDashboardResult({ sets });
    } else {
      const mergedDatasets = sets.map(set => {
        const oldSet = dashboard.dataSets.find(s => s.id === set.id) || { values: {}, inset: {}, sets: [] };
        const mergedSubsets = set.sets.map(s => {
          const oldSubset = oldSet.sets.find(sub => sub.id === s.id) || { values: {}, inset: {} };
          return { ...oldSubset, ...s };
        });
        return { ...oldSet, ...set, sets: mergedSubsets };
      });
      setDashboard(previous => ({ ...previous, dataSets: mergedDatasets }));
    }
  }, 300);

  const onAggregatorChange = newValue => {
    computeDashboardResult({ aggregs: newValue });
  };

  const onFilterChange = newFilterSet => {
    computeDashboardResult({ filter: newFilterSet });
  };

  const onSorterChange = newSorterSet => {
    computeDashboardResult({ sorter: newSorterSet });
  };

  return (
    <section className="chart-builder">
      <Toast ref={toast} />
      <PageTitle title={`${dashboardId ? 'Éditer le dashboard' : 'Nouveau dashboard'}`} backLink="/">
        <div className="loader">
          {loading && (
            <>
              <span className="">Calcul en cours</span>
              <LoadDots />
            </>
          )}
        </div>
        <Button
          onClick={attemptSave}
          disabled={saving || loading || dashboard.dataSets.length === 0 || dashboard.name === ''}
          loading={saving}
          label={`${dashboardId ? 'Sauvegarder' : 'Créer'}`}
        />
      </PageTitle>

      <Card>
        <div className="chart-builder-form-input">
          <label className="input-label" htmlFor="cb-name">
            Nom :&nbsp;
          </label>
          <InputText
            id="cb-name"
            value={dashboard.name}
            onChange={event => {
              setDashboard({ ...dashboard, name: event.target.value });
            }}
            style={{ width: '500px' }}
          />
        </div>
        <div className="chart-builder-form-input">
          <label className="input-label" htmlFor="cb-description">
            Description :&nbsp;
          </label>
          <InputTextarea
            id="cb-description"
            value={dashboard.description}
            onChange={event => {
              setDashboard({ ...dashboard, description: event.target.value });
            }}
            style={{ width: '500px' }}
            rows={5}
          />
        </div>
        <div className="chart-builder-form-input">
          <label className="input-label" htmlFor="cb-description">
            Dashboard partagé :&nbsp;
          </label>
          <InputSwitch
            checked={dashboard.shared}
            onChange={() => setDashboard(prev => ({ ...prev, shared: !prev.shared }))}
          />
        </div>
      </Card>

      <Card subTitle="Sélectionner les données">
        <DatasetSelector
          value={dashboard.dataSets}
          chartType={dashboard.chartType}
          aggregs={dashboard.aggregs}
          onChange={onDatasetsChange}
          disabled={loading}
        />
      </Card>

      <div className="cards-row-50-50">
        <Card subTitle="Filtrer">
          <FilterBuilder
            set={dashboard.filterSet}
            aggregs={dashboard.aggregs}
            disabled={loading}
            onChange={onFilterChange}
          />
        </Card>
        <Card subTitle="Trier">
          <SorterBuilder
            set={dashboard.sorterSet}
            aggregs={dashboard.aggregs}
            disabled={loading}
            onChange={onSorterChange}
          />
        </Card>
      </div>

      <Card subTitle="Mettre en forme">
        <div className="chart-builder-form-input">
          <label className="input-label" htmlFor="cb-graph-title">
            Titre du graphique :&nbsp;
          </label>

          <InputText
            id="cb-graph-title"
            value={dashboard.chartTitle}
            onChange={event => {
              setDashboard({ ...dashboard, chartTitle: event.target.value });
            }}
            style={{ width: '500px' }}
          />
        </div>
        <div className="chart-builder-form-input">
          <label className="input-label" htmlFor="aggregator">
            Agréger par :&nbsp;
          </label>
          <Aggregator value={dashboard.aggregs} onChange={onAggregatorChange} disabled={loading} />
        </div>

        <div className="chart-builder-form-input">
          <label className="input-label" htmlFor="chart-type">
            Type de graphique :&nbsp;
          </label>
          <Dropdown
            inputId="chart-type"
            value={dashboard.chartType}
            options={chartTypes}
            onChange={e => setDashboard(prev => ({ ...prev, chartType: e.value || 'bar' }))}
            placeholder="Histogramme"
          />
        </div>
      </Card>

      <article className="chart-builder-article">
        <h3>Résultat graphique</h3>
        <div>
          <DashboardResult dashboard={dashboard} currentTab={currentTab} onTabChange={setCurrentTab} />
        </div>
      </article>
    </section>
  );
}
