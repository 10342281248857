import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { locale } from 'primereact/api';

import { ErrorBoundary, ProtectedRoute, LayoutWithSidebar, Navbar } from 'components';
import {
  About,
  ChartBuilder,
  SavedSearches,
  Test,
  Login,
  UserProfile,
  NotFound,
  DashboardTable,
  Dashboard,
} from 'pages';
import ChartBuilderOld from 'components/Legacy/chartBuilder';
import { userService } from 'services';

// import './theme.css'; // No longer use
import 'primereact/resources/themes/nova/theme.css';
// import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/style/main.scss';
import './App.scss';

if (process.env.NODE_ENV === 'production') {
  window.onbeforeunload = function confirmExit() {
    return '';
  };
}

locale('fr');

export default function App() {
  const { isAuthenticated, user } = useAuth0();

  if (isAuthenticated) {
    userService.setUser(user);
  }

  const renderRoutableContent = () => (
    <div className="routable-content">
      <Switch>
        <Route path="/login" component={Login} />
        <ProtectedRoute path="/cb" exact component={ChartBuilderOld} />
        <ProtectedRoute path="/formules" component={SavedSearches} />
        <ProtectedRoute path="/a-propos" component={About} />
        <ProtectedRoute path="/test" component={Test} />
        <ProtectedRoute path="/profil" component={UserProfile} />
        <ProtectedRoute path="/builder/:id" component={ChartBuilder} />
        <ProtectedRoute path="/builder" component={ChartBuilder} />
        <ProtectedRoute path="/dash" exact component={DashboardTable} />
        <ProtectedRoute path="/" exact component={Dashboard} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );

  return (
    <ErrorBoundary>
      <Router basename={process.env.PUBLIC_URL}>
        <LayoutWithSidebar>
          {isAuthenticated ? (
            <div className="viewport-with-navbar">
              <div className="navbar-container">
                <Navbar />
              </div>
              {renderRoutableContent()}
            </div>
          ) : (
            renderRoutableContent()
          )}
        </LayoutWithSidebar>
      </Router>
    </ErrorBoundary>
  );
}
