import moneyFormatter from './moneyFormatter';

function round(n, precision) {
  const prec = Math.pow(10, precision);
  return Math.round(n * prec) / prec;
}

export default (number, unit, fractions, shorten) => {
  fractions = unit === 'hab.' ? 0 : fractions;

  if (!number) {
    return '';
  }

  fractions = typeof fractions === 'undefined' ? 2 : fractions;

  if (shorten) {
    const abbrevs = [' ', 'M', 'Md'];

    let base = Math.floor(Math.log(Math.abs(number)) / Math.log(1000));
    let suffix = abbrevs[Math.min(2, base - 1)];
    base = abbrevs.indexOf(suffix) + 1;
    suffix = (suffix || '').trim();

    if (suffix) {
      number = round(number / Math.pow(1000, base), 2);
      unit = `${suffix} ${unit || ''}`.replace(/ €/, '€').trim();
    }
  }

  if (unit === '€') {
    return moneyFormatter(number, fractions, shorten);
  }

  return `${Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: fractions,
  }).format(number)} ${unit || ''}`.trim();
};
