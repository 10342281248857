import React from 'react';
import * as PropTypes from 'prop-types';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Constants, unitFormatter } from 'utils';
import { LoadDots } from '../../../../..';

import './AggregatedData.scss';

const getDefaultQuery = aggregation => ({
  aggs: {
    AGG: {
      terms: {
        field: `${aggregation}.keyword`,
        missing: 'N/A',
        order: { montant: 'desc' },
      },
      aggs: {
        montant: {
          sum: {
            field: 'montant',
          },
        },
        unit: {
          terms: {
            field: 'unit.keyword',
            missing: '',
          },
        },
      },
    },
  },
});

export default function AggregatedData({ aggregation = Constants.DEFAULT_AGGREGATION }) {
  const getValuesFromAggregations = aggregations =>
    aggregations?.AGG
      ? aggregations.AGG.buckets.map(item => ({
          key: item.key,
          value: item?.montant?.value,
          units: item.unit.buckets.map(i => i.key),
        }))
      : [];

  const onValuesChange = (values, units) => {
    const dataValues = values.reduce((acc, { key, value }) => {
      acc[key] = value;
      return acc;
    }, {});
    const dataUnitPlaceholder = units.length === 1 ? units[0] : null;

    // TODO Maybe send these values to parent ?
    console.log('dataValues :', dataValues);
    console.log('dataUnitPlaceholder :', dataUnitPlaceholder);
  };

  return (
    <ReactiveComponent
      componentId="summary"
      react={{ and: Constants.SEARCH_FIELDS_LABELS }}
      defaultQuery={() => getDefaultQuery(aggregation)}
      onData={({ aggregations }) => {
        const values = getValuesFromAggregations(aggregations);
        const units = [...new Set(values.map(v => v.units).flat())];
        onValuesChange(values, units);
      }}
    >
      {({ aggregations, loading, error }) => {
        if (loading) {
          return (
            <div className="data-loading">
              <div>Chargement</div> <LoadDots />
            </div>
          );
        }
        if (error) {
          return <div>Erreur... {JSON.stringify(error)}</div>;
        }

        const values = getValuesFromAggregations(aggregations);

        const footer = aggregations?.AGG?.sum_other_doc_count ? '... (données tronquées) ...' : null;

        return (
          <div>
            <DataTable value={values} responsive footer={footer}>
              <Column field="key" style={{ overflow: 'visible' }} header="" />
              <Column
                field="value"
                header="Somme"
                body={(rowData, column) =>
                  unitFormatter(rowData[column.field], rowData.units.length === 1 ? rowData.units[0] : '')
                }
                className="montant"
              />
            </DataTable>
          </div>
        );
      }}
    </ReactiveComponent>
  );
}

AggregatedData.propTypes = {
  aggregation: PropTypes.string,
};
