import { AppNamespace } from 'config/elastic';
import { Constants } from 'utils';

class UserService {
  user;

  setUser(userData) {
    if (userData) {
      this.user = {
        roles: userData[`${AppNamespace}/roles`],
        appMetadata: userData[`${AppNamespace}/appMetadata`],
        userMetadata: userData[`${AppNamespace}/userMetadata`],
        nickname: userData.nickname,
        name: userData.name,
        avatar: userData.picture,
        updatedAt: userData.updated_at,
        email: userData.email,
        emailVerified: userData.email_verified,
        // user.sub is "auth0|1234567". Remove "auth0|" and keep the id
        // Otherwise, elasticsearch will search userId = "auth0" OR "1234567"
        id: userData.sub.replace('auth0', '').replace('|', ''),
      };
    } else {
      console.debug('No user yet');
    }
  }

  getUser() {
    return this.user;
  }

  isAdmin() {
    return this.user.roles.indexOf(Constants.ROLES.ADMIN) > -1;
  }
}

export default new UserService();
