import React from 'react';
import * as PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';

import './PageTitle.scss';
import { LoadDots } from '../index';

export default function PageTitle({ title, loading, backLink, children }) {
  const history = useHistory();

  const navigate = () => {
    history.push(backLink);
  };

  return (
    <div className="page-header">
      <div className="page-title">
        {backLink && (
          <Button
            className="p-button-rounded p-button-secondary p-button-outlined p-button-text backlink-btn"
            tooltip="Retour"
            icon="pi pi-chevron-left"
            onClick={navigate}
          />
        )}
        <h2>{title}</h2>
      </div>
      <div className="page-actions">
        {loading && <LoadDots />}
        {children}
      </div>
    </div>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  backLink: PropTypes.string,
  children: PropTypes.node,
};
