import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { ColorPicker } from 'primereact/colorpicker';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';

import { SearchModalLegacy } from '..';

class FormulaBuilder extends Component {
  constructor(props) {
    super(props);
    this.input = null;
    const d = props.set;
    this.state = {
      color: d.color,
      chartType: d.chartType,
      label: d.label,
      unit: d.unit,
    };
  }

  onSetUpdate = set => {
    Object.assign(set, this.state);
    this.props.onChange(set);
  };

  render = () => {
    const d = this.props.set;
    const chartType = this.props.chartType;

    const hasColor = ['stack', 'bar', 'line', 'radar'].includes(chartType);
    const hasCurve = ['bar', 'stack'].includes(chartType);

    const toolbarContents = (
      <>
        <div className="p-inputgroup" style={{ display: 'inline-flex', maxWidth: '100%' }}>
          {hasColor && (
            <ColorPicker
              value={('' + (d.color || '')).replace('#', '')}
              onChange={e => {
                this.setState({ color: '#' + e.value }, () => this.onSetUpdate(d));
              }}
            />
          )}
          {hasCurve && (
            <Button
              label=""
              className={d.chartType !== 'line' ? 'p-button-secondary p-button-outlined' : 'p-button-info'}
              icon="pi pi-chart-bar"
              style={{ borderColor: '#a6a6a6', borderLeft: 'none', borderRight: 'none' }}
              title="Basculer courbe / histogrammes"
              onClick={() => {
                this.setState({ chartType: d.chartType === 'line' ? 'bar' : 'line' }, () => this.onSetUpdate(d));
              }}
            />
          )}
          <InputText
            value={d.label || ''}
            onChange={e => {
              this.setState({ label: e.target.value }, () => this.onSetUpdate(d));
            }}
            onFocus={() => !d.formula && this.popup.show()}
            onDoubleClick={() => this.popup.show()}
            placeholder={d.placeholder || 'Nom du jeu de données'}
            style={{ width: '30em', margin: '0 0 0 0' }}
          />

          <Button
            label=""
            icon="pi pi-folder-open"
            style={{ marginRight: '5px', borderRadius: '0 5px 5px 0' }}
            className="p-button-warning"
            onClick={() => this.popup.show()}
          />
        </div>
        <InputText
          value={d.unit || ''}
          onChange={e => {
            this.setState({ unit: e.target.value }, () => this.onSetUpdate(d));
          }}
          placeholder={d.unitPlaceholder || 'unité'}
          style={{ width: '5em', margin: '0 0 0 0' }}
        />
        {d.defaultError && (
          <Message
            severity="error"
            text={d.defaultError}
            style={{ float: 'right', marginTop: '-1em', fontSize: '.9em' }}
          />
        )}
        {d.label && (
          <div
            style={{
              padding: '5px 5px 5px ' + ((hasColor ? 33 : 0) + (hasCurve ? 31 : 0) + 5) + 'px',
              fontSize: '.8em',
            }}
          >
            {d.placeholder}
          </div>
        )}
        <SearchModalLegacy
          set={d}
          onSave={set => this.onSetUpdate(set)}
          ref={p => (this.popup = p)}
          aggreg={this.props.aggregs[0]}
        />
      </>
    );

    const toolbarCloseBtn = (
      <Button
        label=""
        className="p-button-secondary p-button-rounded p-button-text p-button-icon-only"
        icon="pi pi-times"
        style={{ fontSize: '.9em', marginTop: '-2em', marginRight: '-1em' }}
        onClick={this.props.onDelete}
      />
    );

    return <Toolbar style={{ marginBottom: '10px', padding: '15px' }} left={toolbarContents} right={toolbarCloseBtn} />;
  };
}

export default FormulaBuilder;
