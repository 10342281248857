import React, { useState, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext';

import { FiltersetType } from 'propTypes';
import { SearchModal } from 'components';

import './FilterBuilder.scss';

/**
 * Displays a widget allowing to filter data with criteria and results size
 * @param set the filterSet to use
 * @param aggregs aggregations, forwarded to ReactiveSearch
 * @param disabled
 * @param onChange triggered when size or dataset changes
 */
export default function FilterBuilder({ set, aggregs = [], disabled = false, onChange = () => {} }) {
  const [showSearch, setShowSearch] = useState(false);

  // TODO Why only first aggregation ?
  const firstAggregation = useMemo(() => (aggregs.length > 0 ? aggregs[0] : null), [aggregs]);

  const onSave = savedSet => {
    const updatedSet = { ...savedSet };
    onChange(updatedSet);
  };

  const onSizeChange = newSize => {
    const updatedSet = { ...set, size: `${newSize}` };
    onChange(updatedSet);
  };

  return (
    <div className="filter-builder-container">
      <div className="filter-builder-inputs">
        <div className="p-inputgroup">
          <Button
            label=""
            icon="pi pi-filter"
            style={{ borderRadius: '5px 0 0 5px' }}
            className="p-button-default"
            onClick={() => setShowSearch(true)}
            disabled={disabled}
          />
          <div className={`filter-builder-clickable-text ${disabled ? 'disabled' : ''}`}>Afficher</div>
          <InputText
            value={set.size || ''}
            keyfilter="pint"
            placeholder="toutes les"
            onChange={e => onSizeChange(e.target.value)}
            style={{ width: '10ex', textAlign: 'center', margin: '0 0 0 0' }}
            disabled={disabled}
          />
          <div className={`filter-builder-clickable-text ${disabled ? 'disabled' : ''}`}>lignes parmi</div>
          <InputText
            value={set.placeholder || 'toutes les données'}
            readOnly
            onClick={() => setShowSearch(true)}
            style={{ width: '15em', margin: '0 0 0 0' }}
            disabled={disabled}
          />
        </div>
      </div>
      {set.defaultError && (
        <Message
          severity="error"
          text={set.defaultError}
          style={{ float: 'right', marginTop: '-1em', fontSize: '.9em' }}
        />
      )}

      {showSearch && (
        <SearchModal set={set} onSave={onSave} aggreg={firstAggregation} onClose={() => setShowSearch(false)} />
      )}
    </div>
  );
}

FilterBuilder.propTypes = {
  set: FiltersetType,
  aggregs: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
