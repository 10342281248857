import React from 'react';

import './Login.scss';
import { LoginButton } from 'components';

export default function Login() {
  return (
    <div className="login-page">
      <LoginButton />
    </div>
  );
}

Login.propTypes = {};
