import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'primereact/button';

import { DatasetType } from 'propTypes';
import { datasetHelper } from 'utils';
import { FormulaBuilder } from './components';

import './DatasetSelector.scss';

/**
 * Displays and manages datasets as widgets. All non-empty datasets are synced with the parent page
 * @param value the list of datasets
 * @param chartType the type of chart to display
 * @param aggregs array of aggregations
 * @param disabled
 * @param onChange an updated list of datasets when changes occur
 */
export default function DatasetSelector({
  value = [],
  chartType = 'bar',
  aggregs = [],
  disabled = false,
  onChange = () => {},
}) {
  const [colorCount, setColorCount] = useState(1);
  // datasets to display. Internal model
  const [datasets, setDatasets] = useState([]);

  const getNextColor = () => {
    setColorCount(prev => prev + 1);
    return datasetHelper.defaultColors[colorCount % datasetHelper.defaultColors.length];
  };

  // Update internal model as value changes
  useEffect(() => {
    // We want to have all datasets of Value + empty elements of datasets,
    // while keeping the order of the elements of datasets
    setDatasets(previousDatasets => {
      const newValue = [...previousDatasets];
      value.forEach(val => {
        const idx = previousDatasets.findIndex(tb => tb.id === val.id);
        if (idx < 0) {
          newValue.push(val);
        } else {
          newValue[idx] = val;
        }
      });

      // Keep elements existing in Value and empty elements
      return newValue.filter(val => value.find(v => v.id === val.id) || val.sets.length === 0);
    });
  }, [value]);

  const notifyChanges = sets => {
    onChange(sets.filter(ds => ds.sets.length > 0));
  };

  const addDataset = () => {
    setDatasets([...datasets, { color: getNextColor(), sets: [], formula: '', id: datasetHelper.getDatasetId() }]);
  };

  const updateSet = (set, index) => {
    // A set has changed : update the list, filter and trigger onChange
    const updatedDatasets = [...datasets];
    updatedDatasets[index] = set;
    setDatasets(updatedDatasets);
    // Send only datasets having a dataset
    notifyChanges(updatedDatasets);
  };

  const deleteDataset = index => {
    const updatedDatasets = [...datasets];
    updatedDatasets.splice(index, 1);
    setDatasets(updatedDatasets);
    notifyChanges(updatedDatasets);
  };

  const duplicateDataset = idx => {
    const datasetCopy = JSON.parse(JSON.stringify(datasets[idx]));
    const updatedDatasets = [...datasets, { ...datasetCopy, color: getNextColor(), id: datasetHelper.getDatasetId() }];
    setDatasets(updatedDatasets);
    if (datasetCopy.sets.length > 0) {
      notifyChanges(updatedDatasets);
    }
  };

  return (
    <div className="dataset-selector-container">
      {datasets.map((dataset, index) => (
        <FormulaBuilder
          key={dataset.id}
          set={dataset}
          chartType={chartType}
          aggregs={aggregs}
          onChange={set => updateSet(set, index)}
          onDelete={() => deleteDataset(index)}
          onDuplicate={() => duplicateDataset(index)}
          disabled={disabled}
        />
      ))}

      <div className="button-zone">
        <Button
          label="Ajouter un jeu de données"
          icon="pi pi-plus-circle"
          onClick={addDataset}
          disabled={disabled || datasets.filter(tb => tb.sets.length === 0).length > 0}
        />
      </div>
    </div>
  );
}

DatasetSelector.propTypes = {
  value: PropTypes.arrayOf(DatasetType),
  chartType: PropTypes.string,
  aggregs: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
