import { MapControl, withLeaflet } from 'react-leaflet';
import L from 'leaflet';

import './Legend.scss';

class Legend extends MapControl {
  createLeafletElement(props) {
    this.state = { dataSets: this.props.dataSets };
  }

  componentDidMount() {
    function getColor(d) {
      if (d > lim7) {
        return '#800026';
      }
      if (d > lim6) {
        return '#BD0026';
      }
      if (d > lim5) {
        return '#E31A1C';
      }
      if (d > lim4) {
        return '#FC4E2A';
      }
      if (d > lim3) {
        return '#FD8D3C';
      }
      if (d > lim2) {
        return '#FEB24C';
      }
      if (d > lim1) {
        return '#FED976';
      }
      if (d > maxMin[1]) {
        return '#FFEDA0';
      }
    }

    const legend = L.control({ position: 'topright' });
    const jsonDatasets = this.state.dataSets[0].sets[0].values;
    //console.log(jsonDatasets);

    function RecupTabMontants() {
      let tabMontants = [];
      for (var key in jsonDatasets) {
        tabMontants.push(jsonDatasets[key]);
      }
      return tabMontants;
    }
    const montants = RecupTabMontants();

    function minMaxMontants(montants) {
      let maxValues = montants[0];
      let minValues = montants[0];
      let maxMin = [];
      for (let i = 1; i < montants.length; i++) {
        if (maxValues <= montants[i + 1]) {
          maxValues = montants[i + 1];
        }
        if (minValues >= montants[i + 1]) {
          minValues = montants[i + 1];
        }
      }
      maxMin.push(maxValues);
      maxMin.push(minValues);
      return maxMin;
    }

    const maxMin = minMaxMontants(montants);

    function limIntervals(maxMin) {
      let tabLIm = [];
      var largeurInterval = (maxMin[0] - maxMin[1]) / 8;
      var lim1 = maxMin[1] + largeurInterval;
      tabLIm.push(lim1);
      var lim2 = maxMin[1] + 2 * largeurInterval;
      tabLIm.push(lim2);
      var lim3 = maxMin[1] + 3 * largeurInterval;
      tabLIm.push(lim3);
      var lim4 = maxMin[1] + 4 * largeurInterval;
      tabLIm.push(lim4);
      var lim5 = maxMin[1] + 5 * largeurInterval;
      tabLIm.push(lim5);

      var lim6 = maxMin[1] + 6 * largeurInterval;
      tabLIm.push(lim6);
      var lim7 = maxMin[1] + 7 * largeurInterval;
      tabLIm.push(lim7);
      var lim8 = maxMin[1] + 8 * largeurInterval;
      tabLIm.push(lim8);
      return tabLIm;
    }

    const tablimIntervals = limIntervals(maxMin);
    let lim1 = tablimIntervals[0];
    let lim2 = tablimIntervals[1];
    let lim3 = tablimIntervals[2];
    let lim4 = tablimIntervals[3];
    let lim5 = tablimIntervals[4];
    let lim6 = tablimIntervals[5];
    let lim7 = tablimIntervals[6];

    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'info legend');
      const grades = [maxMin[1], lim1, lim2, lim3, lim4, lim5, lim6, lim7]; ///// [min,lim1,lim2,lim3,....]
      let labels = [];
      let from;
      let to;

      const numberFormatter = new Intl.NumberFormat('fr-FR');

      for (let i = 0; i < grades.length; i++) {
        from = grades[i];
        to = grades[i + 1];

        labels.push(
          '<i style="background:' +
            getColor(from + 1) +
            '"></i> ' +
            numberFormatter.format(from.toFixed()) +
            (to ? ' &ndash; ' + numberFormatter.format(to.toFixed()) : ' +')
        );
      }

      div.innerHTML = labels.join('<br>');
      return div;
    };

    const { map } = this.props.leaflet;
    legend.addTo(map);
  }
  componentWillUnmount() {
    this.setState();
  }
}

export default withLeaflet(Legend);
