import React from 'react';
import * as PropTypes from 'prop-types';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import './DashboardSorter.scss';

const sortOptions = [
  { label: 'Nom', value: 'name' },
  { label: 'Description', value: 'description' },
  { label: 'Date de création', value: 'createDate' },
  { label: 'Date de modification', value: 'updateDate' },
  { label: 'Propriétaire', value: 'owner' },
];

export default function DashboardSorter({ value = '', onChange = () => {} }) {
  const changeDirection = () => {
    onChange({ sortBy: value.sortBy, direction: value.direction * -1 });
  };

  const onSortChange = newValue => {
    onChange({ sortBy: newValue, direction: value.direction });
  };

  return (
    <div className="dashboard-sorter">
      <div className="">Trier par : </div>
      <Dropdown
        inputId="chart_type"
        value={value.sortBy}
        options={sortOptions}
        onChange={e => onSortChange(e.value)}
        placeholder="Histogramme"
        style={{ width: '220px' }}
      />
      <Button
        className="p-button-rounded p-button-outlined"
        icon={`pi pi-sort-${value.direction === 1 ? 'up' : 'down'}`}
        tooltip={value.direction === 1 ? 'Tri ascendant' : 'Tri descendant'}
        tooltipOptions={{ position: 'left' }}
        onClick={changeDirection}
      />
    </div>
  );
}

DashboardSorter.propTypes = {
  value: PropTypes.shape({
    sortBy: PropTypes.string,
    direction: PropTypes.number,
  }),
  onChange: PropTypes.func,
};
