import React, { Component } from 'react';
import { GeoJSON, Map, TileLayer } from 'react-leaflet'; // map
// import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet'; // map
import { Legend } from '..';

import departementsData from './DepartementsFrance.json';

let affect = [];
function getAffect(aff) {
  affect = aff;
}
export class BaseMapDepartements extends Component {
  constructor(props) {
    super(props);
    this.State = { dataSets: this.props.dataSets };
  }

  state = {
    lat: 46.37,
    lng: 2.5,
    zoom: 6,
  };

  styleonEachFeature(feature) {
    let affectc = affect;
    let coul = '';
    for (let i = 0; i < 104; i++) {
      if (feature.properties.NOM_DEPT === affectc[0][i]) {
        coul = affectc[1][i];
        return {
          fillColor: coul,
          weight: 1,
          opacity: 1,
          color: '#1f2021',
          fillOpacity: 0.5,
        };
      }
    }
  }

  style() {
    return {
      // the fillColor is adapted from a property which can be changed by the user (segment)
      fillColor: '#BD0026',
      weight: 1,
      // stroke-width: to have a constant width on the screen need to adapt with scale
      opacity: 1,
      color: '#1f2021',
      fillOpacity: 0.5,
    };
  }

  onEachFeature(feature, layer) {
    const popupContent = feature.properties.NOM_DEPT;
    layer.bindPopup(popupContent);
  }

  getAffectCouleur() {
    const jsonDatasets = this.State.dataSets[0].sets[0].values;
    const stringDatasets = JSON.stringify(jsonDatasets);
    const COLORS = ['#800026', '#BD0026', '#E31A1C', '#FC4E2A', '#FD8D3C'];
    const tabItem = [];
    const tabVal = [];
    function RecupTabMontants() {
      const tabMontants = [];
      for (const key in jsonDatasets) {
        tabMontants.push(jsonDatasets[key]);
      }
      return tabMontants;
    }
    const montants = RecupTabMontants();
    function minMaxMontants(allMontants) {
      let maxValues = allMontants[0];
      let minValues = allMontants[0];
      const maxMin = [];
      for (let i = 1; i < allMontants.length; i++) {
        if (maxValues <= allMontants[i + 1]) {
          maxValues = allMontants[i + 1];
        }
        if (minValues >= allMontants[i + 1]) {
          minValues = allMontants[i + 1];
        }
      }
      maxMin.push(maxValues);
      maxMin.push(minValues);
      return maxMin;
    }
    const maxMin = minMaxMontants(montants);

    function limIntervals(limits) {
      const tabLIm = [];
      const largeurInterval = (limits[0] - limits[1]) / 5;
      const lim1 = limits[1] + largeurInterval;
      tabLIm.push(lim1);
      const lim2 = limits[1] + 2 * largeurInterval;
      tabLIm.push(lim2);
      const lim3 = limits[1] + 3 * largeurInterval;
      tabLIm.push(lim3);
      const lim4 = limits[1] + 4 * largeurInterval;
      tabLIm.push(lim4);
      const lim5 = limits[1] + 5 * largeurInterval;
      tabLIm.push(lim5);
      return tabLIm;
    }

    const tablimIntervals = limIntervals(maxMin);
    const lim1 = tablimIntervals[0];
    const lim2 = tablimIntervals[1];
    const lim3 = tablimIntervals[2];
    const lim4 = tablimIntervals[3];
    const lim5 = tablimIntervals[4];

    function sansAccent(chaine) {
      const accent = [
        /[\300-\306]/g,
        /[\340-\346]/g, // A, a
        /[\310-\313]/g,
        /[\350-\353]/g, // E, e
        /[\314-\317]/g,
        /[\354-\357]/g, // I, i
        /[\322-\330]/g,
        /[\362-\370]/g, // O, o
        /[\331-\334]/g,
        /[\371-\374]/g, // U, u
        /[\321]/g,
        /[\361]/g, // N, n
        /[\307]/g,
        /[\347]/g, // C, c
      ];
      const noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

      let str = chaine;
      for (let i = 0; i < accent.length; i++) {
        str = str.replace(accent[i], noaccent[i]);
      }

      return str;
    }

    return JSON.parse(stringDatasets, (key, value) => {
      if (value <= lim1) {
        tabVal.push(COLORS[0]); // verifier que COLORS NON {COLORS}
        tabItem.push(sansAccent(key.toUpperCase()));
      } else if (value > lim1 && value <= lim2) {
        tabVal.push(COLORS[1]); // verifier que COLORS NON {COLORS}
        tabItem.push(sansAccent(key.toUpperCase()));
      } else if (value > lim2 && value <= lim3) {
        tabVal.push(COLORS[2]); // verifier que COLORS NON {COLORS}
        tabItem.push(sansAccent(key.toUpperCase()));
      } else if (value > lim3 && value <= lim4) {
        tabVal.push(COLORS[3]); // verifier que COLORS NON {COLORS}
        tabItem.push(sansAccent(key.toUpperCase()));
      } else if (value > lim4 && value <= lim5) {
        tabVal.push(COLORS[4]); // verifier que COLORS NON {COLORS}
        tabItem.push(sansAccent(key.toUpperCase()));
      }
      const tabItemsColors = [];
      tabItemsColors.push(tabItem);
      tabItemsColors.push(tabVal);
      return tabItemsColors;
    });
  }

  geoJSONStyle() {
    return {
      color: '#1f2021',
      weight: 1,
      fillOpacity: 0.5,
      fillColor: '#fff2af',
    };
  }

  render() {
    const position = [this.state.lat, this.state.lng];
    const COLORS = ['#FFEDA0', '#FED976', '#FEB24C', '#FD8D3C', '#FC4E2A', '#E31A1C', '#BD0026', '#800026'];
    const tabItem = [];
    const tabVal = [];
    const jsonDatasets = this.State.dataSets[0].sets[0].values;
    const stringDatasets = JSON.stringify(jsonDatasets);

    function RecupTabMontants() {
      const tabMontants = [];
      for (const key in jsonDatasets) {
        tabMontants.push(jsonDatasets[key]);
      }
      return tabMontants;
    }
    const montants = RecupTabMontants();

    function minMaxMontants(allMontants) {
      let maxValues = allMontants[0];
      let minValues = allMontants[0];
      const maxMin = [];
      for (let i = 1; i < allMontants.length; i++) {
        if (maxValues <= allMontants[i + 1]) {
          maxValues = allMontants[i + 1];
        }
        if (minValues >= allMontants[i + 1]) {
          minValues = allMontants[i + 1];
        }
      }
      maxMin.push(maxValues);
      maxMin.push(minValues);
      return maxMin;
    }

    const maxMin = minMaxMontants(montants);

    function limIntervals(limits) {
      const tabLIm = [];
      const largeurInterval = (limits[0] - limits[1]) / 8;
      const lim1 = limits[1] + largeurInterval;
      tabLIm.push(lim1);
      const lim2 = limits[1] + 2 * largeurInterval;
      tabLIm.push(lim2);
      const lim3 = limits[1] + 3 * largeurInterval;
      tabLIm.push(lim3);
      const lim4 = limits[1] + 4 * largeurInterval;
      tabLIm.push(lim4);
      const lim5 = limits[1] + 5 * largeurInterval;
      tabLIm.push(lim5);

      const lim6 = limits[1] + 6 * largeurInterval;
      tabLIm.push(lim6);
      const lim7 = limits[1] + 7 * largeurInterval;
      tabLIm.push(lim7);
      const lim8 = limits[1] + 8 * largeurInterval;
      tabLIm.push(lim8);
      return tabLIm;
    }

    const tablimIntervals = limIntervals(maxMin);
    const lim1 = tablimIntervals[0];
    const lim2 = tablimIntervals[1];
    const lim3 = tablimIntervals[2];
    const lim4 = tablimIntervals[3];
    const lim5 = tablimIntervals[4];
    const lim6 = tablimIntervals[5];
    const lim7 = tablimIntervals[6];
    const lim8 = tablimIntervals[7];

    function sansAccent(chaine) {
      const accent = [
        /[\300-\306]/g,
        /[\340-\346]/g, // A, a
        /[\310-\313]/g,
        /[\350-\353]/g, // E, e
        /[\314-\317]/g,
        /[\354-\357]/g, // I, i
        /[\322-\330]/g,
        /[\362-\370]/g, // O, o
        /[\331-\334]/g,
        /[\371-\374]/g, // U, u
        /[\321]/g,
        /[\361]/g, // N, n
        /[\307]/g,
        /[\347]/g, // C, c
      ];
      const noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

      let str = chaine;
      for (let i = 0; i < accent.length; i++) {
        str = str.replace(accent[i], noaccent[i]);
      }

      return str;
    }

    const affectationCouleurs = JSON.parse(stringDatasets, (key, value) => {
      if (value <= lim1) {
        tabVal.push(COLORS[0]); // verifier que COLORS NON {COLORS}
        tabItem.push(sansAccent(key.toUpperCase()));
      } else if (value > lim1 && value <= lim2) {
        tabVal.push(COLORS[1]); // verifier que COLORS NON {COLORS}
        tabItem.push(sansAccent(key.toUpperCase()));
      } else if (value > lim2 && value <= lim3) {
        tabVal.push(COLORS[2]); // verifier que COLORS NON {COLORS}
        tabItem.push(sansAccent(key.toUpperCase()));
      } else if (value > lim3 && value <= lim4) {
        tabVal.push(COLORS[3]); // verifier que COLORS NON {COLORS}
        tabItem.push(sansAccent(key.toUpperCase()));
      } else if (value > lim4 && value <= lim5) {
        tabVal.push(COLORS[4]); // verifier que COLORS NON {COLORS}
        tabItem.push(sansAccent(key.toUpperCase()));
      } else if (value > lim5 && value <= lim6) {
        tabVal.push(COLORS[5]); // verifier que COLORS NON {COLORS}
        tabItem.push(sansAccent(key.toUpperCase()));
      } else if (value > lim6 && value <= lim7) {
        tabVal.push(COLORS[6]); // verifier que COLORS NON {COLORS}
        tabItem.push(sansAccent(key.toUpperCase()));
      } else if (value > lim7 && value <= lim8) {
        tabVal.push(COLORS[7]); // verifier que COLORS NON {COLORS}
        tabItem.push(sansAccent(key.toUpperCase()));
      }
      const tabItemsColors = [];
      tabItemsColors.push(tabItem);
      tabItemsColors.push(tabVal);
      return tabItemsColors;
    });
    getAffect(affectationCouleurs);
    const affectationCouleursJson = {};

    for (var i = 0; i < affectationCouleurs[0].length; i++) {
      affectationCouleursJson[affectationCouleurs[0][i]] = affectationCouleurs[1][i];
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////

    return (
      <div>
        <Map style={{ height: '700px' }} center={position} zoom={this.state.zoom}>
          <TileLayer
            attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>"
            url="https://api.mapbox.com/styles/v1/tariq-laafar/ckcny5mr61ylp1ipf3x3k3ns0/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidGFyaXEtbGFhZmFyIiwiYSI6ImNrY254ZXo1bTBmanYycHJxZHRqcm5janAifQ.jyKUab8wwVldCiCvFtBIYQ"
          />
          <Legend dataSets={this.State.dataSets} />
          <GeoJSON data={departementsData} style={this.styleonEachFeature} onEachFeature={this.onEachFeature} />
        </Map>
      </div>
    );
  }
}

export default BaseMapDepartements;
