import { Constants } from 'utils';

// TODO Upgrade all saved searches instead of this code
// Old saved searches use labels as keys, we need to translate to field names to make the UI work
// New searches use fields names as keys
const upgradeCriteria = criteria => {
  const attributesMapping = Object.values(Constants.SEARCH_FIELDS_FULL);
  const upgradedCriteria = {};
  Object.keys(criteria).forEach(key => {
    if (criteria[key].length > 0) {
      const replacingKey = attributesMapping.find(attr => attr.label === key);
      if (replacingKey) {
        upgradedCriteria[replacingKey.name] = criteria[key];
      } else {
        upgradedCriteria[key] = criteria[key];
      }
    }
  });
  return upgradedCriteria;
};

const getCriteriaBySet = set => set.sets.map(subset => ({ setId: subset.id, criteria: upgradeCriteria(subset.lists) }));
// const getCriteriaBySet = set => set.sets.map(subset => ({ setId: subset.id, criteria: subset.lists }));

const getNewSetId = members => {
  // Get an ID available
  const lastId = Math.max(...members.map(m => m.setId));
  return lastId + 1;
};

const getNewMemberId = members => {
  // Get an ID available
  const lastId = Math.max(...members.map(m => m.id));
  return lastId + 1;
};

const extractFormulaLabel = formula => {
  const regex = /(@\[.+?\]\((\d+|N([+-]\d)?)\)|[^@]+)/g;
  const parts = formula.match(regex) || [];
  return parts.length > 0 ? formula.substring(formula.indexOf('@[') + 2, formula.indexOf('](')) : formula;
};

// Merge adjacent textual members
const getMergedMembers = members =>
  members.reduce((acc, item, index) => {
    if (index === 0) {
      return [item];
    }
    const lastItem = acc[acc.length - 1];

    if (
      item.setId === -1 &&
      lastItem.setId === -1 &&
      !Object.prototype.hasOwnProperty.call(lastItem, 'exer') &&
      !Object.prototype.hasOwnProperty.call(item, 'exer')
    ) {
      // textual item following textual item => merge
      acc[acc.length - 1] = {
        label: `${lastItem.label}${item.label}`,
        formula: `${lastItem.formula}${item.formula}`,
        setId: -1,
        id: lastItem.id,
      };
      if (Object.prototype.hasOwnProperty.call(item, 'exer')) {
        acc[acc.length - 1].exer = item.exer;
      }
      return acc;
    }
    return [...acc, item];
  }, []);

const getMembersFromSet = set => {
  const regex = /(@\[.+?\]\((\d+|N([+-]\d)?)\)|[^@]+)/g;
  const parts = set.formula.match(regex) || [];

  const members = parts.map(part => {
    // Look for the part as a subset formula
    const subset = set.sets.find(s => s.formulaMember === part);
    if (subset) {
      const result = { label: subset.label, formula: subset.formulaMember, setId: subset.id };
      if (Object.prototype.hasOwnProperty.call(subset, 'exer')) {
        result.exer = subset.exer;
      }
      return result;
    }
    const exerRegex = /\(N([+-]\d)\)/;
    const exerPart = part.match(exerRegex) || [];

    if (exerPart.length > 1) {
      const exerLabel = extractFormulaLabel(part);
      return { label: exerLabel, formula: part, setId: -1, exer: exerPart[1] };
    }
    return { label: part, formula: part, setId: -1 };
  });

  // We add empty members to allow the cursor to be between two non-textual members
  const tmp = members.flatMap((value, index, array) =>
    // If the member is an EXER set member, do not add a text member just after
    array.length - 1 !== index && value.setId > -1 && !Object.prototype.hasOwnProperty.call(value, 'exer') // check for the last item
      ? [value, { label: '', formula: '', setId: -1 }]
      : value
  );
  const membersWithSlots = [{ label: '', formula: '', setId: -1 }, ...tmp, { label: '', formula: '', setId: -1 }];

  // Merge adjacent textual members
  const mergedMembers = getMergedMembers(membersWithSlots);

  // assign an id to each member
  return mergedMembers.map((member, index) => ({ ...member, id: index }));
};

const getFormulaLabel = criteria =>
  Object.entries(criteria)
    .map(([label, value]) => ({ label, value }))
    .sort((a, b) => `${a.label}`.localeCompare(b.label))
    .map(f => (Array.isArray(f.value) ? f.value.join(', ') : f.value))
    .filter(d => d)
    .join(' | ');

const isCriteriaEmpty = criteria =>
  // Sum of the lengths of all the values of each key
  Object.keys(criteria)
    .map(key => criteria[key].length)
    .reduce((acc, item) => acc + item, 0) === 0;

const replaceItemAtIndexWithElements = (originalArray, index, itemsToInsert) => {
  // Check if index is inside the array
  if (originalArray.length > index) {
    const head = index === 0 ? [] : originalArray.slice(0, index); // item at index is excluded
    const tail = index === originalArray.length - 1 ? [] : originalArray.slice(index + 1);
    return [...head, ...itemsToInsert, ...tail];
  }
  // When index cannot be inside the array : add items at the end
  console.log('Index cannot be inside the array : adding items at the end');
  return [...originalArray, ...itemsToInsert];
};

// Create a member based on the value of the criteria
const createNewMember = (members, newValue) => {
  const newSetId = getNewSetId(members);
  const newMemberId = getNewMemberId(members);
  const formulaLabel = getFormulaLabel(newValue);
  return {
    label: formulaLabel,
    formula: `@[${formulaLabel}](${newSetId})`,
    setId: newSetId,
    id: newMemberId,
  };
};

// Before : textMember1 -> textMember2 / setMember / textMember1
// After : textMember1 -> textMember1 / setMember / textMember2
const addMemberBeforeOrAfterText = (members, selectedMember, newMember, cursorPosition) => {
  const insertionPoint = members.findIndex(member => member.id === selectedMember.id);
  const emptyMember = { label: '', formula: '', setId: -1, id: newMember.id + 1 };
  const firstMember = cursorPosition === 0 ? emptyMember : selectedMember;
  const lastMember =
    cursorPosition === selectedMember.formula.length ? { ...emptyMember, id: newMember.id + 2 } : selectedMember;
  return replaceItemAtIndexWithElements(members, insertionPoint, [firstMember, newMember, lastMember]);
};

// half1half2 -> half1 / setMember / half2
const addMemberInsideText = (members, selectedMember, newMember, cursorPosition) => {
  const insertionPoint = members.findIndex(member => member.id === selectedMember.id);
  const firstText = selectedMember.formula.substring(0, cursorPosition);
  const lastText = selectedMember.formula.substring(cursorPosition);
  const firstHalf = { ...selectedMember, label: firstText, formula: firstText };
  const lastHalf = { ...selectedMember, label: lastText, formula: lastText, id: getNewMemberId(members) + 1 };
  return replaceItemAtIndexWithElements(members, insertionPoint, [firstHalf, newMember, lastHalf]);
};

const getUpdatedMembersWhenSetMemberSelected = (members, selectedMember, newValue) => {
  const formulaLabel = getFormulaLabel(newValue);
  const criteriaCleared = isCriteriaEmpty(newValue);

  const updatedSelectedMember = criteriaCleared
    ? { ...selectedMember, label: '', formula: '', setId: -1 }
    : {
        ...selectedMember,
        label: formulaLabel,
        formula: `@[${formulaLabel}](${selectedMember.setId})`,
      };

  const updatedMembers = members.map(member => {
    if (member.id === updatedSelectedMember.id) {
      return { ...updatedSelectedMember };
    }
    return member;
  });

  return getMergedMembers(updatedMembers);
};

const getNewSelectedMember = (members, selectedMember, mergedMembers) => {
  const membersIds = members.map(m => m.id);
  // Retrieve which member to select after merge
  // Get reverse array of [0..selectedId] taken from updatedMembers
  // Then get first of mergedMembers[id] found in membersUntilSelected
  const membersUntilSelected = membersIds.slice(0, membersIds.indexOf(selectedMember.id) + 1).reverse();
  const idToSelect = membersUntilSelected.find(idx => mergedMembers.find(item => item.id === idx));
  return mergedMembers.find(item => item.id === idToSelect);
};

const getQueryBySet = set => set.sets.map(subset => ({ setId: subset.id, query: subset.query }));

const rebuildSet = (set, members, criteriaBySet, queryBySet) => {
  const formula = members.map(member => member.formula).join('');
  const label = members.map(member => member.label).join('');

  const datasetMembers = members.filter(member => member.setId > -1);
  const subsets = datasetMembers.map(member => {
    const { query } = queryBySet.find(item => item.setId === member.setId) || {};
    const { criteria } = criteriaBySet.find(item => item.setId === member.setId) || {};

    if (!query) {
      console.error('Mmmh problem : no query found for set ID', member.setId);
    }
    if (!criteria) {
      console.error('Mmmh problem : no criteria found for set ID', member.setId);
    }

    const result = { ...member, id: member.setId, formulaMember: member.formula, lists: criteria, query };
    delete result.setId;
    return result;
  });
  return { color: set.color, id: set.id, formula, placeholder: label, sets: subsets, label: set.label };
};

export default {
  getCriteriaBySet,
  // getNewSetId,
  // getNewMemberId,
  // extractFormulaLabel,
  getMergedMembers,
  getMembersFromSet,
  getFormulaLabel,
  isCriteriaEmpty,
  // replaceItemAtIndexWithElements,
  createNewMember,
  addMemberBeforeOrAfterText,
  addMemberInsideText,
  getUpdatedMembersWhenSetMemberSelected,
  getNewSelectedMember,
  getQueryBySet,
  rebuildSet,
};
