import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { TabPanel, TabView } from 'primereact/tabview';
import { Button } from 'primereact/button';

import { AggregatedData, DetailedData } from './components';

import './SearchResultsPane.scss';

export default function SearchResultsPane({ datasetLabel = '', aggregation = '' }) {
  const [resultsVisible, setResultsVisible] = useState(false);

  useEffect(() => {
    // Hide data when dataset changes
    setResultsVisible(false);
  }, [datasetLabel]);

  return (
    <>
      <div className="search-result-header">
        <div>
          <Button
            label={resultsVisible ? 'Masquer les données' : 'Afficher les données'}
            onClick={() => setResultsVisible(prev => !prev)}
            disabled={!datasetLabel}
          />
        </div>
        <label>Jeu de données : {datasetLabel}</label>
      </div>
      {resultsVisible && (
        <TabView style={{ padding: 0, marginTop: 0 }} activeIndex={0}>
          <TabPanel header="Données aggrégées">
            <AggregatedData aggregation={aggregation} />
          </TabPanel>
          <TabPanel header="Détail">
            <DetailedData />
          </TabPanel>
        </TabView>
      )}
    </>
  );
}

SearchResultsPane.propTypes = {
  datasetLabel: PropTypes.string,
  aggregation: PropTypes.string,
};
