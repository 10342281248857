import React, { useState, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ReactiveBase, StateProvider } from '@appbaseio/reactivesearch';

import { App, Credentials, Host } from 'config/elastic';
import { DatasetType } from 'propTypes';
import { useDebouncedCallback, FormulaHelper } from 'utils';
import { FormulaInputNew, SearchCriteria, SearchResultsPane, ImportModal, MasterQuery } from './components';

import './SearchModalNew.scss';

const debugMode = false;

// Extract criteria from the subsets
const getCriteriaBySet = set => set.sets.map(subset => ({ setId: subset.id, criteria: subset.lists }));
// Extract queries from the subsets
const getQueryBySet = set => set.sets.map(subset => ({ setId: subset.id, query: subset.query }));
// Compute an id for a new set
const getNewSetId = formula => Math.max(...FormulaHelper.getSetsIds(formula), 0) + 1;

/**
 * Rebuild the set with state data
 * @param set original data structure
 * @param formula the string formula
 * @param criteriaBySet selected criteria per set { setId, criteria }
 * @param queryBySet ElasticSearch queries for each set { setId, query }
 * @returns {*}
 */
const rebuildSet = (set, formula, criteriaBySet, queryBySet) => {
  const label = FormulaHelper.getFormulaLabel(formula);
  const formulaSetsInfo = FormulaHelper.getFormulaInfo(formula).filter(item => item.setId > -1);

  const subsets = formulaSetsInfo.map(info => {
    const { query } = queryBySet.find(item => item.setId === info.setId) || {};
    const { criteria } = criteriaBySet.find(item => item.setId === info.setId) || {};

    if (!query) {
      console.error('Mmmh problem : no query found for set ID', info.setId);
    }
    if (!criteria) {
      console.error('Mmmh problem : no criteria found for set ID', info.setId);
    }

    const result = { ...info, id: info.setId, formulaMember: info.formula, lists: criteria, query };
    delete result.setId;
    return result;
  });
  return { color: set.color, id: set.id, formula, placeholder: label, sets: subsets, label: set.label };
};

const isCriteriaEmpty = criteria =>
  // Sum of the lengths of all the values of each key
  Object.keys(criteria)
    .map(key => criteria[key].length)
    .reduce((acc, item) => acc + item, 0) === 0;

// Computes the label associated to the criteria
const getFormulaLabel = criteria =>
  Object.entries(criteria)
    .map(([label, value]) => ({ label, value }))
    .sort((a, b) => `${a.label}`.localeCompare(b.label))
    .map(f => (Array.isArray(f.value) ? f.value.join(', ') : f.value))
    .filter(d => d)
    .join(' | ');

export default function SearchModalNew({
  set = {},
  title = '',
  aggreg = '',
  defaultImportAggregs,
  onClose = () => {},
  onSave = () => {},
}) {
  const toast = useRef(null);
  const [importVisible, setImportVisible] = useState(false);
  const [formula, setFormula] = useState(set?.formula);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [criteriaBySet, setCriteriaBySet] = useState(getCriteriaBySet(set));
  const [queryBySet, setQueryBySet] = useState(getQueryBySet(set));
  const [currentSetId, setCurrentSetId] = useState();
  const [currentSetCriteria, setCurrentSetCriteria] = useState();

  const onFormulaChange = newFormula => {
    setFormula(newFormula);

    // Sync criteria and queries, in case some sets have been deleted
    const setsIds = FormulaHelper.getSetsIds(newFormula);
    const filteredQueryItems = queryBySet.filter(item => setsIds.indexOf(item.setId) > -1);
    const filteredCriteriaItems = criteriaBySet.filter(item => setsIds.indexOf(item.setId) > -1);
    setQueryBySet(filteredQueryItems);
    setCriteriaBySet(filteredCriteriaItems);
  };

  const onMemberSelected = (setId, newCursorPosition) => {
    setCursorPosition(newCursorPosition);
    setCurrentSetId(setId);
    setCurrentSetCriteria(setId > -1 ? criteriaBySet.find(item => item.setId === setId)?.criteria : {});
  };

  const onStateChange = () => {
    // const onStateChange = (prev, next) => {
    // TODO Add debounce 250ms
    // console.log('onStateChange, prev:', prev, ', next:', next);
  };

  const onCriteriaChange = newValue => {
    // If an EXER (N+-1) member is selected, do nothing
    if (FormulaHelper.hasCurrentMemberExer(formula, cursorPosition)) {
      return;
    }

    const criteriaLabel = getFormulaLabel(newValue);
    const selectedId = FormulaHelper.getSelectedSetId(formula, cursorPosition);

    if (selectedId < 0) {
      // It is not a set member
      const newSetId = getNewSetId(formula);
      const criteriaFormula = `@[${criteriaLabel}](${newSetId})`;
      // Insert the formula at cursor position
      const insertPoint = FormulaHelper.translatePositionInFormula(formula, cursorPosition);
      const newFormula = `${formula.substring(0, insertPoint)}${criteriaFormula}${formula.substring(insertPoint)}`;

      // Move the cursor by the length of the new label
      setCursorPosition(previous => previous + criteriaLabel.length);
      setFormula(newFormula);
      setCriteriaBySet([...criteriaBySet, { setId: newSetId, criteria: newValue }]);
      setCurrentSetId(newSetId);
    } else {
      // It is a set member
      const formulaOldLabel = FormulaHelper.getSetLabel(formula, selectedId);
      const formulaOldMember = `@[${formulaOldLabel}](${selectedId})`;

      if (isCriteriaEmpty(newValue)) {
        // Delete member
        const newFormula = formula.replace(formulaOldMember, '');

        // Start of the member being deleted = end of previous
        setCursorPosition(FormulaHelper.getSelectedMemberPositions(formula, selectedId)?.start);
        setFormula(newFormula);
      } else {
        // Replace member with new value
        const newFormula = formula.replace(formulaOldMember, `@[${criteriaLabel}](${selectedId})`);
        setCursorPosition(FormulaHelper.getSelectedMemberPositions(newFormula, selectedId).end);
        setFormula(newFormula);
      }
      const updatedCriteriaBySet = isCriteriaEmpty(newValue)
        ? criteriaBySet.filter(item => item.setId !== selectedId)
        : criteriaBySet.map(item => (item.setId === selectedId ? { ...item, criteria: newValue } : item));

      // Delete query items if the corresponding member no longer exists
      const existingSetIds = updatedCriteriaBySet.map(item => item.setId);
      const filteredQueryItems = queryBySet.filter(item => existingSetIds.indexOf(item.setId) > -1);

      setQueryBySet(filteredQueryItems);
      setCriteriaBySet(updatedCriteriaBySet);
    }
    setCurrentSetCriteria(newValue);
  };

  const onQueryChange = useDebouncedCallback(query => {
    if (currentSetId < 0) {
      return;
    }

    const previousSetQuery = queryBySet.find(item => item.setId === currentSetId);
    if (JSON.stringify(previousSetQuery?.query) === JSON.stringify(query)) {
      return;
    }

    // Add or update item
    if (currentSetId) {
      const updatedQueryBySet = queryBySet.find(item => item.setId === currentSetId)
        ? queryBySet.map(item => (item.setId === currentSetId ? { ...item, query } : { ...item }))
        : [...queryBySet, { setId: currentSetId, query }];
      setQueryBySet(updatedQueryBySet);
    }
  }, 300);

  const save = () => {
    // Rebuild dataset and send it to parent
    onSave(rebuildSet(set, formula, criteriaBySet, queryBySet));
    onClose();
  };

  return (
    <Sidebar fullScreen visible onHide={() => onClose()}>
      <div className="search-modal-new">
        <div className="search-modal-content">
          {title && <h3>{title}</h3>}

          {debugMode && (
            <div style={{ border: '1px solid firebrick', padding: '10px', margin: '10px 0' }}>
              <pre>cursorPosition : {cursorPosition}</pre>
              <pre>currentSetCriteria : {JSON.stringify(currentSetCriteria, null, 2)}</pre>
              <pre>set.sets : {JSON.stringify(set.sets, null, 2)}</pre>
              <pre>currentSetId: {currentSetId}</pre>
              <pre>
                queryBySet :{' '}
                {JSON.stringify(
                  queryBySet.map(o => o.setId),
                  null,
                  2
                )}
              </pre>
            </div>
          )}
          <div className="p-toolbar">
            <FormulaInputNew
              formula={formula}
              cursorPosition={cursorPosition}
              onMemberSelected={onMemberSelected}
              onFormulaChange={onFormulaChange}
            />
            <div className="search-modal-controls">
              <Button icon="pi pi-save" onClick={() => setImportVisible(true)} />
              <Button label="OK" onClick={save} className="p-button-success" />
            </div>
          </div>

          <ReactiveBase url={Host} app={App} credentials={Credentials} className="reactive-block">
            <StateProvider includeKeys={['value', 'aggregations']} strict onChange={onStateChange} />
            <SearchCriteria value={currentSetCriteria} onChange={onCriteriaChange} />
            <MasterQuery selectedSetId={currentSetId} onMasterQueryChange={onQueryChange} />
            <SearchResultsPane
              datasetLabel={currentSetId > -1 ? FormulaHelper.getSetLabel(formula, currentSetId) : ''}
              aggregation={aggreg}
            />
          </ReactiveBase>
        </div>
      </div>

      {importVisible && (
        <ImportModal
          visible={importVisible}
          onHide={() => setImportVisible(false)}
          growl={toast.current}
          formula={rebuildSet(set, formula, criteriaBySet, queryBySet)}
          title={title}
          defaultAggregs={defaultImportAggregs}
        />
      )}
      <Toast ref={toast} />
    </Sidebar>
  );
}

SearchModalNew.propTypes = {
  set: DatasetType,
  title: PropTypes.string,
  aggreg: PropTypes.string,
  defaultImportAggregs: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};
