import React from 'react';

import { UserMenu } from './components';

import './Navbar.scss';

export default function Navbar() {
  return (
    <div className="navbar">
      <UserMenu />
    </div>
  );
}
