import React from 'react';

import './NotFound.scss';

export default function NotFound() {
  return (
    <div className="not-found-page">
      <h1>La page demandée n&apos;existe pas.</h1>
    </div>
  );
}

NotFound.propTypes = {};
