export function shuffle(array) {
  const n = array.length;
  const tempArray = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < n - 1; i++) {
    // The following line removes one random element from arr
    // and pushes it onto tempArr
    tempArray.push(array.splice(Math.floor(Math.random() * array.length), 1)[0]);
  }
  // Push the remaining item onto tempArr
  tempArray.push(array[0]);
  return tempArray;
}
