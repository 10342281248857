import React, { useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Menu } from 'primereact/menu';

import { LoadDots } from '../../..';

import './UserMenu.scss';

export default function UserMenu() {
  const menu = useRef(null);
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  if (isLoading) {
    return (
      <div>
        Chargement <LoadDots />
      </div>
    );
  }

  const menuItems = [
    {
      label: 'Déconnexion',
      icon: 'pi pi-fw pi-power-off',
      command: () => {
        logout({ returnTo: window.location.origin });
      },
    },
  ];
  return (
    isAuthenticated && (
      <>
        <Menu className="user-menu-dropdown" model={menuItems} popup ref={menu} />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="user-menu" onClick={event => menu.current.toggle(event)}>
          <>
            <div className="">
              <img className="user-avatar" src={user.picture} alt={user.name} />
            </div>
            <div className="user-name">
              <span>{user.name}</span>
            </div>
          </>
        </div>
      </>
    )
  );
}
