import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import * as moment from 'moment';

import { PageTitle } from 'components';
import { dashboardService, userService } from 'services';
// TODO Move component if still used here
import { DashboardPreview } from '../Dashboard/components';

import './DashboardTable.scss';

export default function DashboardTable() {
  const [loading, setLoading] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [refreshId, setRefreshId] = useState();
  const [selectedDashboardId, setSelectedDashboardId] = useState();
  const [changeShareId, setChangeShareId] = useState();
  const [duplicateId, setDuplicateId] = useState();
  const toast = useRef(null);
  const history = useHistory();
  const { id: userId } = userService.getUser();

  const getDashboards = useCallback(async () => {
    try {
      const data = await dashboardService.getDashboards();
      setDashboards(data);
      setLoading(false);
    } catch (error) {
      console.log('getDashboards error :', error);
      toast.current.show({
        severity: 'error',
        sticky: false,
        summary: "Impossible d'afficher les données",
        detail: `Une erreur est survenue, veuillez réessayer.\n\n${error && error.message}`,
      });
      setDashboards([]);
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    getDashboards();
  }, [getDashboards]);

  const isAdminOrOwner = owner => userService.isAdmin() || owner.id === userId;

  const createDashboard = () => {
    history.push(`/builder`);
  };

  const edit = dashboard => {
    history.push(`/builder/${dashboard.id}`);
  };

  const deleteDashboard = async dashboard => {
    setDeleteId(dashboard.id);
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer ${dashboard.name} ?`)) {
      try {
        await dashboardService.deleteDashboard(dashboard.id);
        setDeleteId(null);
        await getDashboards();
      } catch (error) {
        console.log('error :', error);
        toast.current.show({
          severity: 'error',
          summary: 'Erreur de suppression',
          detail: error?.message || 'Erreur lors de la suppression des données',
        });
        setDeleteId(null);
      }
    }
    setDeleteId(null);
  };

  const refreshDashboard = async dashboard => {
    setRefreshId(dashboard.id);
    try {
      await dashboardService.refreshDashboardData(dashboard.id);
      setRefreshId(null);
      await getDashboards();
      toast.current.show({
        severity: 'success',
        summary: 'Traitement terminé !',
        detail: 'Les données ont été mises à jour',
      });
    } catch (error) {
      console.log('error :', error);
      toast.current.show({
        severity: 'error',
        summary: 'Erreur de mise à jour',
        detail: error?.message || 'Erreur lors du rafraîchissement des données',
      });
      setRefreshId(null);
    }
  };

  const toggleDashboardSharing = async (dashboard, newShareValue) => {
    try {
      if (isAdminOrOwner(dashboard.owner)) {
        setChangeShareId(dashboard.id);
        await dashboardService.toggleDashboardSharing(dashboard.id, newShareValue);
        await getDashboards();
        setChangeShareId(null);
        toast.current.show({
          severity: 'success',
          summary: `${newShareValue ? 'Partager' : 'Arrêter le partage'}`,
          detail: 'Traitement terminé !',
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Erreur',
        detail: "L'opération n'a pu aboutir",
      });
      console.log('Toggle dashboard sharing error ', error);
      setChangeShareId(null);
    }
  };

  const duplicateDashboard = async dashboard => {
    try {
      if (isAdminOrOwner(dashboard.owner)) {
        setDuplicateId(dashboard.id);
        await dashboardService.duplicateDashboard(dashboard.id);
        await getDashboards();
        setDuplicateId(null);
        toast.current.show({ severity: 'success', summary: 'Dupliquer le dashboard', detail: 'Traitement terminé !' });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Erreur',
        detail: "L'opération n'a pu aboutir",
      });
      console.log('Duplicate dashboard error ', error);
      setDuplicateId(null);
    }
  };

  const renderLabel = dashboard => (
    <div className="dashboard-label-cell">
      <div className="dashboard-share-indicator">
        {dashboard.shared && <i className="pi pi-lock-open" title="Ce dashboard est partagé" />}
      </div>
      <span>{dashboard.name}</span>
    </div>
  );

  const actions = dashboard => (
    <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
      <Button
        tooltip="Editer"
        tooltipOptions={{ position: 'left' }}
        icon="pi pi-pencil"
        onClick={() => edit(dashboard)}
        className="p-button-secondary p-button-rounded p-button-text"
        disabled={!isAdminOrOwner(dashboard.owner)}
      />

      <Button
        tooltip="Prévisualiser"
        tooltipOptions={{ position: 'left' }}
        icon="pi pi-eye"
        onClick={() => setSelectedDashboardId(dashboard.id)}
        className="p-button-secondary p-button-rounded p-button-text"
      />

      <Button
        tooltip="Actualiser les données"
        tooltipOptions={{ position: 'left' }}
        icon="pi pi-refresh"
        onClick={() => refreshDashboard(dashboard)}
        className="p-button-secondary p-button-rounded p-button-text"
        disabled={!isAdminOrOwner(dashboard.owner)}
        loading={refreshId === dashboard.id}
      />

      <Button
        tooltip="Dupliquer"
        tooltipOptions={{ position: 'left' }}
        icon="pi pi-copy"
        onClick={() => duplicateDashboard(dashboard)}
        className="p-button-secondary p-button-rounded p-button-text"
        disabled={!isAdminOrOwner(dashboard.owner)}
        loading={duplicateId === dashboard.id}
      />

      <Button
        tooltip={`${dashboard.shared ? 'Arrêter le partage' : 'Partager'}`}
        tooltipOptions={{ position: 'left' }}
        icon="pi pi-unlock"
        onClick={() => toggleDashboardSharing(dashboard, !dashboard.shared)}
        className="p-button-secondary p-button-rounded p-button-text"
        disabled={!isAdminOrOwner(dashboard.owner)}
        loading={changeShareId === dashboard.id}
      />

      <Button
        tooltip="Supprimer"
        tooltipOptions={{ position: 'left' }}
        icon="pi pi-trash"
        onClick={() => deleteDashboard(dashboard)}
        className="p-button-danger p-button-rounded p-button-text"
        disabled={!isAdminOrOwner(dashboard.owner)}
        loading={deleteId === dashboard.id}
      />
    </div>
  );

  return (
    <section className="dashboard-page">
      <Toast ref={toast} />
      <PageTitle title="Dashboard" loading={loading}>
        <Button onClick={createDashboard}>Nouveau dashboard</Button>
      </PageTitle>

      <div className="dashboard-list">
        <DataTable
          value={dashboards}
          sortField="createDate"
          sortOrder={-1}
          scrollable
          responsive
          resizableColumns
          paginator
          rows={10}
        >
          <Column
            field="thumbnail"
            header=""
            body={rowData => <img className="dashboard-thumb" alt="vignette" src={rowData.thumbnail} />}
          />
          <Column field="name" header="Libellé" sortable filter filterMatchMode="contains" body={renderLabel} />
          <Column field="description" header="Description" sortable filter filterMatchMode="contains" />
          <Column
            field="createDate"
            header="Créé le"
            style={{ width: '180px', textAlign: 'center' }}
            sortable
            body={rowData => (rowData.createDate ? moment(rowData.createDate).format('lll') : '')}
          />
          <Column
            field="updateDate"
            header="Modifié le"
            style={{ width: '180px', textAlign: 'center' }}
            sortable
            body={rowData => (rowData.updateDate ? moment(rowData.updateDate).format('lll') : '')}
          />

          <Column
            field="owner.name"
            header="Propriétaire"
            sortable
            style={{ width: '150px' }}
            body={rowData => rowData.owner?.name}
          />
          <Column field="id" header="Actions" style={{ width: '270px' }} body={actions} />
        </DataTable>
      </div>
      {/* <div className="dashboard-json"> */}
      {/*  <pre>{JSON.stringify(dashboards, null, 2)}</pre> */}
      {/* </div> */}

      {!!selectedDashboardId && (
        <DashboardPreview
          dashboardId={selectedDashboardId}
          visible={!!selectedDashboardId}
          onHide={() => setSelectedDashboardId(null)}
        />
      )}
    </section>
  );
}
