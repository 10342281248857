import React from 'react';
import * as PropTypes from 'prop-types';
import { TabPanel, TabView } from 'primereact/tabview';

import { Dashboardtype } from 'propTypes';
import { ResultDataTable, ResultMap } from './components';

import './DashboardResult.scss';
import { ProfilChart } from '../Chart';

export default function DashboardResult({ dashboard, currentTab = 0, onTabChange = () => {} }) {
  return (
    <TabView renderActiveOnly={false} activeIndex={currentTab} onTabChange={({ index }) => onTabChange(index)}>
      <TabPanel header="Graphique">
        <ProfilChart
          type={dashboard.chartType}
          dataSets={dashboard.dataSets}
          sorterSet={dashboard.sorterSet}
          dataTitle={dashboard.chartTitle}
          size={dashboard.filterSet.size}
        />
      </TabPanel>
      <TabPanel header="Données">
        <ResultDataTable
          dataTitle={dashboard.dataTitle}
          dataSets={dashboard.dataSets}
          sorterSet={dashboard.sorterSet}
          aggregations={dashboard.aggregs}
        />
      </TabPanel>
      <TabPanel header="Carte">
        <ResultMap dataSets={dashboard.dataSets} />
      </TabPanel>
    </TabView>
  );
}

DashboardResult.propTypes = {
  dashboard: Dashboardtype,
  currentTab: PropTypes.number,
  onTabChange: PropTypes.func,
};
