import React, { useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { Chip } from 'primereact/chip';
import { Menu } from 'primereact/menu';

import { Constants } from 'utils';

import './Aggregator.scss';
import { Dropdown } from 'primereact/dropdown';

/**
 * A simple widget displaying a customizable list of aggregation attributes
 * @param value array of strings (aggregation values)
 * @param onChange notify parent with the new value when it changes
 * @param disabled
 */
export default function Aggregator({ value = [], onChange = () => {}, disabled = false }) {
  const menu = useRef(null);

  // get all aggregations { label, value } based on the items of value
  const internalValue = useMemo(
    () =>
      value
        .map(item => Constants.AGGREGATIONS.find(aggregation => aggregation.value === item))
        // Remove possible undefined values
        .filter(o => !!o),
    [value]
  );

  const removeAggreg = aggregValue => {
    const index = value.findIndex(aggreg => aggreg === aggregValue);
    if (index > -1) {
      const newValue = [...value];
      newValue.splice(index, 1);
      onChange(newValue);
    } else {
      // eslint-disable-next-line no-console
      console.log('Cannot remove unknown aggreg with value :', aggregValue);
    }
  };

  // Take aggregation items not already used in value
  const menuItems = useMemo(
    () =>
      Constants.AGGREGATIONS.filter(item => value.indexOf(item.value) < 0).map(aggreg => ({
        label: aggreg.label,
        value: aggreg.value,
        command: () => onChange([...value, aggreg.value]),
      })),
    [onChange, value]
  );

  return (
    <div className="aggregator-container">
      <div className="aggregator-wrapper">
        <div className="aggregator-chips">
          {internalValue.map(aggregation => (
            <Chip
              key={aggregation.value}
              label={aggregation.label}
              className="p-mb-2"
              removable={!disabled}
              onRemove={() => removeAggreg(aggregation.value)}
            />
          ))}
        </div>
        <div className="aggregator-controls">
          <Menu model={menuItems} popup ref={menu} id="popup_menu" style={{ width: '200px' }} />
          <Dropdown
            inputId="aggregator"
            value=""
            options={Constants.AGGREGATIONS.filter(agg => value.indexOf(agg.value) < 0)}
            className="value-dropdown"
            onChange={e => {
              onChange([...value, e.value]);
            }}
            placeholder="Ajouter"
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}

Aggregator.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
