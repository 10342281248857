/// ////////////////////////////////  sélection des champs pour l'agrégation des données  /////////////////////////////////////

import React, { Component } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { aggregationService } from 'services';
import { FormulaParser } from 'utils';
import { App, Client as elastic } from '../../config/elastic';

class Aggregator extends Component {
  aggregations = [
    { label: 'Exercice', value: 'EXER' },
    { label: 'Territoire régional', value: 'CREGI' },
    { label: 'Territoire départemental', value: 'NDEPT' },
    { label: 'Catégorie', value: 'CATEG' },
    { label: "Type d'établissement", value: 'CTYPE' },
    { label: 'Source', value: 'SOURCE' },
    { label: 'Nomenclature', value: 'NOMEN' },
    { label: 'Dépense / Recette', value: 'RD' },
    { label: 'Fonct. / Invest.', value: 'FI' },
    { label: 'Type de budget', value: 'CBUDG' },
    { label: 'Compte abrégé', value: 'COMPTE_ABBR' },
    { label: 'Compte de base', value: 'COMPTE_BASE' },
    { label: 'Compte développé', value: 'COMPTE' },
    { label: 'Fonction', value: 'FONCTION_0' },
    { label: 'Sous-Fonction', value: 'FONCTION_1' },
    { label: 'Code Fonction', value: 'FONCTION' },
    { label: 'Entité budgétaire', value: 'LBUDG' },
    { label: 'Requête', value: 'formulaLabel' },
    { label: 'Agrégat', value: 'AGREGAT' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      aggregs: this.props.defaultValue || ['EXER'],
    };
  }

  buildAggBody = (aggregsParam = [], filterSize) => {
    const body = {
      size: 0,
    };
    // const aggregs2 = this.state.aggregs.concat();
    const aggregs = [...aggregsParam];
    // console.log('Aggregator::buildAggBody, aggregs2 :', aggregs2);
    console.log('Aggregator::buildAggBody, aggregs :', aggregs);
    let aggreg = aggregs.pop();
    while (aggreg) {
      body.aggs = {
        AGG: {
          terms: {
            size: 1000000 || filterSize || 10, // by default
            field: `${aggreg}.keyword`,
            missing: 'N/A',
            order: { montant: 'desc' },
          },
          aggs: {
            montant: {
              sum: {
                field: 'montant',
              },
            },
            unit: {
              terms: {
                field: 'unit.keyword',
                missing: '',
              },
            },
            ...body.aggs,
          },
        },
      };
      aggreg = aggregs.pop();
    }

    // console.log('Aggregator::buildAggBody, body :', body);
    return body;
  };

  getAggValues = aggregations => {
    // console.log('Aggregator::getAggValues, aggregations:', aggregations);
    const values =
      !aggregations || !aggregations.AGG
        ? []
        : aggregations.AGG.buckets.map(item => {
            const inset = item.AGG ? this.getAggValues(item) : undefined;
            return {
              key: item.key,
              value: (item.montant || {}).value,
              units: item.unit.buckets.map(i => i.key),
              inset,
            };
          });

    const res = {};

    res.values = values.reduce((o, { key, value }) => {
      o[key] = value;
      return o;
    }, {});

    const hasInset = !!values.map(i => i.inset).filter(i => i).length;
    if (hasInset) {
      res.inset = values.reduce((o, { key, inset }) => {
        o[key] = inset;
        return o;
      }, {});
    } else {
      res.inset = {};
    }

    const units = [...new Set(values.map(v => v.units).flat())];
    res.unitPlaceholder = units.length === 1 ? units[0] : null;

    // console.log('AGG Values', aggregations, res);

    // console.log('Aggregator::getAggValues, res:', res);
    return res;
  };

  updateData = async (dataSets = [{}], filterSet = {}, sorterSet, aggregs, filterSize) => {
    console.log(' ');
    console.log(' ');
    console.log('########## CALL Aggregator::updateData ###########');
    console.log(' ');
    console.log(' ');
    const useNewService = true;

    // const dataSets = this.props.dataSets || [{}];
    // const filterSet = this.props.filterSet || {};
    // const sorterSet = this.props.sorterSet;

    if (useNewService) {
      console.log('NEW call service Aggregation');
      const { dataSets: newDatasets, sorterSet: newSorterSet } = await aggregationService.updateData(
        dataSets,
        filterSet,
        sorterSet,
        // this.state.aggregs.concat(),
        aggregs,
        filterSize
      );
      // const result = await aggregationService.updateDataOld(sets, filterSet, sorterSet, aggregators);

      console.log('result :', newDatasets, newSorterSet);
      // this.props.updateDataSets(
      //   newDatasets.map(set => parser.parse(set)),
      //   newSorterSet ? parser.parse(sorterSet) : null
      // );
      // return newDatasets;

      return { dataSets: [...newDatasets], sorterSet: { ...newSorterSet } };
    }
    const updatedDatasetOld = await this.updateDataOld(dataSets, filterSet, sorterSet, aggregs, filterSize);
    console.log('updatedDatasetOld :', updatedDatasetOld);
    return updatedDatasetOld;
  };

  updateDataOld = (dataSets, filterSet, sorterSet, aggregs, filterSize) => {
    console.log('Use old code', aggregs);
    // OLD CODE
    // console.log(dataSets);
    // Recalculer les valeurs de chaque set
    return (
      Promise.all(
        dataSets
          .concat(sorterSet || {})
          .map((set, k) =>
            Promise.all(
              (set.sets || []) /* .filter((s, k) => parser.isUsingSet(set, k)) */
                .map(s => {
                  const body = { ...s.query, ...this.buildAggBody(aggregs, filterSize) };
                  const filters = (filterSet.sets || []).map(s2 => s2.query.query);
                  if (filters) {
                    body.query = { bool: { must: [body.query, ...filters] } };
                  }
                  // console.log('body', body, filterSet);

                  return elastic
                    .search({
                      index: App,
                      body,
                      requestTimeout: 300000,
                    })
                    .then(({ aggregations }) => {
                      const res = this.getAggValues(aggregations);
                      Object.assign(s, res);
                      delete s.exer;
                      // console.log(set, res);
                    })
                    .catch(error => {
                      console.error('Erreur', error.message, error);

                      if (error && error.message && error.message.match(/\[too_many_buckets_exception\]/)) {
                        this.growl &&
                          this.growl.show({
                            severity: 'error',
                            sticky: false,
                            summary: "Impossible d'afficher les données",
                            detail:
                              "Une erreur est survenue en raison du trop grand nombre de critères d'agrégations. Veuillez modifier votre demande et réessayer.",
                          });
                      } else {
                        this.growl &&
                          this.growl.show({
                            severity: 'error',
                            sticky: false,
                            summary: "Impossible d'afficher les données",
                            detail: `Une erreur est survenue, veuillez réessayer.\n\n${error && error.message}`,
                          });
                      }
                      Object.assign(s, this.getAggValues(null));
                    });
                })
            )
          )
          .flat()
      )
        // .then(() => console.log('DataSets', dataSets))
        .then(() => {
          // this.props.updateDataSets(
          //   dataSets.map(set => parser.parse(set)),
          //   sorterSet ? parser.parse(sorterSet) : null
          // );
          // return dataSets;
          const parser = new FormulaParser();
          return {
            dataSets: [...dataSets.map(set => parser.parse(set))],
            sorterSet: sorterSet ? parser.parse(sorterSet) : null,
          };
        })
    );
  };

  updateAggregs = aggregs => {
    this.props.updateAggregs(aggregs);
    console.log('>>> Aggregator.updateAggregs : call updateData without parameters = bug !');
    this.setState({ aggregs }, this.props.updateImmediately ? this.updateData : null);
  };

  setAggreg = (k, aggreg) => {
    const { aggregs } = this.state;
    aggregs[k] = aggreg;
    this.updateAggregs(aggregs);
  };

  addAggreg = () => {
    const nextValue = (this.aggregations.filter(a => !this.state.aggregs.includes(a.value)).shift() || {}).value;
    const { aggregs } = this.state;
    aggregs.push(nextValue);
    this.updateAggregs(aggregs);
  };

  delAggreg = () => {
    const { aggregs } = this.state;
    aggregs.pop();
    this.updateAggregs(aggregs);
  };

  render = () => (
    <div style={{ display: 'flex', alignItems: 'center', ...this.props.style }}>
      <label htmlFor="aggregator" style={{ whiteSpace: 'no-wrap' }}>
        Aggréger par :{' '}
      </label>
      <div style={{ marginLeft: '.5em', display: 'inline-block' }}>
        <Toast ref={el => (this.growl = el)} />
        {this.state.aggregs.map((aggreg, k) => (
          <span key={k} style={{ display: 'inline-flex', alignItems: 'center' }}>
            <Dropdown
              inputId="aggregator"
              value={aggreg}
              options={this.aggregations}
              style={{ margin: '.5em 0' }}
              onChange={e => {
                this.setAggreg(k, e.value || 'EXER');
              }}
              placeholder="Exercice"
            />
            &nbsp;
            {k === this.state.aggregs.length - 1 && k < this.aggregations.length - 1 ? (
              <Button onClick={this.addAggreg} icon="pi pi-plus" style={{ margin: '.2em', verticalAlign: 'middle' }} />
            ) : null}
            {k === this.state.aggregs.length - 1 && k > 0 ? (
              <Button
                className="p-button-secondary p-button-outlined"
                onClick={this.delAggreg}
                icon="pi pi-minus"
                style={{ margin: '.2em', verticalAlign: 'middle' }}
              />
            ) : null}
          </span>
        ))}
      </div>
    </div>
  );
}

export default Aggregator;
