/**
 * Use a class to store state instead of hooks
 * Because setState triggers a render and causes infinite elastic queries (with DataSearch remote)
 */
class ElasticSearchState {
  aggregatedOptions;

  setAggregatedOptions(options) {
    this.aggregatedOptions = options;
  }

  getAggregatedOptions() {
    return this.aggregatedOptions;
  }
}

export default new ElasticSearchState();
