import React, { useState, useEffect, useCallback, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import * as moment from 'moment';
import 'moment/locale/fr';

import { PageTitle, SearchModal } from 'components';
import { formulaService, userService } from 'services';

import './SavedSearches.scss';
import { ImportModal } from '../../components/SearchModal/components';

const confirmRefreshAll = total =>
  `Êtes-vous sûr de vouloir actualiser ${total} requêtes ? L'opération peut prendre du temps`;

export default function SavedSearches() {
  const [formulae, setFormulae] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [refreshingAll, setRefreshingAll] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [formulaToSave, setFormulaToSave] = useState();
  const [refreshId, setRefreshId] = useState();
  const [deleteId, setDeleteId] = useState();
  const [changeShareId, setChangeShareId] = useState();
  const toast = useRef(null);

  const { id: userId } = userService.getUser();

  const getFormulae = useCallback(async () => {
    try {
      setLoading(true);
      const result = await formulaService.getFormulae();
      console.log('formulae :', result);
      setFormulae(result);
      setLoading(false);
    } catch (error) {
      console.error('Erreur', error.message, error);
      toast.current.show({
        severity: 'error',
        sticky: false,
        summary: "Impossible d'afficher les données",
        detail: `Une erreur est survenue, veuillez réessayer.\n\n${error && error.message}`,
      });
      setFormulae([]);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getFormulae();
  }, [getFormulae]);

  const isAdminOrOwner = owner => userService.isAdmin() || owner.id === userId;

  const refreshFormula = async ({ formula, aggregs, label, shared }) => {
    try {
      const refreshResult = await formulaService.refreshFormula(formula, label, aggregs, shared);
      toast.current.show({
        severity: 'success',
        summary: 'Traitement terminé !',
        detail: `${refreshResult} ${refreshResult > 1 ? 'lignes importées' : 'ligne importée'}`,
      });
    } catch (error) {
      console.error('Refresh formula error :', error);
      toast.current.show({
        severity: 'error',
        summary: 'Rafraîchir',
        detail: `Une erreur est survenue, veuillez réessayer. (Erreur : ${error})`,
      });
    }
  };

  const refreshAll = () => {
    // Avoid silly things
    if (!window.confirm(confirmRefreshAll(formulae.length))) {
      return;
    }
    let k = 0;
    setRefreshingAll(true);
    let promiseSeq = Promise.resolve();
    setProgress(0);

    formulae
      // eslint-disable-next-line no-underscore-dangle
      .sort((a, b) => moment(a._source.date).valueOf() - moment(b._source.date).valueOf())
      .forEach(rowData => {
        promiseSeq = promiseSeq.then(() => {
          // eslint-disable-next-line no-underscore-dangle
          const data = { ...rowData._source, formula: rowData.formula };
          return refreshFormula(data).then(
            () => {
              // eslint-disable-next-line no-plusplus
              setProgress(Math.round((++k / formulae.length) * 100));
            },
            e => {
              console.error('Erreur', data.label, e);
              return Promise.resolve();
            }
          );
        });
      });
    promiseSeq.then(async () => {
      await getFormulae();
      setRefreshingAll(false);
    });
  };

  const editData = item => {
    if (isAdminOrOwner(item.owner)) {
      setSearchVisible(true);
      setSelectedItem(item);
    }
  };

  const clickRefresh = async data => {
    if (isAdminOrOwner(data.owner)) {
      // eslint-disable-next-line no-underscore-dangle
      setRefreshId(data.formula._id);
      await refreshFormula(data);
      setRefreshId(null);
      await getFormulae();
    }
  };

  const toggleFormulaSharing = async (formulaId, owner, newShareValue) => {
    try {
      if (isAdminOrOwner(owner)) {
        setChangeShareId(formulaId);
        await formulaService.toggleFormulaSharing(formulaId, newShareValue);
        await getFormulae();
        setChangeShareId(null);
        toast.current.show({
          severity: 'success',
          summary: `${newShareValue ? 'Partager' : 'Arrêter le partage'}`,
          detail: 'Traitement terminé !',
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Erreur',
        detail: "L'opération n'a pu aboutir",
      });
      console.log('Toggle formula sharing error ', error);
      setChangeShareId(null);
    }
  };

  const deleteData = async ({ formula, label, owner }) => {
    if (!isAdminOrOwner(owner)) {
      return;
    }
    // eslint-disable-next-line no-underscore-dangle
    setDeleteId(formula._id);
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer ${label} ?`)) {
      try {
        const nbDeleted = await formulaService.deleteFormula(formula, label);
        await getFormulae();
        toast.current.show({
          severity: 'info',
          summary: 'Nettoyage effectué !',
          detail: `${nbDeleted} lignes supprimées`,
        });
        setDeleteId(null);
      } catch (error) {
        console.error('Delete data error :', error);
        toast.current.show({
          severity: 'error',
          summary: 'Erreur de suppression',
          detail: error?.message || 'Erreur lors de la suppression des données',
        });
        setDeleteId(null);
      }
    }
    setDeleteId(null);
  };

  const onSearchModalClose = () => {
    setSearchVisible(false);
    setSelectedItem(null);
    getFormulae();
  };

  // When we are ready to import data from this page
  const onDatasetSelected = dataset => {
    console.log('dataset selected', dataset);
    setFormulaToSave(dataset);
    setImportVisible(true);
  };

  const renderLabel = rowData => (
    <div className="formula-label-cell">
      <div className="formula-share-indicator">
        {/* eslint-disable-next-line no-underscore-dangle */}
        {rowData._source.shared && <i className="pi pi-lock-open" title="Cette requête est partagée" />}
      </div>
      {/* eslint-disable-next-line no-underscore-dangle */}
      <span>{rowData._source.label}</span>
    </div>
  );

  const actions = rowData => {
    // eslint-disable-next-line no-underscore-dangle
    const data = { ...rowData._source, formula: rowData.formula };

    return (
      <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
        <Button
          tooltip="Voir la formule"
          tooltipOptions={{ position: 'left' }}
          icon="pi pi-pencil"
          onClick={() => editData(data)}
          className="p-button-secondary p-button-rounded p-button-text"
          disabled={!isAdminOrOwner(data.owner)}
        />
        <Button
          tooltip="Actualiser les données"
          tooltipOptions={{ position: 'left' }}
          icon="pi pi-refresh"
          onClick={() => clickRefresh(data)}
          className="p-button-secondary p-button-rounded p-button-text"
          disabled={!isAdminOrOwner(data.owner)}
          // eslint-disable-next-line no-underscore-dangle
          loading={refreshId === data.formula._id}
        />
        <Button
          tooltip={`${data.shared ? 'Arrêter de partager la requête' : 'Partager la requête'}`}
          tooltipOptions={{ position: 'left' }}
          icon="pi pi-unlock"
          // eslint-disable-next-line no-underscore-dangle
          onClick={() => toggleFormulaSharing(data.formula._id, data.owner, !data.shared)}
          className="p-button-secondary p-button-rounded p-button-text"
          disabled={!isAdminOrOwner(data.owner)}
          // eslint-disable-next-line no-underscore-dangle
          loading={changeShareId === data.formula._id}
        />
        <Button
          tooltip="Supprimer les données"
          tooltipOptions={{ position: 'left' }}
          icon="pi pi-trash"
          onClick={() => deleteData(data)}
          className="p-button-danger p-button-rounded p-button-text"
          disabled={!isAdminOrOwner(data.owner)}
          // eslint-disable-next-line no-underscore-dangle
          loading={deleteId === data.formula._id}
        />
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <PageTitle title="Requêtes enregistrées" loading={loading}>
        {/* <Button onClick={() => setSearchVisible(true)}>Nouvelle requête</Button> */}
      </PageTitle>

      <DataTable
        value={formulae}
        sortField="_source.date"
        sortOrder={-1}
        scrollable
        responsive
        resizableColumns
        paginator
        rows={10}
      >
        <Column
          field="_source.date"
          header="Date"
          style={{ width: '180px', textAlign: 'center' }}
          sortable
          // eslint-disable-next-line no-underscore-dangle
          body={rowData => (rowData._source.date ? moment(rowData._source.date).format('lll') : '')}
        />
        <Column field="_source.label" header="Libellé" sortable filter filterMatchMode="contains" body={renderLabel} />
        <Column
          field="_source.owner.name"
          header="Propriétaire"
          sortable
          style={{ width: '150px' }}
          // eslint-disable-next-line no-underscore-dangle
          body={rowData => rowData._source?.owner?.name}
        />
        <Column field="_id" header="Actions" style={{ width: '200px' }} body={actions} />
      </DataTable>
      <br />
      {refreshingAll && (
        <div className="progress-bar">
          <ProgressBar value={progress} />
        </div>
      )}
      <Button
        label="Tout actualiser"
        icon="pi pi-refresh"
        onClick={refreshAll}
        className="p-button-warning"
        loading={refreshingAll}
      />

      {/* Not used for the moment, rework UX before */}
      {searchVisible && (
        <SearchModal
          set={selectedItem?.formula || { sets: [], formula: '' }}
          onSave={onDatasetSelected}
          onClose={onSearchModalClose}
          title={selectedItem?.label || ''}
          defaultImportAggregs={selectedItem?.aggregs || []}
          aggreg={selectedItem?.aggregs[0] || ''}
        />
      )}
      {importVisible && (
        <ImportModal
          visible={importVisible}
          onHide={async () => {
            setImportVisible(false);
            await getFormulae();
          }}
          growl={toast.current}
          formula={formulaToSave}
          title={formulaToSave?.label || ''}
        />
      )}
    </div>
  );
}
