import { shuffle } from './array';

const defaultColors = shuffle([
  '#8a633c',
  '#db4242',
  '#e98350',
  '#fdde6c',
  '#8bbb42',
  '#2cb377',
  '#328dbd',
  '#4150ad',
  '#583b97',
  '#9d3fa3',
  '#df5d8e',
  '#f1a5c7',
  '#87858e',
  '#e7dcdc',
]);

// Generate a random dataset ID
const getDatasetId = () => `dataset-${Math.floor(Math.random() * 10000000)}`;

export default { defaultColors, getDatasetId };
