import React, { useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext';

import { SortersetType } from 'propTypes';
import { SearchModal } from 'components';

import './SorterBuilder.scss';

/**
 * Displays a widget allowing to sort data with criteria and sort direction
 * @param set the filterSet to use
 * @param aggregs aggregations, forwarded to ReactiveSearch
 * @param disabled
 * @param onChange triggered when sort direction or dataset changes
 * @constructor
 */
export default function SorterBuilder({ set, aggregs = [], disabled = false, onChange = () => {} }) {
  // TODO Use reverse or set.reverse ?
  const [reverse, setReverse] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  // TODO Why only first aggregation ?
  const firstAggregation = useMemo(() => (aggregs.length > 0 ? aggregs[0] : null), [aggregs]);

  const onSave = savedSet => {
    const updatedSet = { ...savedSet, reverse };
    onChange(updatedSet);
  };

  const changeFilterDirection = () => {
    setReverse(previous => !previous);
    const updatedSet = { ...set, reverse: !reverse };
    onChange(updatedSet);
  };

  return (
    <div className="sorter-builder-container">
      <div className="sorter-builder-inputs">
        <div className="p-inputgroup">
          <Button
            label=""
            icon={`pi pi-sort-${set.reverse ? 'up' : 'down'}`}
            style={{ borderRadius: '5px 0 0 5px' }}
            className="p-button-default"
            onClick={changeFilterDirection}
            title="Croissant / Décroissant"
            disabled={disabled}
          />
          <div className={`filter-builder-clickable-text ${disabled ? 'disabled' : ''}`}>Trier selon</div>
          <InputText
            value={set.placeholder || 'les valeurs'}
            readOnly
            onClick={() => setShowSearch(true)}
            style={{ width: '15em', margin: '0 0 0 0' }}
            disabled={disabled}
          />
        </div>
      </div>

      {set.defaultError && (
        <Message
          severity="error"
          text={set.defaultError}
          style={{ float: 'right', marginTop: '-1em', fontSize: '.9em' }}
        />
      )}

      {showSearch && (
        <SearchModal set={set} onSave={onSave} aggreg={firstAggregation} onClose={() => setShowSearch(false)} />
      )}
    </div>
  );
}

SorterBuilder.propTypes = {
  set: SortersetType,
  aggregs: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
