import React from 'react';
import * as PropTypes from 'prop-types';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import * as moment from 'moment';

import { Dashboardtype } from 'propTypes';
import { userService } from 'services';
import { LoadDots } from 'components';

import './DashboardCard.scss';
import { ActionsMenu } from './components';

export default function DashboardCard({
  dashboard,
  working,
  onEdit = () => {},
  onPreview = () => {},
  onRefresh = () => {},
  onDuplicate = () => {},
  onToggleSharing = () => {},
  onDelete = () => {},
}) {
  const { id: userId } = userService.getUser();

  const isAdminOrOwner = owner => userService.isAdmin() || owner.id === userId;

  const menuActions = [
    {
      label: 'Dupliquer',
      icon: 'pi pi-fw pi-copy',
      // I can duplicate a dashboard shared with me
      disabled: working,
      command: () => {
        onDuplicate(dashboard);
      },
    },
    {
      label: 'Actualiser les données',
      icon: 'pi pi-fw pi-refresh',
      disabled: working || !isAdminOrOwner(dashboard.owner),
      command: () => {
        onRefresh(dashboard.id);
      },
    },
    {
      label: 'Supprimer',
      icon: 'pi pi-fw pi-trash',
      disabled: working || !isAdminOrOwner(dashboard.owner),
      command: () => {
        onDelete(dashboard);
      },
    },
  ];
  const actions = () => (
    <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
      <Button
        tooltip="Editer"
        tooltipOptions={{ position: 'left' }}
        icon="pi pi-pencil"
        onClick={() => onEdit(dashboard.id)}
        className="p-button-secondary p-button-rounded p-button-text"
        disabled={working || !isAdminOrOwner(dashboard.owner)}
      />
      <Button
        tooltip={`${dashboard.shared ? 'Arrêter le partage' : 'Partager'}`}
        tooltipOptions={{ position: 'left' }}
        icon="pi pi-unlock"
        onClick={() => onToggleSharing(dashboard, !dashboard.shared)}
        className="p-button-secondary p-button-rounded p-button-text"
        disabled={working || !isAdminOrOwner(dashboard.owner)}
      />
      <ActionsMenu actions={menuActions} disabled={working} />
    </div>
  );

  return (
    <Card>
      <div className="dashboard-card">
        <div className="dashboard-metadata">
          <div className="img-wrap">
            <img className="dash-thumb" alt="vignette" src={dashboard.thumbnail} title="Prévisualiser" />
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions */}
            <div className="img-overlay" title="Prévisualiser" onClick={() => onPreview(dashboard.id)}>
              <i className="pi pi-eye" style={{ fontSize: '3rem' }} />
            </div>
          </div>
          <div className="">
            <div className="dash-name">
              {dashboard.shared && (
                <div className="share-icon">
                  <i className="pi pi-lock-open" title="Ce dashboard est partagé" />
                </div>
              )}
              {dashboard.name}
              {working && <LoadDots />}
            </div>
            <div className="dash-description">{dashboard.description}</div>
            <div>
              <span className="prop-name">Propriétaire : </span>
              <span className="prop-value">{dashboard.owner.name}</span>
            </div>
            <div>
              <span className="prop-name">Créé : </span>
              <span className="prop-value">
                {dashboard.createDate ? moment(dashboard.createDate).format('lll') : ''}
              </span>
            </div>
            <div>
              <span className="prop-name">Modifié : </span>
              <span className="prop-value">
                {dashboard.updateDate ? moment(dashboard.updateDate).format('lll') : ''}
              </span>
            </div>
          </div>
        </div>
        <div className="dash-actions">{actions()}</div>
      </div>
    </Card>
  );
}

DashboardCard.propTypes = {
  dashboard: Dashboardtype,
  working: PropTypes.bool,
  onEdit: PropTypes.func,
  onPreview: PropTypes.func,
  onRefresh: PropTypes.func,
  onDuplicate: PropTypes.func,
  onToggleSharing: PropTypes.func,
  onDelete: PropTypes.func,
};
