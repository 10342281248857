/* eslint-disable no-underscore-dangle */
import { Client as elastic, DashboardApp } from '../config/elastic';
import { userService, aggregationService } from './index';

const accessModes = { READ: Symbol('read'), WRITE: Symbol('write') };

// Values and insets are stored as JSON, otherwise the number of fields may exceed mapping.total_fields.limit of the index
const convertSetForUI = dataset => ({
  ...dataset,
  values: JSON.parse(dataset.values || '{}'),
  inset: JSON.parse(dataset.inset || '{}'),
  sets: dataset.sets.map(s => ({ ...s, values: JSON.parse(s.values), inset: JSON.parse(s.inset) })),
});

const convertSetForStorage = dataset => ({
  ...dataset,
  values: JSON.stringify(dataset.values),
  inset: JSON.stringify(dataset.inset),
  sets: dataset.sets.map(s => ({ ...s, values: JSON.stringify(s.values), inset: JSON.stringify(s.inset) })),
});

// I can view a dashboard if it is mine, it is shared, or I am admin
const getReadDashboardQuery = id => {
  const isAdmin = userService.isAdmin();
  const { id: userId } = userService.getUser();
  const admQuery = { query: { bool: { must: [{ match: { _id: id } }] } } };
  const query = {
    query: {
      bool: {
        should: [{ match: { shared: true } }, { match: { 'owner.id': userId } }],
        must: [{ match: { _id: id } }],
      },
    },
  };
  return isAdmin ? admQuery : query;
};

// I can modify a dashboard if it is mine or I am admin
const getWriteDashboardQuery = id => {
  const isAdmin = userService.isAdmin();
  const { id: userId } = userService.getUser();
  const admQuery = { query: { bool: { must: [{ match: { _id: id } }] } } };
  const query = { query: { bool: { must: [{ match: { _id: id } }, { match: { 'owner.id': userId } }] } } };
  return isAdmin ? admQuery : query;
};

const listDashboards = async query => {
  const searchResult = await elastic.search({
    index: DashboardApp,
    body: { ...query, size: 10000, sort: [{ updateDate: { order: 'desc' } }, 'name.keyword'] },
  });
  const hits = searchResult?.hits?.hits || [];
  return hits.map(hit => ({
    ...hit._source,
    dataSets: hit._source.dataSets.map(convertSetForUI),
    sorterSet: convertSetForUI(hit._source.sorterSet),
    id: hit._id,
  }));
};

const getDashboardById = async (id, accessMode = accessModes.READ) => {
  const query = accessMode === accessModes.WRITE ? getWriteDashboardQuery(id) : getReadDashboardQuery(id);
  const dashboards = await listDashboards(query);
  return dashboards.length > 0 && dashboards[0];
};

class DashboardService {
  async createDashboard(dashboardData) {
    const user = userService.getUser();
    const createDate = new Date();
    const createData = {
      ...dashboardData,
      dataSets: dashboardData.dataSets.map(convertSetForStorage),
      sorterSet: convertSetForStorage(dashboardData.sorterSet),
      createDate,
      updateDate: createDate,
      owner: { id: user.id, name: user.name },
    };
    return elastic.index({ index: DashboardApp, refresh: 'true', body: { ...createData } });
  }

  /**
   * Get dashboards list with a few properties
   * The list contains all dashboards if I am admin, all my dashboards + shared dashboards if I am not admin
   * @returns {*}
   */
  async getDashboards() {
    const isAdmin = userService.isAdmin();
    const { id: userId } = userService.getUser();
    const ownerConstraint = isAdmin
      ? {}
      : { query: { bool: { should: [{ match: { 'owner.id': userId } }, { match: { shared: true } }] } } };
    const dashboards = await listDashboards(ownerConstraint);

    // Keep only useful properties
    return dashboards.map(dashboard => ({
      id: dashboard.id,
      name: dashboard.name,
      description: dashboard.description,
      thumbnail: dashboard.thumbnail,
      createDate: dashboard.createDate,
      updateDate: dashboard.updateDate,
      owner: dashboard.owner,
      shared: dashboard.shared,
    }));
  }

  /**
   * Get a dashboard I am able to modify. Need to be owner or admin to fetch it
   * @param dashboardId ID of the dashboard
   * @returns {*}
   */
  async getDashboardForEdit(dashboardId) {
    return getDashboardById(dashboardId, accessModes.WRITE);
  }

  /**
   * Get a dashboard I am able to view. Need to be admin or owner or it is shared to fetch it
   * @param dashboardId ID of the dashboard
   * @returns {*}
   */
  async getDashboardForPreview(dashboardId) {
    return getDashboardById(dashboardId, accessModes.READ);
  }

  /**
   * Update a dashboard
   * @param dashboardId ID of the dashboard
   * @param dashboardData all dashboard data to replace in Elastic
   * @returns {*}
   */
  async updateDashboard(dashboardId, dashboardData) {
    const dashboard = await this.getDashboardForEdit(dashboardId);
    if (dashboard) {
      const payload = {
        ...dashboardData,
        dataSets: dashboardData.dataSets.map(convertSetForStorage),
        sorterSet: convertSetForStorage(dashboardData.sorterSet),
        updateDate: new Date(),
      };
      delete payload.id;

      return elastic.update({
        index: DashboardApp,
        refresh: 'true',
        id: dashboardId,
        body: { doc: payload },
      });
    }
    console.log('Current user cannot update dashboard with id', dashboardId);
    return null;
  }

  /**
   * Delete a dashboard
   * @param dashboardId ID of the dashboard
   * @returns {*}
   */
  async deleteDashboard(dashboardId) {
    const dashboard = await this.getDashboardForEdit(dashboardId);
    if (dashboard) {
      return elastic.delete({ index: DashboardApp, id: dashboardId, refresh: 'true' });
    }
    console.log('Current user cannot delete dashboard with id', dashboardId);
    return null;
  }

  /**
   * Recompute the dashboard data and save it as an update
   * @param dashboardId ID of the dashboard
   * @returns {Promise<null|*>}
   */
  async refreshDashboardData(dashboardId) {
    const dashboard = await this.getDashboardForEdit(dashboardId);
    if (dashboard) {
      const updatedData = await aggregationService.updateData(
        dashboard.dataSets,
        dashboard.filterSet,
        dashboard.sorterSet,
        dashboard.aggregs
      );
      const dashboardData = { ...dashboard, dataSets: updatedData.dataSets, sorterSet: updatedData.sorterSet };
      return this.updateDashboard(dashboardId, dashboardData);
    }
    console.log('Current user cannot refresh dashboard with id', dashboardId);
    return null;
  }

  /**
   * Toggles sharing state of a dashboard. Need write access to do it
   * @param dashboardId ID of the dashboard
   * @param newSharingValue boolean the dashboard is shared or not
   * @returns {Promise<number|number|number>}
   */
  async toggleDashboardSharing(dashboardId, newSharingValue) {
    const dashboard = await this.getDashboardForEdit(dashboardId);
    if (dashboard) {
      const res = await elastic.updateByQuery({
        body: {
          script: { source: `ctx._source.shared = ${newSharingValue}`, lang: 'painless' },
          query: { bool: { must: [{ match: { _id: dashboardId } }] } },
        },
        index: DashboardApp,
        conflicts: 'proceed',
        refresh: 'wait_for',
      });
      return res?.updated || 0;
    }
    console.log('Current user cannot update dashboard with id', dashboardId);
    return 0;
  }

  /**
   * Create a clone of a dashboard, with a different name
   * @param dashboardId ID of the dashboard
   * @returns {Promise<null|*>}
   */
  async duplicateDashboard(dashboardId) {
    // I can duplicate a dashboard shared with me
    const dashboard = await this.getDashboardForPreview(dashboardId);
    if (dashboard) {
      const user = userService.getUser();
      const createDate = new Date();
      const dashboardData = {
        ...dashboard,
        name: `${dashboard.name} (copie)`,
        createDate,
        updateDate: createDate,
        shared: false,
        owner: { id: user.id, name: user.name },
      };
      return this.createDashboard(dashboardData);
    }
    console.log('Current user cannot duplicate dashboard with id', dashboardId);
    return null;
  }
}

export default new DashboardService();
