import React from 'react';

import './About.scss';

export default function About() {
  return (
    <div>
      <h2>À propos</h2>

      <h3>Provenance des données</h3>

      <h4>Plans de comptes</h4>
      <ul>
        <li>
          récupérés de
          <a target="_blank" rel="noopener noreferrer" href="http://odm-budgetaire.org/composants/normes/">
            http://odm-budgetaire.org/composants/normes/
          </a>
        </li>
      </ul>

      <blockquote className="quote">
        <p>
          Certains plans de comptes sont manquants, et les données correspondantes ont été exclues de l&apos;application
          puisque nous n&apos;aurions pas été capables de savoir si celles-ci relevaient d&apos;une dépense ou
          d&apos;une recette ou bien les libellés des comptes et fonctions associés.
        </p>

        <p>Il s&apos;agit notamment des plans de comptes correspondant aux nomenclatures suivantes :</p>

        <ul>
          <li>M14, M4, M42, M43_A, M43_D M44, M49_A, M49_D, M52, M61, M71 jusque 2012</li>
          <li>M157 jusque 2013</li>
          <li>M57 jusque 2014 (la M57 n&apos;a été dématérialisée qu&apos;à partir de l&apos;exercice 2015)</li>
          <li>M22 (n&apos;a pas encore été dématérialisée)</li>
        </ul>
      </blockquote>

      <h4>Agrégats</h4>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://data.economie.gouv.fr/explore/dataset/agregats-comptables-des-collectivites-et-des-etablissements-publics-locaux-2018/information/"
          >
            2018
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://data.economie.gouv.fr/explore/dataset/agregatspl-2017/table/"
          >
            2017
          </a>
        </li>
      </ul>
      <blockquote>
        <p>
          Ces fichiers ont été retraités avant import (?). Les données des nomenclature inexistantes (1, 10, 421, 20,
          422, et 0) sont ignorées.
        </p>
      </blockquote>

      <h4>Agrégats OFGL</h4>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/comptes-consolides-des-communes-2012-2019/"
          >
            Communes
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/comptes-consolides-des-groupements-a-fiscalite-propre-2012-2019/"
          >
            Groupements à fiscalité propre
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/comptes-des-departements-2012-2019/"
          >
            Départements
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/comptes-consolides-des-regions-2012-2019/"
          >
            Régions
          </a>
        </li>
      </ul>

      <h4>Balances SPL (présentation croisée nature-fonction)</h4>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-collectivites-et-des-etablissements-publics-locaux-avec-la-presentation-croisee-nature-fonction-2019/"
          >
            2019
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-collectivites-et-des-etablissements-publics-locaux-avec-la-presentation-croisee-nature-fonction-2018/"
          >
            2018
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-collectivites-et-des-etablissements-publics-locaux-avec-la-presentation-croisee-nature-fonction-2017/"
          >
            2017
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-collectivites-et-des-etablissements-publics-locaux-avec-la-presentation-croisee-nature-fonction-2016/"
          >
            2016
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-collectivites-et-des-etablissements-publics-locaux-avec-la-presentation-croisee-nature-fonction-2015/"
          >
            2015
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-collectivites-et-des-etablissements-publics-locaux-avec-la-presentation-croisee-nature-fonction-2014/"
          >
            2014
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-collectivites-et-des-etablissements-publics-locaux-avec-la-presentation-croisee-nature-fonction-2013/"
          >
            2013
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-collectivites-et-des-etablissements-publics-locaux-avec-la-presentation-croisee-nature-fonction-2012/"
          >
            2012
          </a>
        </li>
      </ul>

      <blockquote>
        <p>
          Pour chaque ligne de ces fichiers nous créons une ligne de dépense (<code>OBNETDEB - OOBDEB</code>) et une
          ligne de recettes (<code>OBNETCRE - OOBCRE</code>) si nous avons le plan de compte correspondant (voir
          ci-avant), que le montant est non nul, et que le numéro de compte ne commence pas par 19 ou 28 (opérations
          d&apos;ordre budgétaire concernant les amortissements).
        </p>
        <p>
          On utilise les comptes fonctionnels ou bien la référence fonctionnelle des plans de comptes afin de trouver le
          libellé correspondant à chaque fonction. Pour déterminer si le budget a été voté par nature ou par fonction
          afin de savoir quel set de libellés utiliser, on vérifie d&apos;abord si l&apos;ensemble des fonctions
          utilisées pour une collectivité commence par 9 ou non. Cette méthode atteint ses limites pour les
          collectivités qui n&apos;utilisent que trop peu de fonctions (ex. PAYS SOURCES ET VALLEE-CC PAYS NOYONNAIS
          n’utilise en 2017 que la seule fonction 96, ou bien ESPACE COM - LES ACHARDS qui en 2019 qui n’impute que sur
          940).
        </p>
      </blockquote>

      <h4>Balances SPL (présentation croisée nature)</h4>
      <ul>
        <li>
          Communes
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-communes-en-2019/"
              >
                2019
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-communes-en-2018/"
              >
                2018
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-communes/"
              >
                2012 à 2017
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-communes-en-2011/"
              >
                2011
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-communes-en-2010/"
              >
                2010
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-groupements-a-fiscalite-propre-depuis-2010/"
          >
            GFP 2010-2019
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-etablissements-publics-locaux-depuis-2010/"
          >
            EPL 2010-2019
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-syndicats-depuis-2010/"
          >
            Syndicats 2010-2019
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-departements-depuis-2010/"
          >
            Départements 2010-2019
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.data.gouv.fr/fr/datasets/balances-comptables-des-regions-depuis-2010/"
          >
            Régions 2010-2019
          </a>
        </li>
      </ul>

      <blockquote>
        <p>Traitement identique au précédent, sans les fonctions.</p>
      </blockquote>

      <h4>CA (fichiers TOTEM)</h4>
      <ul>
        <li>récupérés manuellement auprès de chaque collectivité</li>
      </ul>

      <h4>Populations</h4>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://drive.google.com/drive/folders/1dKsLSeDR1gig1WymqhwzYQRxd4H1wn0f"
          >
            Données compilées manuellement
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.google.com/spreadsheets/d/1PwQ6sl22ed6nvvgNxoWKVeTQ7LneIs2-cn45_cFT50k/edit#gid=0"
          >
            CSV
          </a>
          créé à partir de :
          <ul>
            <li>
              <a target="_blank" rel="noopener noreferrer" href="https://www.insee.fr/fr/statistiques/1893198">
                INSEE
              </a>
              (?)
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.dotations-dgcl.interieur.gouv.fr/consultation/criteres_repartition.php"
              >
                DGF
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
