import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';

import './ActionsMenu.scss';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';

export default function ActionsMenu({ actions, disabled = false }) {
  const menu = useRef(null);

  return (
    <div title="Plus d'actions">
      <Menu className="actions-menu" model={actions} popup ref={menu} />
      <Button
        className="p-button-secondary p-button-rounded p-button-text actions-menu-button"
        icon="pi pi-ellipsis-v"
        onClick={event => menu.current.toggle(event)}
        disabled={disabled}
      />
    </div>
  );
}

ActionsMenu.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      disabled: PropTypes.bool,
      command: PropTypes.func,
    })
  ),
  disabled: PropTypes.bool,
};
