import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { Mention, MentionsInput } from 'react-mentions';

import { FormulaParser } from 'utils';
import { defaultMentionStyle, exerciceMentionStyle, style } from './mentionsStyleLegacy';

import './FormulaInputLegacy.scss';

class FormulaInputLegacy extends Component {
  data = {};

  parser = new FormulaParser();

  constructor(props) {
    super(props);
    this.state = {
      formula: props.value || '',
    };
  }

  onSave() {
    const { onSave, set } = this.props;
    onSave(set);
    // this.props.onSave(this.props.set);
  }

  onCancel() {
    const { onCancel } = this.props;
    onCancel();
    // this.props.onCancel();
  }

  onAdd(state) {
    const data = {
      ...this.data,
      label: state.label || state.placeholder || '',
      lists: state.lists,
      unitPlaceholder: state.unitPlaceholder,
    };

    // console.log('ADD', data);

    const { set } = this.props;

    // delete data.values; // sera recalculé ensuite selon aggregation globale
    // dataSets[+visible].formula += (dataSets[+visible].formula ? ' + ' : '') + '@['+data.label+']('+(dataSets[+visible].sets.length-1)+')';

    const cursorPosition = this.getCursorPosition();
    const { formulaPosition, selectedSetId, position } = cursorPosition;
    // console.log('ADD', cursorPosition);

    const newLabel = (data.label || '').replace(/[[]/g, '').replace(/[\]]/g, '');

    let caretPosition = position;
    const selectedSet = set.sets.find(s => s.id === selectedSetId);
    if (selectedSetId !== false && selectedSet) {
      data.formulaMember = data.label ? `@[${newLabel}](${selectedSetId})` : '';
      data.id = selectedSetId;
      data.label = newLabel;
      const selectedSetFormula = selectedSet.formulaMember;
      const selectedSetLabel = selectedSet.label;

      const selectedSetIndex = set.sets.findIndex(s => s.id === selectedSetId);
      if (data.label) {
        Object.assign(set.sets[selectedSetIndex], data);
      } else {
        delete set.sets[selectedSetIndex];
      }
      // console.log('replaced', selectedSet.formulaMember);
      set.formula = set.formula.replace(selectedSetFormula, data.formulaMember);
      caretPosition -= selectedSetLabel.length;
      caretPosition = Math.max(position, caretPosition);
    } else {
      if (!data.label) {
        return;
      }

      data.id = set.sets.map(s => s.id).reduce((a, b) => Math.max(a, b), -1) + 1;
      data.formulaMember = `@[${newLabel}](${data.id})`;
      set.sets.push(data);

      // console.log('not selected', set.formula.slice(0, formulaPosition), data.formulaMember, set.formula.slice(formulaPosition));
      set.formula = [
        set.formula.slice(0, formulaPosition),
        data.formulaMember,
        set.formula.slice(formulaPosition),
      ].join('');
    }
    caretPosition += newLabel.length;
    this.input.cursorPosition = caretPosition;

    // console.log('caret', caretPosition, set);

    this.cleanSets(set);

    // this.onFormulaChange(visible, dataSets[+visible].formula, true);
    this.setState({ formula: set.formula });
    // console.warn('>STATE<', 'ADD', state);
  }

  onFormulaChange(formula) {
    // console.log('onFormulaChange');
    const { onChange } = this.props;
    onChange(formula);
    // this.props.onChange(formula);
    this.setState({ formula });
    // this.setState(this.state);
    // console.warn('>STATE<', 'onFormulaChange', {formula});
  }

  cleanSets = set => {
    // TODO change param reassign
    // eslint-disable-next-line no-param-reassign
    set.sets = set.sets.filter((s, k) => this.parser.isUsingSet(set, k));
  };

  /*
    getSelectedSetId = () => {
        const textarea = this.input;

        const selection = [textarea.selectionStart, textarea.selectionEnd];
        const formula = this.props.set.formula;


        let results = [];
        let offset = 0;

        formula.replace(/@\[.+?\]\((\d+)\)/g, (v,k,start) => {
            const [label, setId] = v.match(/@\[(.+?)\]\((\d+)\)/).slice(1);
            start -= offset;
            const end = start+label.length;

            const inRange = ((selection[0]-start)*(selection[0]-end) <= 0) && ((selection[1]-start)*(selection[1]-end) <= 0);

            results.push({label, setId, start, end, inRange });
            offset += v.length-label.length;
        });

        const selected = results.find(f => f.inRange);
        if(selected && this.props.set.sets[selected.setId]) {
            return selected.setId;
        }
    }
    */

  getCursorPosition = () => {
    // console.log('getCursorPosition');
    // Where did I click
    const cursorPosition = this.input.cursorPosition || 0;
    const { formula } = this.state;
    // console.log('cursor', cursorPosition, formula);

    const analyse = this.parser.analyse(formula);

    // Le premier bloc commence à zéro
    const currentBlock = analyse.filter((a, k) => (k === 0 ? 0 : a.start + 1) <= cursorPosition).pop() || {};

    // On n'est pas sur un membre, on peut insérer une formule au milieu
    if (!currentBlock.isMember) {
      currentBlock.formulaPosition += cursorPosition - currentBlock.start;
    }

    // console.log('cursor', analyse.slice(0), currentBlock, formula);

    return {
      position: currentBlock.start || 0,
      formulaPosition: currentBlock.formulaPosition || 0,
      exer: currentBlock.exer || undefined,
      selectedSetId: 'setId' in currentBlock ? currentBlock.setId : false,
      cursorPosition,
    };
  };

  // TODO consistent return
  // eslint-disable-next-line consistent-return
  onMentionInputCursor = () => {
    if (this.input.processing) {
      return false;
    }
    this.input.processing = true;

    this.input.cursorPosition = this.input.selectionStart;

    const cursorPosition = this.getCursorPosition();
    const { selectedSetId } = cursorPosition;

    const { set } = this.props;
    const selectedSet = selectedSetId !== false ? set.sets.find((s, k) => (s.id ? s.id : k) === selectedSetId) : false;
    // selectedSetId !== false ? this.props.set.sets.find((s, k) => (s.id ? s.id : k) === selectedSetId) : false;
    // console.log('onMentionInputCursor', selectedSet, this.props.set, cursorPosition);
    if (selectedSet) {
      const { setListsValues } = this.props;

      // Load ReactiveComponent state
      // console.log('selectedSet', selectedSet, this.props.set.sets, [selectedSetId]);
      setListsValues(selectedSet.lists);
      // this.props.setListsValues(selectedSet.lists);
      // this.setState(selectedSet.lists, () => this.input.processing = false);
      this.input.processing = false;
      // console.warn('>STATE<', 'onMentionInputCursor', selectedSet.lists);
      Object.assign(this.data, selectedSet);
    } else {
      const { clearListsValues } = this.props;
      // console.log('clearSelected lists');
      clearListsValues();
      // this.props.clearListsValues();
      // console.warn('>STATE<', 'onMentionInputCursor', {placeholder: '', ...Object.fromEntries(this.allFields.map(l => [l, []]))});
      this.input.processing = false;
    }
  };

  focus = () => {
    const { formula } = this.state;
    this.input.selectionStart = (formula || '').length;
    this.onMentionInputCursor();
  };

  setQuery = query => {
    const { set } = this.props;
    const { selectedSetId } = this.getCursorPosition();

    const selectedSet = set.sets.find(s => s.id === selectedSetId);
    if (selectedSetId !== false && selectedSet) {
      // console.log('query', query);
      selectedSet.query = query;
    }
  };

  render = () => {
    const { formula } = this.state;
    const { importData } = this.props;

    return (
      <div className="p-toolbar" style={{ marginBottom: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          <div className="p-inputgroup" style={{ flex: 1 }}>
            <MentionsInput
              value={formula}
              onChange={(e, newValue) => this.onFormulaChange(newValue)}
              // onFocus={() => !formula && this.popup.setState({visible: true})}
              onClick={() => this.onMentionInputCursor()}
              onKeyUp={() => this.onMentionInputCursor()}
              // eslint-disable-next-line no-return-assign
              inputRef={mi => (this.input = mi)}
              style={style}
              // disabled={this.state.processing}
              placeholder="Sélectionnez des données"
            >
              <Mention
                markup="@[__display__](N__id__)"
                trigger="N"
                data={[
                  { id: '-1', display: 'N-1' },
                  { id: '+0', display: 'N' },
                  { id: '+1', display: 'N+1' },
                ]}
                style={exerciceMentionStyle}
              />
              <Mention trigger="@" data={[].map((s, k) => ({ id: k, display: s.label }))} style={defaultMentionStyle} />
            </MentionsInput>

            <Button
              label=""
              icon="pi pi-save"
              style={{ margin: '0 10px 0 5px', borderRadius: '0 5px 5px 0' }}
              className="p-button-info"
              onClick={importData}
              disabled={!formula}
            />

            <Button
              label="OK"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={this.onSave.bind(this)}
              style={{ marginRight: '10px', borderRadius: '5px' }}
              className="p-button-success"
              disabled={!formula}
            />
            {/* <Button type="button" onClick={this.onCancel.bind(this)} label="Annuler" className="p-button-secondary"/> */}
          </div>
        </div>
      </div>
    );
  };
}

FormulaInputLegacy.propTypes = {
  value: PropTypes.string,
  set: PropTypes.shape({
    sets: PropTypes.arrayOf(PropTypes.shape({})),
    formula: PropTypes.string,
  }),
  onSave: PropTypes.func,
  importData: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  setListsValues: PropTypes.func,
  clearListsValues: PropTypes.func,
};

export default FormulaInputLegacy;
