import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import { unregister } from './registerServiceWorker';
import App from './App';

import 'leaflet/dist/leaflet.css'; // map

const history = createBrowserHistory();

unregister();

const auth0Param = {
  domain: 'profil-dataviz.eu.auth0.com',
  clientId: 'wQRBzNLbBZOjp5ZpMRKM894APfN1Gsx8',
};

/*
Development tenant params :
  domain: 'dev--nz-oja6.eu.auth0.com',
  clientId: 'fVclzXeaaPuNdS2B2QceGPMWQJ6Zf1Rv',
*/

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={auth0Param.domain}
      clientId={auth0Param.clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={() => history.replace('/')}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
