import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'moment/locale/fr';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import { unitFormatter } from 'utils';

import './TableDetails.scss';

class TableDetails extends Component {
  render = () => {
    const columnsAttrs = this.props.filter ? { sortable: true, filter: true, filterMatchMode: 'contains' } : {};
    const styleAttrs = w => ({ style: { maxHeight: '4em', overflow: 'auto', width: `${w || 350}px` } });

    const optionsPages = [
      { label: '10 lignes', value: 10 },
      { label: '1 000 lignes', value: 1000 },
      { label: '100 000 lignes', value: 100000 },
    ];

    const footer = (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Button
          type="button"
          icon="pi pi-download"
          className="p-button-secondary p-button-outlined"
          iconPos="left"
          label="Exporter"
          onClick={() => this.dt.exportCSV()}
          style={{ fontSize: '.8em', marginRight: '10px' }}
        />
        <Dropdown
          value={this.props.nbPerPage}
          options={optionsPages}
          onChange={e => {
            this.props.onNbPerPageChange(e.value);
          }}
        />
      </div>
    );

    return (
      <DataTable
        value={this.props.value}
        responsive
        scrollable
        paginator
        rows={10}
        ref={el => {
          this.dt = el;
        }}
        footer={footer}
        {...this.props}
      >
        <Column field="EXER" header="Exercice" {...styleAttrs(110)} {...columnsAttrs} />
        <Column field="CREGI" header="Territoire régional" {...styleAttrs()} {...columnsAttrs} />
        <Column field="NDEPT" header="Territoire départemental" {...styleAttrs()} {...columnsAttrs} />
        <Column field="CATEG" header="Catégorie" {...styleAttrs(150)} {...columnsAttrs} />
        <Column field="CTYPE" header="Type d'établissement" {...styleAttrs(200)} {...columnsAttrs} />
        <Column field="description" header="Données" {...styleAttrs()} {...columnsAttrs} />
        <Column field="formulaLabel" header="Requête" {...styleAttrs()} {...columnsAttrs} />
        <Column
          field="montant"
          header="Valeur"
          {...styleAttrs(150)}
          body={(rowData, column) => unitFormatter(rowData[column.field], rowData.unit)}
          className="montant"
          {...columnsAttrs}
        />
        <Column field="RD" header="Type" {...styleAttrs(110)} {...columnsAttrs} />
        <Column field="FI" header="Section" {...styleAttrs(150)} {...columnsAttrs} />
        <Column field="NOMEN" header="Nomenclature" {...styleAttrs(200)} {...columnsAttrs} />
        <Column field="LBUDG" header="Entité budgétaire" {...styleAttrs(200)} {...columnsAttrs} />
        <Column field="CBUDG" header="Budg." {...styleAttrs(100)} {...columnsAttrs} />
        <Column field="CHAPITRE_FN" header="Chapitre Fonction" {...styleAttrs(250)} {...columnsAttrs} />
        <Column field="FONCTION_0" header="Fonction" {...styleAttrs(250)} {...columnsAttrs} />
        <Column field="FONCTION_1" header="Sous-Fonction" {...styleAttrs(250)} {...columnsAttrs} />
        <Column field="FONCTION" header="Code Fonction" {...styleAttrs(250)} {...columnsAttrs} />
        <Column field="CHAPITRE" header="Chapitre Nature" {...styleAttrs(250)} {...columnsAttrs} />
        <Column field="COMPTE_ABBR" header="Compte abrégé" {...styleAttrs()} {...columnsAttrs} />
        <Column field="COMPTE_BASE" header="Compte de base" {...styleAttrs()} {...columnsAttrs} />
        <Column field="COMPTE" header="Compte développé" {...styleAttrs()} {...columnsAttrs} />
        <Column field="AGREGAT" header="Agrégat" {...styleAttrs()} {...columnsAttrs} />
        <Column field="SOURCE" header="Source" {...styleAttrs(250)} {...columnsAttrs} />
      </DataTable>
    );
  };
}

export default TableDetails;
