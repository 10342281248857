import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';

import { DashboardResult, LoadDots } from 'components';
import { dashboardService } from 'services';

import './DashboardPreview.scss';

export default function DashboardPreview({ dashboardId, visible = false, onHide = () => {} }) {
  const [dashboard, setDashboard] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dashboardId) {
      setLoading(true);
      dashboardService
        .getDashboardForPreview(dashboardId)
        .then(data => {
          if (data) {
            setDashboard(data);
          }
          setLoading(false);
        })
        .catch(error => {
          console.log('Error while fetching the dashboard', error);
          setLoading(false);
        });
    }
  }, [dashboardId]);
  return (
    <Dialog
      header={dashboard?.name ? dashboard.name : 'Prévisualiser'}
      visible={visible}
      style={{ width: '65vw' }}
      modal
      onHide={onHide}
      dismissableMask
      blockScroll
    >
      {loading && (
        <div className="dashboard-loading">
          <LoadDots />
        </div>
      )}
      {dashboard && <DashboardResult dashboard={dashboard} preview />}
    </Dialog>
  );
}

DashboardPreview.propTypes = {
  dashboardId: PropTypes.string,
  visible: PropTypes.bool,
  onHide: PropTypes.func,
};
